import React from "react";
import withRoot from "../../withRoot";
import AdminAppBar from "../../views/AdminAppBar";
import { gql,useQuery } from "@apollo/client";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../../custom/Typography";
import bg from "../../assets/productCurvyLines.png";
import { Download } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ButtoN from "@mui/material/Button";

const GET_CSV = gql`
  query Query {
    paymentcsv
  }
`;

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};
const AdminHome = () => {
  const navigate = useNavigate();
  const { data, error, loading } = useQuery(GET_CSV);
  const [opeN, setOpeN] = React.useState(false);
  const handleClickOpen = () => {
    setOpeN(true);
  };

  const handleClose = () => {
    setOpeN(false);
  };

  const handleOK = () => {
    setOpeN(false);
    navigate("/");
    localStorage.clear();
  };
  const downloadCsv = () => {
    // document.write(data?.getCSV);
    var downloadElement = document.createElement("a");
    downloadElement.href =
      "data:text/csv;charset=utf-8," + encodeURI(data?.paymentcsv);
    downloadElement.target = "_blank";
    downloadElement.download = `Data.csv`;
    downloadElement.click();
  };
  if (loading) {
    return <pre>Loading</pre>;
  }
  // if (error) {
  //   return <pre>{error.message}</pre>;
  // }
  if (error) {
    if (!opeN) {
      handleClickOpen();
    }
    return (
      <div>
        <Dialog
          open={opeN}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Error Encountered!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              We have encountered an error! Click on <strong>OK</strong> and try
              logging in again
              <br />
              Reason: {error?.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtoN color="error" onClick={handleClose}>
              Cancel
            </ButtoN>
            <ButtoN color="success" onClick={handleOK} autoFocus>
              OK
            </ButtoN>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  return (
    <React.Fragment>
      <AdminAppBar />
      <Box
        sx={{ display: "flex", overflow: "hidden", bgcolor: "secondary.light" }}
      >
        <Container
          sx={{ mt: 10, mb: 50, display: "flex", position: "relative" }}
        >
          <Box
            component="img"
            src={bg}
            alt="curvy lines"
            sx={{
              pointerEvents: "none",
              position: "absolute",
              left: -100,
              top: -180,
            }}
          />
          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              <Box sx={item}>
                <Box sx={{ height: 65 }}>
                  <Button
                    variant="contained"
                    color="textColor"
                    onClick={(e) => {
                      navigate(`/admin/students`);
                    }}
                    size="large"
                  >
                    <Typography
                      color="primary"
                      fontWeight="bold"
                      sx={{ fontSize: 20 }}
                    >
                      Students
                    </Typography>
                  </Button>
                </Box>
                <Typography variant="h6" sx={{ my: 2 }}>
                  All Student Info
                </Typography>
                <Typography variant="h5">
                  {
                    "Know the details of the students who have signed up to this platform. Also know whether the student is registered or not."
                  }
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={item}>
                <Box sx={{ height: 65 }}>
                  <Button
                    variant="contained"
                    color="textColor"
                    onClick={(e) => {
                      navigate(`/admin/teacher-courses`);
                    }}
                    size="large"
                  >
                    <Typography
                      color="primary"
                      fontWeight="bold"
                      sx={{ fontSize: 20 }}
                    >
                      Courses
                    </Typography>
                  </Button>
                </Box>
                <Typography variant="h6" sx={{ my: 2 }}>
                  Approve Courses
                </Typography>
                <Typography variant="h5">
                  {"Update and approve the courses created by the instructors."}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={item}>
                <Box sx={{ height: 65 }}>
                  <Button
                    variant="contained"
                    color="textColor"
                    onClick={(e) => {
                      navigate(`/admin/teachers`);
                    }}
                    size="large"
                  >
                    <Typography
                      color="primary"
                      fontWeight="bold"
                      sx={{ fontSize: 20 }}
                    >
                      Instructors
                    </Typography>
                  </Button>
                </Box>
                <Typography variant="h6" sx={{ my: 2 }}>
                  Approve Teachers
                </Typography>
                <Typography variant="h5">
                  {
                    "You can check out all the instructors and approve them to be a part of us. Oversee their course details as well."
                  }
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={item}>
                <Box sx={{ height: 65 }}>
                  <Button
                    variant="contained"
                    color="textColor"
                    onClick={() => downloadCsv()}
                    size="large"
                  >
                    <Typography
                      color="primary"
                      fontWeight="bold"
                      sx={{ fontSize: 20 }}
                    >
                      Payments
                    </Typography>
                  </Button>
                </Box>
                <Typography variant="h6" sx={{ my: 2 }}>
                  Download <Download />
                </Typography>
                <Typography variant="h5">
                  {
                    "Get all the details of the student payments in a excel sheet format."
                  }
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default withRoot(AdminHome);
