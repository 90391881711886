import * as React from "react";
import TeacherAppBar from "../../views/TeacherAppBar";
import withRoot from "../../withRoot";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  One_Backup_Teacher,
  backupapprove,
  backupreject,
} from "../../schema/teacher";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
} from "@mui/material";
import Paper from "../../custom/Paper";
import Typography from "../../custom/Typography";
import { StyledCell, StyledTableRow } from "./MyCourse";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ButtoN from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
const columns = [
  { id: "name", label: "Title", minWidth: 140 },
  { id: "desc", label: "Description", minWidth: 100 },
  { id: "status", label: "Course Status", minWidth: 60 },
  {
    id: "teacherName",
    label: "Instructor Name",
    minWidth: 140,
    align: "center",
  },
  {
    id: "email",
    label: "E-Mail",
    minWidth: 150,
    align: "center",
  },
  { id: "approve", align: "center", label: "Accept", minWidth: 100 },
  { id: "reject", align: "center", label: "Reject", minWidth: 100 },
  { id: "buStatus", align: "center", label: "Status", minWidth: 100 },
];

const BackUpCourse = () => {
  const {
    data: backupcourseList,
    loading: backupcourseLoading,
    error: backupcourseError,
    refetch: backupcourseRefetch,
  } = useQuery(One_Backup_Teacher);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [approvef] = useMutation(backupapprove);
  const [rejectf] = useMutation(backupreject);
  const [opeN, setOpeN] = React.useState(false);
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpeN(true);
  };

  const handleClose = () => {
    setOpeN(false);
  };

  const handleOK = () => {
    setOpeN(false);
    navigate("/");
    localStorage.clear();
  };

  if (backupcourseLoading) {
    return <pre>Loading! ....</pre>;
  }

  if (backupcourseError) {
    if (!opeN) {
      handleClickOpen();
    }
    return (
      <div>
        <Dialog
          open={opeN}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Error Encountered!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              We have encountered an error! Click on <strong>OK</strong> and try
              logging in again
              <br />
              Reason: {backupcourseError?.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtoN color="error" onClick={handleClose}>
              Cancel
            </ButtoN>
            <ButtoN color="success" onClick={handleOK} autoFocus>
              OK
            </ButtoN>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <React.Fragment>
      <TeacherAppBar />
      <Box
        margin={2.5}
        sx={{
          display: "flex",
          mt: 4,
          mb: 4,
        }}
      >
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <StyledTableRow>
                  <StyledCell align="center" colSpan={3}>
                    Course Details
                  </StyledCell>
                  <StyledCell align="center" colSpan={2}>
                    Assigned By
                  </StyledCell>
                  <StyledCell align="center" colSpan={3}>
                    Action
                  </StyledCell>
                </StyledTableRow>
                <StyledTableRow>
                  {columns.map((column) => (
                    <StyledCell
                      StyledCell={column.id}
                      align={column.align}
                      style={{ top: 57, minWidth: column.minWidth }}
                    >
                      {column.label}
                    </StyledCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {backupcourseList?.oneBackupTeacher?.map((row) => {
                  return (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      <StyledCell component={"th"} scope="row" align="left">
                        {row.title}
                      </StyledCell>
                      <StyledCell component={"th"} scope="row" align="left">
                        {row.description}
                      </StyledCell>
                      <StyledCell component={"th"} scope="row" align="left">
                        {row.status === 1 ? (
                          <Typography sx={{ color: "#FF8F00" }}>
                            Pending
                          </Typography>
                        ) : (
                          <>
                            {row.backup_status === 2 ? (
                              <Typography color={"#008000"}>
                                Approved
                              </Typography>
                            ) : (
                              <Typography color={"red"}>Rejected</Typography>
                            )}
                          </>
                        )}
                      </StyledCell>
                      <StyledCell component={"th"} scope="row" align="center">
                        {row?.teacher.name}
                      </StyledCell>
                      <StyledCell component={"th"} scope="row" align="center">
                        {row?.teacher.email}
                      </StyledCell>
                      <StyledCell component={"th"} scope="row" align="center">
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={async () => {
                            await approvef({
                              variables: {
                                data: {
                                  id: row.id,
                                },
                              },
                            }).then((res) => {
                              backupcourseRefetch();
                              return res;
                            });
                          }}
                        >
                          Approve
                        </Button>
                      </StyledCell>
                      <StyledCell component={"th"} scope="row" align="center">
                        <Button
                          color="error"
                          variant="outlined"
                          onClick={async () => {
                            await rejectf({
                              variables: {
                                data: {
                                  id: row.id,
                                },
                              },
                            }).then((res) => {
                              backupcourseRefetch();
                              return res;
                            });
                          }}
                        >
                          Reject
                        </Button>
                      </StyledCell>
                      <StyledCell component={"th"} scope="row" align="center">
                        {row.backup_status === 1 ? (
                          <Typography sx={{ color: "#FF8F00" }}>
                            Pending
                          </Typography>
                        ) : (
                          <>
                            {row.backup_status === 2 ? (
                              <Typography color={"#008000"}>
                                Approved
                              </Typography>
                            ) : (
                              <Typography color={"red"}>Rejected</Typography>
                            )}
                          </>
                        )}
                      </StyledCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={backupcourseList.oneBackupTeacher.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </React.Fragment>
  );
};

export default withRoot(BackUpCourse);
