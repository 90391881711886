import React from "react";
import {
  PayPalScriptProvider,
  PayPalButtons,
} from "@paypal/react-paypal-js";
import { gql, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ButtoN from "@mui/material/Button";

const START_PAY = gql`
  mutation InitiatePay($data: InitiateInput!) {
    initiatePay(data: $data) {
      order_id
    }
  }
`;

const START_AUTH = gql`
  mutation AuthorizeOrder($data: PaymentInputObject!) {
    authorizeOrder(data: $data)
  }
`;
const amount = "1";
const currency = "USD";
const style = { layout: "vertical" };

const Payer = () => {
  const [opeN, setOpeN] = React.useState(false);
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpeN(true);
  };

  const handleClose = () => {
    setOpeN(false);
  };

  const handleOK = () => {
    setOpeN(false);
    navigate("/");
    localStorage.clear();
  };

  const initialOptions = {
    "client-id":
      "AcR23T2y54IwL_QQ_Zuut8wqyzPFMhSAWHIT-7gCG6dNWVFdHUTuKqSDW8_KCfpw5sp86LhZaUtOy8WQ",
    currency: "USD",
    components: "buttons",
  };

  const [
    payFunction,
    { data: paySucess, error: payError, loading: payLoading },
  ] = useMutation(START_PAY);

  const [
    endFunction,
    { data: endSucess, error: endError, loading: endLoading },
  ] = useMutation(START_AUTH);

  if (payError || endError) {
    if (!opeN) {
      handleClickOpen();
    }
    return (
      <div>
        <Dialog
          open={opeN}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Error Encountered!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              We have encountered an error! Click on <strong>OK</strong> and try
              logging in and paying for the course again.
              <br />
              Reason: {payError?.message} {endError?.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtoN color="error" onClick={handleClose}>
              Cancel
            </ButtoN>
            <ButtoN color="success" onClick={handleOK} autoFocus>
              OK
            </ButtoN>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={style}
          disabled={false}
          forceReRender={[amount, currency, style]}
          fundingSource={undefined}
          createOrder={async (data, actions) => {
            const orderdata = await payFunction({
              variables: {
                data: {
                  id: parseInt(localStorage.getItem("id")),
                },
              },
            });
            console.log(orderdata.data.initiatePay.order_id);
            return orderdata.data.initiatePay.order_id;
          }}
          onApprove={async (data, actions) => {
            const orderId = data.orderID;
            const success = await endFunction({
              variables: {
                data: {
                  order_id: orderId,
                },
              },
            }).then(() => {
              navigate("/checkout-success");
            });
            return 1;
          }}
        />
      </PayPalScriptProvider>
    </div>
  );
};

export default Payer;
