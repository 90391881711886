import React from "react";
import withRoot from "../../withRoot";
import TeacherAppBar from "../../views/TeacherAppBar";
import image from "../../assets/appCurvyLines.png";
import {
  Autocomplete,
  Box,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled,
} from "@mui/material";
import Typography from "../../custom/Typography";
import { Create } from "@mui/icons-material";
import Paper from "../../custom/Paper";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  Create_Course,
  Backup_Fetch,
  create_class,
} from "../../schema/teacher";
import Button from "../../custom/Button";

export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const currencies = ["USD", "SGD", "INR", "DHR"];
export const CustomComplete = styled(Autocomplete)({
  "& label.Mui-focused": {
    color: "#008000",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#008000",
  },
});
export const CustomField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#008000",
  },
  "& .MuiOutlinedInput-root": {
    // "& fieldset": {
    //   borderColor: "#008000",
    // },
    "&:hover fieldset": {
      borderColor: "#008000",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#008000",
    },
  },
});
const CreateCourse = () => {
  const [name, setName] = React.useState("");
  const [desc, setDesc] = React.useState("");
  const [maxst, setMaxst] = React.useState(0);
  const [backup, setBackup] = React.useState("");
  const [type, setType] = React.useState("");
  const [sdays, setsDays] = React.useState(["Sunday"]);
  const [value, setValue] = React.useState([dayjs("2022-04-17T15:30")]);
  const [cdesc, setcDesc] = React.useState([""]);
  const [currency, setCurrency] = React.useState([""]);
  const [fees, setFees] = React.useState([0]);
  const [link1, setLink1] = React.useState([""]);
  const [link2, setLink2] = React.useState([""]);
  const [id1, setId1] = React.useState([""]);
  const [id2, setId2] = React.useState([""]);
  const [pass1, setPass1] = React.useState([""]);
  const [pass2, setPass2] = React.useState([""]);
  const [classf] = useMutation(create_class);
  const [createCourseFunction] = useMutation(Create_Course);
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: backupList,
    loading: backupLoading,
    error: backupError,
    refetch: backupRefetch,
  } = useQuery(Backup_Fetch);

  const handleChange = ({ event, index }) => {
    const a = [...sdays];
    a[index] = event.target.value;
    setsDays(a);
  };
  const handleChangeLink1 = ({ event, index }) => {
    const a = [...link1];
    a[index] = event.target.value;
    setLink1(a);
  };
  const handleChangeLink2 = ({ event, index }) => {
    const a = [...link2];
    a[index] = event.target.value;
    setLink2(a);
  };
  const handleChangeFees = ({ event, index }) => {
    const a = [...fees];
    a[index] = parseFloat(event.target.value);
    setFees(a);
  };
  const handleChangePass1 = ({ event, index }) => {
    const a = [...pass1];
    a[index] = event.target.value;
    setPass1(a);
  };
  const handleChangePass2 = ({ event, index }) => {
    const a = [...pass2];
    a[index] = event.target.value;
    setPass2(a);
  };
  const handleChangeCur = ({ event, index }) => {
    const a = [...currency];
    a[index] = event;
    setCurrency(a);
  };
  const handleChangeId1 = ({ event, index }) => {
    const a = [...id1];
    a[index] = event.target.value;
    setId1(a);
  };
  const handleChangeId2 = ({ event, index }) => {
    const a = [...id2];
    a[index] = event.target.value;
    setId2(a);
  };
  const handleChangeValue = ({ event, index }) => {
    const b = [...value];
    b[index] = event;
    setValue(b);
  };
  const handleChangeDesc = ({ event, index }) => {
    const c = [...cdesc];
    c[index] = event.target.value;
    setcDesc(c);
  };
  if (backupLoading) {
    return <pre>Loading! ....</pre>;
  }
  if (backupError) {
    return (
      <center>
        {/* {error?.message} */}
        {backupError?.message}
        {/* {backupcourseError?.message} */}
      </center>
    );
  }
  return (
    <React.Fragment>
      <TeacherAppBar />
      <Box
        sx={{
          display: "flex",
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
        }}
        //   component={Container}
      >
        <Container maxWidth="lg">
          <Box sx={{ mt: 7, mb: 12 }}>
            <Paper
              background="light"
              sx={{ py: { xs: 4, md: 8 }, px: { xs: 3, md: 6 } }}
            >
              <Box>
                <Typography sx={{ fontSize: 30 }}>
                  <Create /> Create your Course
                </Typography>
              </Box>
              <Grid padding={1} container spacing={2}>
                <Grid item xs={3} paddingRight={2}>
                  <CustomField
                    variant="outlined"
                    label="Title"
                    placeholder="title"
                    type="text"
                    size="large"
                    fullWidth
                    required
                    margin="normal"
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3} paddingRight={2}>
                  <FormControl
                    color="textColor"
                    variant="outlined"
                    required
                    sx={{ minWidth: 240 }}
                    margin="normal"
                  >
                    <InputLabel
                      id="demo-simple-select-standard-label"
                      sx={{
                        "& .MuiInputBase-input": {
                          "&:focus": {
                            borderColor: "#008000",
                            color: "#008000",
                            borderBottomColor: "#008000",
                          },
                        },
                      }}
                    >
                      Course Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      onChange={
                        (e) => setType(e.target.value)
                      }
                      label="Course Type"
                      defaultValue={"class"}
                      sx={{
                        "&:focus": {
                          backgroundColor: "#ffddec",
                          borderColor: "brown",
                        },
                      }}
                    >
                      <MenuItem value="class">Class</MenuItem>
                      <MenuItem value="workshop">Workshop</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3} paddingRight={2}>
                  <CustomField
                    variant="outlined"
                    label="Maximum Strength (Approx)"
                    type="number"
                    size="large"
                    margin="normal"
                    fullWidth
                    onChange={(e) => setMaxst(parseInt(e.target.value))}
                    // required
                  />
                </Grid>
              </Grid>
              <Grid container padding={1} spacing={2}>
                <Grid item xs={6}>
                  <CustomField
                    variant="outlined"
                    multiline
                    rows={3}
                    fullWidth
                    margin="normal"
                    label="Course Description"
                    placeholder="About the course"
                    onChange={(e) => setDesc(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container padding={1} spacing={2}>
                <Grid item xs={3} paddingRight={2}>
                  <FormControl
                    variant="outlined"
                    // required
                    sx={{ minWidth: 300 }}
                    margin="normal"
                    color="textColor"
                  >
                    <InputLabel
                      color="success"
                      id="demo-simple-select-standard-label"
                      sx={{
                        "& .MuiInputBase-input": {
                          "&:focus": {
                            borderColor: "#008000",
                            color: "#008000",
                            borderBottomColor: "#008000",
                          },
                        },
                      }}
                    >
                      Backup Teacher
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      onChange={
                        (e) => setBackup(e.target.value)
                      }
                      label="Backup Teacher"
                      defaultValue={"none"}
                    >
                      <MenuItem value="none">None</MenuItem>
                      {backupList.allTeacherInfo.map((teacher, index) => {
                        return teacher.id !== parseInt(id) ? (
                          <MenuItem value={teacher.id} key={index}>
                            {teacher.name}-{teacher.email}
                          </MenuItem>
                        ) : null;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Divider />
              <Box padding="10px">
                <Typography sx={{ fontSize: 25 }}>
                  <Create /> Create your Classes and Workshop
                </Typography>
              </Box>
              {sdays.map((sday, index) => {
                return (
                  <Grid container paddingTop={2} spacing={2}>
                    <Grid item xs={3}>
                      <FormControl
                        color="textColor"
                        sx={{ borderColor: "#008000", width: 240 }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Day
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={sdays[index]}
                          onChange={(event) => handleChange({ event, index })}
                          label="Day"
                          margin="normal"
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {days.map((day, index) => {
                            return (
                              <MenuItem value={day} key={index}>
                                {day}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <CustomComplete
                        disablePortal
                        options={currencies}
                        sx={{ width: 200 }}
                        defaultValue={"USD"}
                        onInputChange={(event, newInputValue) => {
                          handleChangeCur({ newInputValue, index });
                        }}
                        renderInput={(params) => (
                          <CustomField
                            {...params}
                            sx={{ maxWidth: 150 }}
                            variant="outlined"
                            label="Choose currency"
                            onChange={(e) => {}}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <CustomField
                        variant="outlined"
                        type="number"
                        fullWidth
                        margin="normal"
                        label="Class Fee"
                        onChange={(event) => handleChangeFees({ index, event })}
                      />
                    </Grid>
                    <Grid item margin="normal" xs={4}>
                      <LocalizationProvider
                        padding="none"
                        dateAdapter={AdapterDayjs}
                      >
                        <DemoContainer
                          padding="none"
                          components={["TimePicker"]}
                        >
                          <TimePicker
                            label="Start Time"
                            value={value[index]}
                            onChange={(event) =>
                              handleChangeValue({ index, event })
                            }
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                    <Grid container spacing={2} padding={1}>
                      <Grid item xs={6}>
                        <CustomField
                          variant="outlined"
                          multiline
                          rows={2}
                          fullWidth
                          margin="normal"
                          label="Class Description"
                          placeholder="About the class"
                          onChange={(event) =>
                            handleChangeDesc({ index, event })
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} padding={1}>
                      <Grid item xs={6}>
                        <CustomField
                          variant="outlined"
                          label="Meeting Link"
                          placeholder="Meeting Link"
                          type="text"
                          size="large"
                          fullWidth
                          required
                          margin="normal"
                          onChange={(event) =>
                            handleChangeLink1({ index, event })
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CustomField
                          variant="outlined"
                          label="Meeting ID"
                          placeholder="ID"
                          type="text"
                          size="large"
                          fullWidth
                          //   required
                          margin="normal"
                          onChange={(event) =>
                            handleChangeId1({ index, event })
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CustomField
                          variant="outlined"
                          label="Passcode"
                          placeholder="passcode"
                          type="text"
                          size="large"
                          fullWidth
                          //  required
                          margin="normal"
                          onChange={(event) =>
                            handleChangePass1({ index, event })
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomField
                          variant="outlined"
                          label="Alternate Meeting Link"
                          placeholder="Alternate link"
                          type="text"
                          size="large"
                          fullWidth
                          // required
                          margin="normal"
                          onChange={(event) =>
                            handleChangeLink2({ index, event })
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CustomField
                          variant="outlined"
                          label="Meeting ID (Alt)"
                          placeholder="ID (alt)"
                          type="text"
                          size="large"
                          fullWidth
                          //   required
                          margin="normal"
                          onChange={(event) =>
                            handleChangeId2({ index, event })
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CustomField
                          variant="outlined"
                          label="Passcode (Alt)"
                          placeholder="passcode (alt)"
                          type="text"
                          size="large"
                          fullWidth
                          //   required
                          margin="normal"
                          onChange={(event) =>
                            handleChangePass2({ index, event })
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container padding={1} spacing={2}>
                      <Grid item xs={4}>
                        <Button
                          color="success"
                          variant="outlined"
                          onClick={() => {
                            setsDays([...sdays, "Sunday"]);
                            setValue([...value, dayjs("2022-04-17T15:30")]);
                            setcDesc([...cdesc, ""]);
                            setCurrency([...currency, "USD"]);
                            setFees([...fees, 0]);
                            setLink1([...link1, ""]);
                            setLink2([...link2, ""]);
                            setId1([...id1, ""]);
                            setId2([...id2, ""]);
                            setPass1([...pass1, ""]);
                            setPass2([...pass2, ""]);
                          }}
                        >
                          Add Class
                        </Button>
                        {index > 0 ? (
                          <Button
                            color="error"
                            onClick={() => {
                              const a = [...sdays];
                              a.splice(index, 1);
                              setsDays(a);
                              const b = [...value];
                              b.splice(index, 1);
                              setValue(b);
                              var c = [...cdesc];
                              c.splice(index, 1);
                              setcDesc(c);
                              c = [...fees];
                              c.splice(index, 1);
                              setFees(c);
                              c = [...currency];
                              c.splice(index, 1);
                              setCurrency(c);
                              c = [...link1];
                              c.splice(index, 1);
                              setLink1(c);
                              c = [...link2];
                              c.splice(index, 1);
                              setLink2(c);
                              c = [...id1];
                              c.splice(index, 1);
                              setId1(c);
                              c = [...id2];
                              c.splice(index, 1);
                              setId2(c);
                              c = [...pass1];
                              c.splice(index, 1);
                              setPass1(c);
                              c = [...pass2];
                              c.splice(index, 1);
                              setPass2(c);
                            }}
                          >
                            Remove Class
                          </Button>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
              <Box align="center">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={async (e) => {
                    e.preventDefault();
                    await createCourseFunction({
                      variables: {
                        data: {
                          name: name,
                          description: desc,
                          id: parseInt(backup),
                          course_type: type,
                          max_strength: maxst,
                        },
                      },
                    }).then(async (res) => {
                      const promises = sdays.map(async (day, index) => {
                        const arr = new Date(value[index].$d.getTime())
                          .toString()
                          .split(" ");
                        let response = await classf({
                          variables: {
                            data: {
                              course_id: res.data.createCourse.id,
                              name: `Class_${day}`,
                              days: day,
                              startTime: arr[4] + " " + arr[5],
                              description: cdesc[index],
                              fees: fees[index],
                              currency: currency[index],
                              link: link1[index],
                              meetingId: id1[index],
                              passCode: pass1[index],
                              altLink: link2[index],
                              altMeetingId: id2[index],
                              altPassCode: pass2[index],
                            },
                          },
                        });
                        return response;
                      });
                      const prom_res = await Promise.all(promises);
                      backupRefetch();
                      navigate(`/teacher/${id}/my-courses`);
                      return prom_res;
                    });
                  }}
                >
                  <Typography
                    color="primary"
                    fontWeight="bold"
                    sx={{ fontSize: 15 }}
                  >
                    Create Course
                  </Typography>
                </Button>
              </Box>
            </Paper>
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default withRoot(CreateCourse);
