import { gql } from "@apollo/client";

export const Student_Login = gql`
  mutation Mutation($data: StudentLoginInput!) {
    studentlogin(data: $data) {
      email
      id
      name
      gender
    }
  }
`;

export const Logout = gql`
  mutation Mutation {
    logoutsuccess
  }
`;

export const Fetch_All_Student = gql`
  query Backup_teacher {
    getStudentCourses {
      backup_teacher {
        email
        username
        name
      }
      teacher {
        email
        name
        username
      }
      classes {
        course_id
        days
        description
        id
        name
        teacher_id
        startTime
        fees
        currency
        currReg
      }
      description
      title
      status
      id
    }
  }
`;

export const Create_Student = gql`
  mutation Mutation($data: StudentCreateInput!) {
    createStudent(data: $data) {
      email
      id
      name
    }
  }
`;

export const MyCourses = gql`
  query GetMyCoursesStudent {
    getMyCoursesStudent {
      altLink
      altMeetingId
      altPassCode
      course_id
      currency
      days
      description
      fees
      id
      link
      meetingId
      name
      passCode
      currReg
      startTime
      teacher {
        email
        name
      }
      course {
        description
        course_type
        title
      }
    }
  }
`;

export const RegStudent = gql`
  mutation CreateStudent($data: StudentUpdateInput!) {
    updateStudent(data: $data) {
      email
      gender
      name
      id
      registered
    }
  }
`;
