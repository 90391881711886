import * as React from "react";
import { Field, Form, FormSpy } from "react-final-form";
import Box from "@mui/material/Box";
import Typography from "../../custom/Typography";
import AppFooter from "../../views/AppFooter";
import AppForm from "../../views/AppForm";
import { email, required } from "../../form/validation";
import RFTextField from "../../form/RFTextField";
import FormButton from "../../form/FormButton";
import FormFeedback from "../../form/FormFeedback";
import withRoot from "../../withRoot";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import AdminAppBar from "../../views/AdminAppBar";
import { Admin_Login } from "../../schema/admin";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

function AdminLogin() {
  const navigate = useNavigate();
  const [sent, setSent] = React.useState(false);
  const [adminLogin] = useMutation(Admin_Login);
  const [mail, setMail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const validate = (values) => {
    const errors = required(["email", "password"], values);

    if (!errors.email) {
      const emailError = email(values.email);
      if (emailError) {
        errors.email = emailError;
      }
    }

    return errors;
  };
  const handleSubmit = async (event) => {
    // event.preventDefault();
    try {
      let data = { email: mail, password };
      await adminLogin({ variables: { data } })
        .then((loginResult) => {
          localStorage.setItem("name", loginResult?.data?.adminLogin?.name);
          localStorage.setItem(
            "user",
            loginResult?.data?.adminLogin?.__typename
          );
          // navigate(`/admin/${loginResult?.data?.adminLogin?.uuid}/courses`);
          navigate("/admin/home");
          window.location.reload();
        })
        .catch((error) => {
          console.log(JSON.stringify(error, null, 2));
        });
    } catch (error) {
      console.log(error);
      window.alert("something went wrong");
    }
  };

  return (
    <React.Fragment>
      <AdminAppBar />
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Admin Login
          </Typography>
        </React.Fragment>
        <Form
          onSubmit={handleSubmit}
          subscription={{ submitting: true }}
          validate={validate}
        >
          {({ handleSubmit: handleSubmit2, submitting }) => (
            <Box
              component="form"
              onSubmit={handleSubmit2}
              noValidate
              sx={{ mt: 6 }}
            >
              <Field
                autoComplete="email"
                autoFocus
                component={RFTextField}
                disabled={submitting || sent}
                fullWidth
                label="Email Address"
                margin="normal"
                name="email"
                required
                size="large"
                value={email}
                parse={(value) => {
                  setMail(value.trim());
                  return value;
                }}
              />
              <Field
                fullWidth
                size="large"
                component={RFTextField}
                disabled={submitting || sent}
                required
                name="password"
                autoComplete="current-password"
                label="Password"
                type={showPassword ? "text" : "password"}
                margin="normal"
                value={password}
                parse={(value) => {
                  setPassword(value);
                  return value;
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormSpy subscription={{ submitError: true }}>
                {({ submitError }) =>
                  submitError ? (
                    <FormFeedback error sx={{ mt: 2 }}>
                      {submitError}
                    </FormFeedback>
                  ) : null
                }
              </FormSpy>
              <FormButton
                sx={{ mt: 3, mb: 2 }}
                disabled={submitting || sent}
                size="large"
                color="secondary"
                fullWidth
              >
                {submitting || sent ? "In progress…" : "Login"}
              </FormButton>
            </Box>
          )}
        </Form>
        {/* <Typography align="center">
          <Link
            underline="always"
            href="/premium-themes/onepirate/forgot-password/"
          >
            Forgot password?
          </Link>
        </Typography> */}
      </AppForm>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(AdminLogin);
