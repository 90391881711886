import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import AppBar from "../custom/AppBar";
import Toolbar from "../custom/ToolBar";
import { useMutation } from "@apollo/client";
import { Logout } from "../schema/student";
import { useNavigate, useParams } from "react-router-dom";

const rightLink = {
  fontSize: 16,
  color: "common.white",
  ml: 3,
};

function TeacherAppBar() {
  const [logoutsuccess] = useMutation(Logout);
  const { id } = useParams();
  const navigate = useNavigate();
  return (
    <div>
      <AppBar position="fixed">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Link
            variant="h6"
            underline="none"
            color="inherit"
            href={`/teacher/home`}
            sx={{ fontSize: 26, fontFamily: "Oxygen" }}
          >
            {"Prithivi Grow Yoga - Instructors"}
          </Link>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            {localStorage.getItem("user") === "Teacher" ? (
              <>
                <Link
                  variant="h6"
                  underline="none"
                  //   href="`/teacher/${id}/teacher-course`"
                  sx={{ ...rightLink, color: "secondary.dark" }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/teacher/${id}/my-courses`);
                  }}
                >
                  {"My Courses"}
                </Link>
                <Link
                  variant="h6"
                  underline="none"
                  //   href="`/teacher/${id}/teacher-course`"
                  sx={{ ...rightLink, color: "secondary.dark" }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/teacher/${id}/create-course`);
                  }}
                >
                  {"Create"}
                </Link>

                <Link
                  color="inherit"
                  variant="h6"
                  underline="none"
                  // href="/teacher/login"
                  onClick={async () => {
                    await logoutsuccess()
                      .then((data) => {
                        localStorage.removeItem("accessToken");
                        localStorage.removeItem("email");
                        localStorage.removeItem("user");
                        navigate("/teacher/login");
                      })
                      .catch((error) => {
                        console.log(JSON.stringify(error, null, 2));
                      });
                  }}
                  sx={{ ...rightLink, color: "black" }}
                >
                  {"Logout"}
                </Link>
              </>
            ) : (
              <Link
                color="inherit"
                variant="h6"
                underline="none"
                href="/teacher/login"
                sx={{ rightLink, color: "black" }}
              >
                {"Login"}
              </Link>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default TeacherAppBar;
