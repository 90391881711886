import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../custom/Typography";
import Snackbar from "../custom/SnackBar";
import Button from "../custom/Button";
import { styled } from "@mui/material/styles";
import background from "../assets/productCTAImageDots.png";
import founder from "../assets/founder.png";
import { ImageIconButton } from "./LandingYogaKnowledge";
import { useNavigate } from "react-router-dom";
const images = [
  {
    url: `url(${founder})`,
    title: "Our Founder",
    width: "100%",
  },
];
export const ImageBackdrop = styled("div")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: "#000",
  opacity: 0.5,
  height: 450,
  transition: theme.transitions.create("opacity"),
}));

function ProductCTA() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container component="section" sx={{ mt: 10, display: "flex" }}>
      <Grid container>
        <Grid item xs={12} md={6} sx={{ zIndex: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              bgcolor: "warning.light",
              py: 7,
              px: 3,
            }}
          >
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ maxWidth: 400 }}
              align={"center"}
            >
              <Typography
                variant="h2"
                sx={{ fontSize: 35 }}
                component="h2"
                gutterBottom
              >
                Our Vission & Mission
              </Typography>
              <Typography sx={{ fontSize: 20 }}>
                Making Yoga accessible to every individual!
              </Typography>
              <Box align="center" sx={{ fontSize: 20 }}>
                <Typography sx={{ fontSize: 25, fontWeight: 10 }}>
                  ...
                </Typography>
              </Box>
              <Typography sx={{ fontSize: 20 }}>
                Trained Yoga Experts conducting lessons for anyone with the
                slightest intention & willingness to pursue, explore Yoga at
                their own time.
              </Typography>
              <Box align="center">
                <Typography sx={{ fontSize: 25 }}>...</Typography>
              </Box>
              <Typography sx={{ fontSize: 20 }}>
                Healthy Living & Living Well Is the birthright of every
                individual.
              </Typography>
              <Box paddingTop={2}></Box>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                sx={{ width: "100%" }}
                onClick={()=>{
                  navigate('/about-founder')
                }}
              >
                Our Founder
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{ display: { md: "block", xs: "none" }, position: "relative" }}
        >
          <Box
            sx={{
              position: "absolute",
              top: -47,
              left: -67,
              right: 0,
              bottom: 0,
              width: "100%",
              background: `url(${background})`,
              zIndex: -1,
            }}
          />

          <Box sx={{ mt: 4.5, display: "flex", flexWrap: "wrap" }}>
            {images.map((image) => (
              <ImageIconButton
                key={image.title}
                style={{
                  width: image.width,
                }}
                onClick={() => {
                  navigate("/about-founder/");
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    backgroundSize: "cover",
                    backgroundPosition: "center 20%",
                    height: 450,
                    backgroundImage: `url(${founder})`,
                  }}
                />
                <ImageBackdrop className="imageBackdrop" />
                <Box
                  sx={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 450,
                    color: "common.white",
                  }}
                >
                  <Typography
                    component="h3"
                    variant="h6"
                    color="inherit"
                    className="imageTitle"
                  >
                    {image.title}
                    <div className="imageMarked" />
                  </Typography>
                </Box>
              </ImageIconButton>
            ))}
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        closeFunc={handleClose}
        message="Cheers! We will reach out to you soon."
      />
    </Container>
  );
}

export default ProductCTA;
