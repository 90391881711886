import React from "react";
import withRoot from "../withRoot";
import Container from "@mui/material/Container";
import Typography from "../custom/Typography";
import { Box, Grid } from "@mui/material";
import image from "../assets/appCurvyLines.png";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Fetch_All_Student } from "../schema/student";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import MenuItem from "@mui/material/MenuItem";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import {
  KeyboardArrowDown,
  PaymentOutlined,
  PaymentsOutlined,
} from "@mui/icons-material";
import {
  StyledTableCell,
  StyledTableRow,
} from "../components/teacher/MyCourse";
import { StyledMenu } from "../components/admin/AdminMenu";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const STRIPE_START = gql`
  mutation InitiateStripe($data: InitiateInput!) {
    initiateStripe(data: $data) {
      goto_url
    }
  }
`;
function Row(props) {
  const { row } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <StyledTableCell component="th" scope="row" align="center">
          {row?.title.charAt(0).toUpperCase() + row?.title?.slice(1)}{" "}
        </StyledTableCell>
        <StyledTableCell align="center">
          {row?.description?.charAt(0).toUpperCase() +
            row.description?.slice(1)}
        </StyledTableCell>
        <StyledTableCell align="center"> {row.teacher?.name}</StyledTableCell>
        <StyledTableCell align="center">
          {" "}
          {row?.classes[0]?.name}
        </StyledTableCell>
        <StyledTableCell align="center">
          {" "}
          {row?.classes[0]?.description}
        </StyledTableCell>
        <StyledTableCell align="center">
          {" "}
          {row?.classes[0]?.days}
        </StyledTableCell>
        <StyledTableCell align="center">
          {row?.classes[0]?.currency} {row?.classes[0]?.fees}
        </StyledTableCell>
        <StyledTableCell align="center">
          {row?.classes[0]?.startTime}
        </StyledTableCell>
        <StyledTableCell align="center">
          {
            <Box>
              <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                size="small"
                onClick={handleClick}
                color="secondary"
                endIcon={<KeyboardArrowDown />}
              >
                Register
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    localStorage.setItem("id", row?.id);
                    props.navigate("/pay-off");
                  }}
                  disableRipple
                >
                  <PaymentOutlined /> Pay with PayPal
                </MenuItem>
                <MenuItem
                  onClick={async () => {
                    const res = await props.stripeStartf({
                      variables: {
                        data: {
                          id: parseInt(row.id),
                        },
                      },
                    });
                    if (res.data.initiateStripe.goto_url) {
                      window.location.href = res.data.initiateStripe.goto_url;
                    }
                  }}
                  disableRipple
                >
                  <PaymentsOutlined /> Pay with Stripe
                </MenuItem>
              </StyledMenu>
            </Box>
          }
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}
const CoursesLanding = () => {
  const navigate = useNavigate();
  const [stripeStartf] = useMutation(STRIPE_START);
  const { data, error, loading, refetch } = useQuery(Fetch_All_Student);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [opeN, setOpeN] = React.useState(false);
  const handleClickOpen = () => {
    setOpeN(true);
  };

  const handleClose = () => {
    setOpeN(false);
  };

  const handleOK = () => {
    setOpeN(false);
    // navigate("/");
    localStorage.clear();
    window.location.reload();
  };

  // const [rows, setRows] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  if (loading) {
    return <pre>Loading...</pre>;
  }
  // if (error) {
  //   return <center>{error.message}</center>;
  // }
  if (error) {
    if (!opeN) {
      handleClickOpen();
    }
    return (
      <div>
        <Dialog
          open={opeN}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Error Encountered!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              We have encountered an error! Click on <strong>OK</strong> and try
              loading again
              <br />
              Reason: {error?.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={handleClose}>
              Cancel
            </Button>
            <Button color="success" onClick={handleOK} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        backgroundImage: `url(${image})`,
        backgroundRepeat: "no-repeat",
      }}
      //   component={Container}
    >
      <Container component="section" sx={{ mt: 8, mb: 4 }}>
        <Typography variant="h4" marked="center" align="center" component="h2">
          Register to our Courses
        </Typography>
        <Container
          component="section"
          sx={{
            flexWrap: "wrap",
            alignContent: "center",
            mt: 8,
            mb: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              mt: 2,
              mb: 4,
              overflowX: "auto",
            }}
          >
            <TableContainer
              sx={{
                width: "100%",
                display: "table",
                tableLayout: "fixed",
              }}
              component={Paper}
            >
              <Grid item xs={12}>
                <Table stickyHeader aria-label="collapsible table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell align="center">
                        Course Title
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Course Description
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Instructor
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Class Name
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Class Description
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Class Day
                      </StyledTableCell>
                      <StyledTableCell align="center">Fees</StyledTableCell>
                      <StyledTableCell align="center">
                        Class Time
                      </StyledTableCell>
                      <StyledTableCell align="center">Book</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {data?.getStudentCourses?.map((row) => (
                      <Row
                        key={row.id}
                        row={row}
                        navigate={navigate}
                        refetch={refetch}
                        stripeStartf={stripeStartf}
                      />
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={data?.getStudentCourses?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </TableContainer>
          </Box>
        </Container>
      </Container>
    </Box>
  );
};

export default withRoot(CoursesLanding);
