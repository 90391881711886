import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Typography from "../custom/Typography";
import fb from "../assets/appFooterFacebook.png";
import twitter from "../assets/appFooterTwitter.png";
import insta from "../assets/instagram.png";
import linkedIn from "../assets/linkedIn.png";
import wapp from "../assets/whatsapp.png";
function Copyright() {
  return (
    <React.Fragment>
      {"© "}
      <Link
        color="secondary.dark"
        style={{ cursor: "pointer" }}
        href="https://prithivigrow.com/"
        target="_blank"
      >
        Prithivi Grow Care
      </Link>{" "}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

const iconStyle = {
  width: 48,
  height: 48,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "warning.main",
  mr: 1,
  "&:hover": {
    bgcolor: "warning.dark",
  },
};

export default function AppFooter() {
  return (
    <Typography
      component="footer"
      sx={{ display: "flex", bgcolor: "secondary.light" }}
    >
      <Container sx={{ my: 8, display: "flex", flexDirection: "column" }}>
        <Grid container spacing={5} sx={{ display: "flex", flexGrow: 1 }}>
          <Grid item xs={12} sm={4} md={3}>
            <Grid
              container
              direction="column"
              justifyContent="flex-end"
              flexDirection={"column"}
              spacing={2}
              sx={{ height: 120 }}
            >
              <Grid item sx={{ display: "flex" }}>
                <Box
                  component="a"
                  href="https://www.facebook.com/prithivigrowyoga"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={iconStyle}
                >
                  <img src={fb} alt="Facebook" />
                </Box>
                <Box
                  component="a"
                  href="https://twitter.com/prithiviyoga"
                  rel="noopener noreferrer"
                  target="_blank"
                  sx={iconStyle}
                >
                  <img src={twitter} alt="Twitter" />
                </Box>
                <Box
                  component="a"
                  href="https://www.instagram.com/prithivigrowyoga/"
                  rel="noopener noreferrer"
                  target="_blank"
                  sx={iconStyle}
                >
                  <img src={insta} alt="insta" />
                </Box>
                <Box
                  component="a"
                  href="https://www.linkedin.com/company/prithivigrowyoga"
                  rel="noopener noreferrer"
                  target="_blank"
                  sx={iconStyle}
                >
                  <img src={linkedIn} alt="linkedIn" />
                </Box>
                <Box
                  component="a"
                  href="https://wa.me/17325998914"
                  rel="noopener noreferrer"
                  target="_blank"
                  sx={iconStyle}
                >
                  <img src={wapp} alt="Whatsapp" />
                </Box>
              </Grid>
              <Grid item>
                <Copyright />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Typography variant="h6" marked="left" gutterBottom>
              Explore
            </Typography>
            <Box component="ul" sx={{ m: 0, listStyle: "none", p: 0 }}>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link
                  href="/about-us"
                  style={{ cursor: "pointer" }}
                  sx={{ color: "warning.dark", fontSize: 18 }}
                >
                  About Us
                </Link>
              </Box>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link
                  href="/knowledge"
                  style={{ cursor: "pointer" }}
                  sx={{ color: "secondary.dark", fontSize: 18 }}
                >
                  Yoga Knowledge
                </Link>
              </Box>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link
                  href="/contact-us"
                  style={{ cursor: "pointer" }}
                  sx={{ color: "warning.dark", fontSize: 18 }}
                >
                  Contact Us
                </Link>
              </Box>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link
                  href="/about-founder"
                  style={{ cursor: "pointer" }}
                  sx={{ color: "secondary.dark", fontSize: 18 }}
                >
                  About the Founder
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
