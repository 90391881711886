import * as React from "react";
import MuiAppBar from "@mui/material/AppBar";

function AppBar(props) {
  return (
    <MuiAppBar
      sx={{
        opacity: 0.85,
        "&:hover": {
          opacity:1,
        },
      }}
      elevation={0}
      position="fixed"
      {...props}
    />
  );
}

export default AppBar;
