import * as React from "react";
import ProductCategories from "./views/LandingYogaKnowledge";
import ProductSmokingHero from "./views/ContactUsButton";
import AppFooter from "./views/AppFooter";
import ProductHero from "./views/LandingFrontPage";
import ProductValues from "./views/LandingSecondPage";
import ProductHowItWorks from "./views/LandingHowItWorks";
import ProductCTA from "./views/LandingVisionMission";
import AppAppBar from "./views/AppAppBar";
import withRoot from "./withRoot";
import CoursesLanding from "./views/CoursesLanding";
import Testimonials from "./views/Testimonial";

function Index() {
  return (
    <React.Fragment>
      <AppAppBar />
      <ProductHero />
      <ProductValues />
      <ProductCategories />
      <ProductHowItWorks />
      <CoursesLanding />
      <Testimonials />
      <ProductCTA />
      <ProductSmokingHero />
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Index);
