import { gql } from "@apollo/client";

// MUTATIONS
export const Create_Teacher = gql`
  mutation Mutation($data: TeacherCreateInput!) {
    createTeacher(data: $data) {
      email
      id
      name
      phone
      username
    }
  }
`;

export const Teacher_Login = gql`
  mutation Mutation($data: LoginTeacherInput!) {
    teacherlogin(data: $data) {
      email
      id
      name
      username
    }
  }
`;

export const Create_Course = gql`
  mutation CreateCourse($data: CourseInput!) {
    createCourse(data: $data) {
      description
      id
      title
    }
  }
`;

export const Delete_Course = gql`
  mutation OneTeacherDeleteCourse($data: DeleteCourse!) {
    oneTeacherDeleteCourse(data: $data) {
      description
      id
      name
    }
  }
`;

export const logout = gql`
  mutation Mutation {
    logoutsuccess
  }
`;

export const backupapprove = gql`
  mutation ApproveBackupCourse($data: DeleteCourse!) {
    approveBackupCourse(data: $data) {
      backup_status
      backup_teacher {
        id
      }
      id
      status
      title
    }
  }
`;

export const backupreject = gql`
  mutation RejectBackupCourse($data: DeleteCourse!) {
    rejectBackupCourse(data: $data) {
      backup_status
      backup_teacher {
        id
      }
      id
      title
      status
    }
  }
`;

export const create_class = gql`
  mutation CreateClass($data: ClassInput!) {
    createClass(data: $data) {
      course_id
      days
      id
      name
      teacher_id
      startTime
      description
    }
  }
`;

export const Update_backup = gql`
  mutation UpdateCourse($data: UpdateInput!) {
    updateCourse(data: $data) {
      backup_status
      backup_teacher {
        email
      }
      classes {
        course_id
        days
        description
        id
        name
        startTime
        teacher_id
      }
      description
      id
      title
      status
    }
  }
`;

//QUERIES
export const Self_Teacher = gql`
  query TeacherInfo {
    teacherInfo {
      email
      id
      name
      username
      phone
    }
  }
`;

export const One_Teacher_Course = gql`
  query OneTeacherCourses {
    oneTeacherCourses {
      backup_status
      backup_teacher {
        email
        id
        username
        name
      }
      classes {
        days
        currency
        description
        fees
        link
        name
        startTime
        meetingId
        passCode
        currReg
      }
      description
      id
      title
      status
    }
  }
`;

export const One_Backup_Teacher = gql`
  query OneBackupTeacher {
    oneBackupTeacher {
      backup_status
      description
      id
      title
      status
      teacher {
        email
        name
        id
        username
      }
    }
  }
`;

export const Backup_Fetch = gql`
  query AllTeacherInfo {
    allTeacherInfo {
      email
      id
      name
      username
    }
  }
`;
