import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../custom/Typography";
import image from "../assets/yogaCA.png";
import image1 from "../assets/yogaCA2.png";
import bg from "../assets/productCurvyLines.png";
import pranayama from "../assets/pranayama (1).png";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 0,
};

function ProductValues() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", overflow: "hidden", bgcolor: "secondary.light" }}
    >
      <Container sx={{ mt: 15, mb: 30, display: "flex", position: "relative" }}>
        <Box
          component="img"
          src={bg}
          alt="curvy lines"
          sx={{ pointerEvents: "none", position: "absolute", top: -180 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box component="img" src={pranayama} sx={{ height: 65 }} />
              <Typography variant="h6" sx={{ my: 3 }}>
                Pranayama
              </Typography>
              <Box align="center">
                <Typography variant="h5">
                  {
                    "Pranayama, the art of breath regulation, is a profound practice that combines the essence of 'Prana' - life force energy, with 'Yama', which signifies the act of skilfully regulating or warehousing this vital energy. Engaging in pranayama offers a plethora of benefits through various breathing exercises. Here are some notable advantages such as Diaphragmatic Breathing, Nitric Oxide Production and increased Lung Capacity"
                  }
                </Typography>
              </Box>
              <Box paddingTop={0.65} align="center">
                <Typography variant="h5">
                  {
                    "By engaging in regular pranayama practices, one can experience these benefits, thereby fostering a harmonious union between the breath, body, and mind. Pranayama serves as a powerful tool to optimize our respiratory health and enhance our overall well-being."
                  }
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={image1}
                alt="graph"
                sx={{ height: 65 }}
              />
              <Typography variant="h6" sx={{ my: 3 }}>
                Asana
              </Typography>
              <Box align="center">
                <Typography variant="h5">
                  {
                    "Asana, derived from the Sanskrit word meaning 'seat' or 'posture,' embodies the art of finding a steady and comfortable position. The practice of asanas, or yoga exercises, offers a myriad of benefits for our well-being."
                  }
                </Typography>
              </Box>
              <Box paddingTop={0.65} align="center">
                <Typography variant="h5">
                  {
                    "Yoga postures encompass a diverse range, including standing, sitting, and lying down positions. By embracing a regular practice of asanas, we embark on a transformative journey that harmonizes our physical body with our mental and spiritual well-being. Each posture cultivates balance, strength, and flexibility, nurturing a state of holistic health and vitality"
                  }
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={image}
                alt="clock"
                sx={{ width: 55, height: 65 }}
              />
              <Typography variant="h6" sx={{ my: 3 }}>
                Meditation
              </Typography>
              <Box align="center">
                <Typography variant="h5">
                  {
                    "Meditation, also known as Dhayana, holds the transformative power to grant us profound rest within a remarkably short span of time. By anchoring our mind in the present moment, meditation allows us to relinquish attachment to the past and experience deep serenity and stillness."
                  }
                </Typography>
              </Box>
              <Box paddingTop={0.65} align="center">
                <Typography variant="h5">
                  {
                    "The practice of meditation yields an array of remarkable benefits, including but not limited to Increased Grey Matter, Induction of Happy Hormones, Reduction of Stress Hormones, Blood Pressure Regulation and Anti-Aging Effects."
                  }
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
