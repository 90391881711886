import { useQuery } from "@apollo/client";
import React from "react";
import { Admin_Students_Fetch } from "../../schema/admin";
import withRoot from "../../withRoot";
import AdminAppBar from "../../views/AdminAppBar";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
} from "@mui/material";
import Typography from "../../custom/Typography";

import {
  StyledCell,
  StyledTableCell,
  StyledTableRow,
} from "../teacher/MyCourse";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ButtoN from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <StyledTableCell>
          {row.registered ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          ) : null}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row.id}
        </StyledTableCell>
        <StyledTableCell align="left">{row?.name}</StyledTableCell>
        <StyledTableCell align="center"> {row.email}</StyledTableCell>
        <StyledTableCell align="center">{row?.phone}</StyledTableCell>
        <StyledTableCell align="center">
          {row.registered ? (
            <Typography color="#008000">Registered</Typography>
          ) : (
            <Typography color="error">Not Registered</Typography>
          )}
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Student Information
              </Typography>
              <Table size="large" aria-label="course">
                <TableHead>
                  <StyledTableRow>
                    <StyledCell>Gender</StyledCell>
                    <StyledCell>Age Group</StyledCell>
                    <StyledCell align="center">Occupation</StyledCell>
                    <StyledCell align="center">Country</StyledCell>
                    <StyledCell align="center">Time-Zone</StyledCell>
                    <StyledCell align="center">Primary</StyledCell>
                    <StyledCell align="center">Secondary</StyledCell>
                    <StyledCell align="center">Injury</StyledCell>
                    <StyledCell align="center">Medical Condition</StyledCell>
                    <StyledCell align="center">Active Hours</StyledCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow key={row?.id}>
                    <StyledTableCell component="th" scope="row">
                      {row?.gender?.charAt(0).toUpperCase() +
                        row?.gender?.slice(1)}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row?.age}</StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.occupation}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.country}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.timeZone}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.primary}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.secondary}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.isInjury ? "Yes" : "No"}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.isMedicalCondtion ? "Yes" : "No"}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.activity?.match(/\d+/)[0]} Hrs/day
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledCell>
      </StyledTableRow>
      {/*   <StyledTableRow>
          <StyledCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Back Up Courses
                </Typography>
                <Table size="large" aria-label="course">
                  <TableHead>
                    <StyledTableRow>
                      <StyledCell>Course Title</StyledCell>
                      <StyledCell>Assigned By</StyledCell>
                      <StyledCell>Course Description</StyledCell>
                      <StyledCell>Created At</StyledCell>
                      <StyledCell align="center">Status</StyledCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {row?.backup_courses?.map((backup) => (
                      <StyledTableRow key={backup.id}>
                        <StyledTableCell component="th" scope="row">
                          {backup.title}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {backup?.teacher?.name}- {backup?.teacher?.email}
                        </StyledTableCell>
                        <StyledTableCell>{backup.description}</StyledTableCell>
                        <StyledTableCell align="center">
                          {new Date(
                            parseInt(backup.createdAt)
                          ).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          })}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {backup.backup_status === 1 ? (
                            <Typography sx={{ color: "#FF8F00" }}>
                              Pending
                            </Typography>
                          ) : (
                            <>
                              {backup.backup_status === 2 ? (
                                <Typography color={"#008000"}>
                                  Approved
                                </Typography>
                              ) : (
                                <Typography color={"red"}>Rejected</Typography>
                              )}
                            </>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </StyledCell>
        </StyledTableRow> */}
    </React.Fragment>
  );
}
const AdminStudents = () => {
  const { data, loading, error, refetch } = useQuery(Admin_Students_Fetch);
  const [opeN, setOpeN] = React.useState(false);
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpeN(true);
  };

  const handleClose = () => {
    setOpeN(false);
  };

  const handleOK = () => {
    setOpeN(false);
    navigate("/");
    localStorage.clear();
  };

  if (loading) {
    return <pre>Loading</pre>;
  }

  if (error) {
    if (!opeN) {
      handleClickOpen();
    }
    return (
      <div>
        <Dialog
          open={opeN}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Error Encountered!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              We have encountered an error! Click on <strong>OK</strong> and try
              logging in again
              <br />
              Reason: {error?.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtoN color="error" onClick={handleClose}>
              Cancel
            </ButtoN>
            <ButtoN color="success" onClick={handleOK} autoFocus>
              OK
            </ButtoN>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  return (
    <React.Fragment>
      <AdminAppBar />
      <Box align="center" padding={1}>
        <Typography variant={"h4"} color="textColor">
          Students
        </Typography>
      </Box>
      <Box
        margin={2.5}
        sx={{
          display: "flex",
          mt: 2,
          mb: 4,
        }}
      >
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="collapsible table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell />
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell align="center">Email</StyledTableCell>
                <StyledTableCell align="center">Phone</StyledTableCell>
                <StyledTableCell align="center">Registered</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {data.fetchStudents?.students?.map((row) => (
                <Row
                  key={row.id}
                  row={row}
                  //   approvef={approvef}
                  //   rejectf={rejectf}
                  refetch={refetch}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </React.Fragment>
  );
};

export default withRoot(AdminStudents);
