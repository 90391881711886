import React from "react";
import AppAppBar from "../views/AppAppBar";
import AppFooter from "../views/AppFooter";
import withRoot from "../withRoot";
import Button from "../custom/Button";
import Typography from "../custom/Typography";
import pic from "../assets/yoga2.jpg";
import ProductHeroLayout from "../views/LandingFrontPageLayout";
import { Box, Container, Link } from "@mui/material";
const ImageComponent = () => {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${pic})`,
        backgroundColor: "#7fc7d9", // Average color of the background image.
        backgroundPosition: "center",
      }}
    >
      <img style={{ display: "none" }} src={pic} alt="increase priority" />
      <Typography
        color="secondary"
        align="center"
        sx={{ fontSize: 50, fontFamily: "revert" }}
        marked="center"
      >
        About the Founder
      </Typography>
      <Typography
        color="inherit"
        align="center"
        sx={{
          mb: 4,
          fontSize: 40,
          mt: { xs: 4, sm: 10 },
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        Mr. Shreedhar Thuljaram
      </Typography>
      <Box sx={{ color: "#008000" }}>
        <Button
          color="secondary"
          variant="outlined"
          size="large"
          component="a"
          sx={{ minWidth: 200 }}
        >
          More Below
        </Button>
      </Box>
    </ProductHeroLayout>
  );
};

const AboutFounder = () => {
  return (
    <React.Fragment>
      <AppAppBar />
      <ImageComponent />
      <Box
        margin={2.5}
        sx={{
          display: "flex",
          mt: 2,
          mb: 4,
          // backgroundColor:'secondary.light'
        }}
      >
        <Container
          sx={{
            mt: 2,
            mb: 15,
            position: "relative",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
          }}
        >
          <Box align="center">
            <Typography
              variant="h4"
              marked="center"
              component="h2"
              sx={{ mb: 5, textTransform: "capitalize" }}
            >
              About the Founder
            </Typography>
          </Box>
          <Box padding={1.5}>
            <Typography
              variant={"h5"}
              sx={{ fontSize: 22, fontFamily: "oxygen" }}
            >
              {
                "Shreedhar Thuljaram is a mechanical engineering graduate and has a master’s degree in data science from Liverpool John Moores University, UK. He has been working in the IT industry for 30 years. He has been teaching yoga and meditation since 2007. He has been practicing meditation for over 15 years."
              }
            </Typography>
          </Box>
          <Box padding={1.5}>
            <Typography
              variant={"h5"}
              sx={{ fontSize: 22, fontFamily: "oxygen" }}
            >
              {
                "Currently, he lives in Dallas, Texas, USA with his family and prior to that, he worked in Jakarta, Singapore, Dubai, and Riyadh"
              }
            </Typography>
          </Box>
          <Box padding={1.5}>
            <Typography
              variant={"h5"}
              sx={{ fontSize: 22, fontFamily: "oxygen" }}
            >
              {
                "He has suffered from many illnesses due to stress, bad lifestyle and bad diet. He was under medication for sinuses, pre-diabetes, migraines, hyper-acidity, lower back pain, and high cholesterol. He also often suffered from fever, cold and cough. He took up Yoga to overcome his ailments."
              }
            </Typography>
          </Box>
          <Box padding={1.5}>
            <Typography
              variant={"h5"}
              sx={{ fontSize: 22, fontFamily: "oxygen" }}
            >
              {
                "He has seen great improvement in his health after practicing yoga and meditation and following a proper lifestyle and diet. He has stopped needing most of the medications. This made him curious to dive deeper into yoga. So, he attended yoga teachers training in Montreal, Canada in 2007 again in 2021 in Bangalore, India. He has learned yoga and meditation from his gurus Krishan Verma and Dr Shriram. He expresses his deepest gratitude to them for being in his life and showing him the path. "
              }
            </Typography>
          </Box>
          <Box padding={1.5}>
            <Typography
              variant={"h5"}
              sx={{ fontSize: 22, fontFamily: "oxygen" }}
            >
              "He has also volunteered with Art Of Living, teaching the
              Happiness Program and Sri Sri Yoga since 2007. He is also a
              volunteer and Director of Programs with Overseas Volunteers for
              Better Health, a non-governmental organization involved in
              rejuvenating dried up rivers in India (
              <Link
                style={{ cursor: "pointer" }}
                href="https://www.eliminatingwaterpoverty.org/"
                rel="noopener noreferrer"
                target="_blank"
                underline="always"
                color="secondary.dark"
              >
                eliminatingwaterpoverty.org
              </Link>{" "}
              )."
            </Typography>
          </Box>
          <Box padding={1.5}>
            <Typography
              variant={"h5"}
              sx={{ fontSize: 22, fontFamily: "oxygen" }}
            >
              "He donates 20% of the generated fees from courses to service
              projects like Care for Children, providing free schools to tribal
              and slum children of India (
              <Link
                href="https://www.careforchildren.org/"
                style={{ cursor: "pointer" }}
                rel="noopener noreferrer"
                target="_blank"
                underline="always"
                color="secondary.dark"
              >
                www.careforchildren.org
              </Link>{" "}
              )."
            </Typography>
          </Box>
          <Box padding={1.5}>
            <Typography
              variant={"h5"}
              sx={{ fontSize: 22, fontFamily: "oxygen" }}
            >
              He always quotes from an old Tamil saying, ‘யாம் பெற்ற இன்பம்,
              பெறுக இவ்வயகம்’ , meaning ‘let the world also experience the
              bliss, joy and well-being that I experience in my life.'
            </Typography>
          </Box>
          <Box padding={1.5}>
            <Typography
              variant={"h5"}
              sx={{ fontSize: 22, fontFamily: "oxygen" }}
            >
              {
                "With that conviction, he has started yoga workshops and classes to benefit many, so they can experience inner peace, flexibility, physical strength and well-being."
              }
            </Typography>
          </Box>
          <Box paddingTop={2} align="center">
            <Typography
              variant="h4"
              marked="center"
              component="h2"
              sx={{ mb: 5, textTransform: "capitalize" }}
            >
              Our Instructors
            </Typography>
          </Box>
          <Box padding={1.5}>
            <Typography
              variant={"h5"}
              sx={{ fontSize: 22, fontFamily: "oxygen" }}
            >
              {
                "Shreedhar Thuljaram is a very experienced yoga teacher and has been teaching ‘Joy of Yoga’ since 2007. He has facilitated many yoga courses in the USA, Singapore, and India."
              }
            </Typography>
          </Box>
          <Box padding={1.5}>
            <Typography
              variant={"h5"}
              sx={{ fontSize: 22, fontFamily: "oxygen" }}
            >
              {
                "He did his yoga teachers training with Art of Living in 2007 for 200 hrs and 2021 for 300 hrs. He is a 500-hour registered yoga trainer with Yoga Alliance (YA id:58897)."
              }
            </Typography>
          </Box>
        </Container>
      </Box>
      <AppFooter />
    </React.Fragment>
  );
};

export default withRoot(AboutFounder);
