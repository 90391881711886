import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Self_Teacher } from "../../schema/teacher";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import TeacherAppBar from "../../views/TeacherAppBar";
import withRoot from "../../withRoot";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../../custom/Typography";
import bg from "../../assets/productCurvyLines.png";
import { Stack } from "@mui/material";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

const TeacherHome = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, error, loading } = useQuery(Self_Teacher);
  if (error) {
    return <pre>{error.message}</pre>;
  }
  if (loading) {
    return <pre>Loading</pre>;
  }
  return (
    <React.Fragment>
      <TeacherAppBar />
      <Box
        sx={{ overflow: "hidden", bgcolor: "secondary.light" }}
      >
        <Stack
          direction="column"
          justifyContent="space-evenly"
          marginLeft={3}
          spacing={{ xs: 1, sm: 2 }}
        >
          <Typography>
            Welcome,{" "}
            <Typography
              sx={{ fontSize: 20 }}
              color="secondary.dark"
              fontWeight="bold"
            >
              {data.teacherInfo.name}
            </Typography>{" "}
          </Typography>
          <Typography>
            Necessary information will shared to you from our side via{" "}
            <Typography
              sx={{ fontSize: 20 }}
              color="secondary.dark"
              fontWeight="bold"
            >
              {data.teacherInfo.email}
            </Typography>{" "}
            as given by you.
          </Typography>
          <Typography>
            Your Contact Information is{" "}
            <Typography
              sx={{ fontSize: 20 }}
              color="secondary.dark"
              fontWeight="bold"
            >
              {data.teacherInfo.phone}
            </Typography>
          </Typography>
        </Stack>
      </Box>
      <Box
        sx={{ display: "flex", overflow: "hidden", bgcolor: "secondary.light" }}
      >
        <Container
          sx={{ mt: 10, mb: 15, display: "flex", position: "relative" }}
        >
          <Box
            component="img"
            src={bg}
            alt="curvy lines"
            sx={{ pointerEvents: "none", position: "absolute",left:-100, top: -180 }}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={{ height: 65 }}>
                  <Button
                    variant="contained"
                    color="textColor"
                    onClick={(e) => {
                      navigate(`/teacher/${id}/create-course`);
                    }}
                    size="large"
                  >
                    <Typography
                      color="primary"
                      fontWeight="bold"
                      sx={{ fontSize: 20 }}
                    >
                      Create
                    </Typography>
                  </Button>
                </Box>
                <Typography variant="h6" sx={{ my: 5 }}>
                  Create Your Course
                </Typography>
                <Typography variant="h5">
                  {
                    "Create your courses as classes or workshops along with proper details. Give the MEETING LINKS AND RELATED INFORMATION without fail. Wait for the course approval to get started."
                  }
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={{ height: 65 }}>
                  <Button
                    variant="contained"
                    color="textColor"
                    onClick={(e) => {
                      navigate(`/teacher/${id}/my-courses`);
                    }}
                    size="large"
                  >
                    <Typography
                      color="primary"
                      fontWeight="bold"
                      sx={{ fontSize: 20 }}
                    >
                      My Courses
                    </Typography>
                  </Button>
                </Box>
                <Typography variant="h6" sx={{ my: 5 }}>
                  Your Courses
                </Typography>
                <Typography variant="h5">
                  {
                    "Check your created courses containing classes and workshops here.We will go through the course fee and other details.Upon the approval of Admin, your course is shown for student enrollment."
                  }
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={{ height: 65 }}>
                  <Button
                    variant="contained"
                    color="textColor"
                    onClick={(e) => {
                      navigate(`/teacher/${id}/backup-courses`);
                    }}
                    size="large"
                  >
                    <Typography
                      color="primary"
                      fontWeight="bold"
                      sx={{ fontSize: 20 }}
                    >
                      Assigned to you
                    </Typography>
                  </Button>
                </Box>
                <Typography variant="h6" sx={{ my: 5 }}>
                  Assigned as Back-up
                </Typography>
                <Typography variant="h5">
                  {
                    "You can see the courses for which you have assigned as a back up teacher. You can APPROVE or REJECT upon your convenience and according mails will be sent to both the involed instructors."
                  }
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default withRoot(TeacherHome);
