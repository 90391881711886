import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  One_Teacher_Course,
  Backup_Fetch,
  Update_backup,
} from "../../schema/teacher";
import { useMutation, useQuery } from "@apollo/client";

import withRoot from "../../withRoot";
import TeacherAppBar from "../../views/TeacherAppBar";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { Grid, TableCell, styled, tableCellClasses } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { CustomComplete, CustomField } from "./CreateCourse";
import Typography from "../../custom/Typography";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E4F7E5",
    color: "#008000",
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    backgroundColor: "#FFFAF6",
  },
}));
export const StyledCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E4F7E5",
    color: "#008000",
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    backgroundColor: theme.palette.common.white,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [backups, setBackups] = React.useState("");
  const [updatef] = useMutation(Update_backup);
  const {
    data: backupList,
    loading: backupLoading,
    error: backupError,
    refetch: backupRefetch,
  } = useQuery(Backup_Fetch);
  if (backupLoading) {
    return <pre>Loading! ....</pre>;
  }
  if (backupError) {
    return <pre>{backupError?.message}</pre>;
  }
  const backupsArray = backupList.allTeacherInfo.map(
    (teacher, index) => `${teacher.id}-${teacher.name}-${teacher.email}`
  );

  return (
    <React.Fragment>
      <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row.id}
        </StyledTableCell>
        <StyledTableCell align="center">{row.title}</StyledTableCell>
        <StyledTableCell align="center">{row.description}</StyledTableCell>
        <StyledTableCell align="center">
          {row.status === 1 ? (
            <Typography sx={{ color: "#FF8F00" }}>Pending</Typography>
          ) : (
            <>
              {row.status === 2 ? (
                <Typography color={"#008000"}>Approved</Typography>
              ) : (
                <Typography color={"red"}>Rejected</Typography>
              )}
            </>
          )}
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Backup Instructor Details
              </Typography>
              <Table size="large" aria-label="backUp">
                <TableHead>
                  <StyledTableRow>
                    <StyledCell>Name</StyledCell>
                    <StyledCell>E-mail</StyledCell>
                    <StyledCell align="right">Status</StyledCell>
                    <StyledCell align="center">Edit Backup</StyledCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow key={row.id}>
                    <StyledTableCell component="th" scope="row">
                      {row.backup_teacher?.name}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.backup_teacher?.email}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.backup_status === 1 ? (
                        <Typography sx={{ color: "#FF8F00" }}>
                          Pending
                        </Typography>
                      ) : (
                        <>
                          {row.backup_status === 2 ? (
                            <Typography color={"#008000"}>Approved</Typography>
                          ) : (
                            <Typography color={"red"}>Rejected</Typography>
                          )}
                        </>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {!isEdit ? (
                        <Edit
                          color="textColor"
                          onClick={() => {
                            setIsEdit(true);
                          }}
                        />
                      ) : (
                        <Grid container spacing={0}>
                          <Grid item xs={7}>
                            {" "}
                            <CustomComplete
                              disablePortal
                              options={backupsArray}
                              onInputChange={(event, newInputValue) => {
                                setBackups(parseInt(newInputValue));
                              }}
                              renderInput={(params) => (
                                <CustomField
                                  {...params}
                                  fullWidth
                                  variant="outlined"
                                  label="Choose BackUp teacher"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={2.5}>
                            {" "}
                            <Button
                              color="secondary"
                              size="medium"
                              variant="contained"
                              onClick={async (e) => {
                                e.preventDefault();
                                const resp = await updatef({
                                  variables: {
                                    data: {
                                      course_id: props.row.id,
                                      id: parseInt(backups),
                                    },
                                  },
                                });
                                props.refetch();
                                backupRefetch();
                                return resp;
                              }}
                            >
                              Submit
                            </Button>
                          </Grid>
                          <Grid item xs={2.5}>
                            <Button
                              color="textColor"
                              variant="standard"
                              size="small"
                              onClick={() => {
                                setIsEdit(false);
                              }}
                            >
                              {" "}
                              Cancel
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Classes
              </Typography>
              <Table size="large" aria-label="course">
                <TableHead>
                  <StyledTableRow>
                    <StyledCell>Class Title</StyledCell>
                    <StyledCell align="center">Description</StyledCell>
                    <StyledCell align="center">Fees</StyledCell>
                    <StyledCell align="center">Day</StyledCell>
                    <StyledCell align="center">Time</StyledCell>
                    <StyledCell align="center">Link</StyledCell>
                    <StyledCell align="center">Meeting Id</StyledCell>
                    <StyledCell align="center">Passcode</StyledCell>
                    <StyledCell align="center">Strength</StyledCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {row?.classes?.map((course) => (
                    <StyledTableRow key={course?.id}>
                      <StyledTableCell component="th" scope="row">
                        {course?.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {course?.description}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {course?.currency} {course?.fees}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {course?.days}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {course?.startTime}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {course?.link}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {course?.meetingId}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {course?.passCode}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {course?.currReg}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

const MyCourse = () => {
  const { data, loading, error, refetch } = useQuery(One_Teacher_Course);
  if (loading) {
    return <pre>Loading! ....</pre>;
  }
  if (error) {
    return <pre>{error?.message}</pre>;
  }
  return (
    <React.Fragment>
      <TeacherAppBar />
      <Box
        margin={2.5}
        sx={{
          display: "flex",
          mt: 4,
          mb: 4,
        }}
      >
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="collapsible table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell />
                <StyledTableCell align="center">Course Id</StyledTableCell>
                <StyledTableCell align="center">Course Name</StyledTableCell>
                <StyledTableCell align="center">Description</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {data?.oneTeacherCourses?.map((row) => (
                <Row key={row.id} row={row} refetch={refetch} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </React.Fragment>
  );
};

export default withRoot(MyCourse);
