import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../custom/Typography";
import TextField from "../custom/TextField";
import Button from "../custom/Button";
import background from "../assets/background.png";
import AppAppBar from "../views/AppAppBar";
import withRoot from "../withRoot";
import { Link } from "@mui/material";
import { Mail, Phone } from "@mui/icons-material";
import fb from "../assets/appFooterFacebook.png";
import twitter from "../assets/appFooterTwitter.png";
import insta from "../assets/instagram.png";
import linkedIn from "../assets/linkedIn.png";
import wapp from "../assets/whatsapp.png";
import AppFooter from "../views/AppFooter";
import { gql, useMutation } from "@apollo/client";
const iconStyle = {
  width: 48,
  height: 48,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "warning.main",
  mr: 1,
  "&:hover": {
    bgcolor: "warning.dark",
  },
};

const SET_Q = gql`
  mutation CreateQuery($data: Inputq!) {
    createQuery(data: $data) {
      content
      email
      id
      name
    }
  }
`;

const ContactUs = () => {
  const [open, setOpen] = React.useState(false);
  const [mail, setMail] = React.useState(false);
  const [name, setName] = React.useState(false);
  const [content, setContent] = React.useState(false);
  const [setf] = useMutation(SET_Q);

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <React.Fragment>
      <AppAppBar />
      <Container component="section" sx={{ mt: 10, display: "flex" }}>
        <Grid container>
          <Grid item xs={12} md={6} sx={{ zIndex: 1 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                bgcolor: "warning.main",
                py: 8,
                px: 3,
              }}
            >
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{ maxWidth: 400 }}
              >
                <Typography variant="h2" component="h2" gutterBottom>
                  For Assistance
                </Typography>
                <Typography variant="h5">
                  Embrace the divinity of yoga from your home with us.
                </Typography>
                <TextField
                  noBorder
                  required
                  placeholder="Your email"
                  variant="standard"
                  sx={{ width: "100%", mt: 2, mb: 1 }}
                  onChange={(e) => setMail(e.target.value)}
                />
                <TextField
                  noBorder
                  required
                  placeholder="Your name"
                  variant="standard"
                  sx={{ width: "100%", mt: 1, mb: 1 }}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  noBorder
                  required
                  multiline
                  rows={3}
                  placeholder="Your query or comment"
                  variant="standard"
                  sx={{ width: "100%", mt: 1, mb: 1 }}
                  onChange={(e) => setContent(e.target.value)}
                />
                <Button
                  // type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ width: "100%" }}
                  onClick={async (e) => {
                    e.preventDefault();
                    await setf({
                      variables: {
                        data: {
                          email: mail,
                          content: content,
                          name: name,
                        },
                      },
                    });
                    alert(
                      "We received your request. Please wait while we take a look at it."
                    );
                  }}
                >
                  Update Me!
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: { md: "block", xs: "none" }, position: "relative" }}
          >
            <Box
              sx={{
                position: "absolute",
                top: -67,
                left: -67,
                right: 0,
                bottom: 0,
                width: "100%",
                background: `url(${background})`,
              }}
            />
            <Box
              component="form"
              onSubmit={handleSubmit}
              justifyContent={"center"}
              sx={{
                position: "relative",
                top: -28,
                left: -10,
                right: 0,
                bottom: 0,
                width: "100%",
                bgcolor: "secondary.light",
                direction: "column",
                alignItems: "center",
                justifyContent: "center",
                height: 300,
                maxWidth: 600,
              }}
            >
              <Typography
                justifyContent={"left"}
                paddingLeft={3}
                paddingTop={2}
                variant="h2"
                component="h2"
                gutterBottom
              >
                Reach Us
              </Typography>
              <Grid
                container
                paddingLeft={3}
                justifyContent={"left"}
                spacing={2}
              >
                <Grid item sx={6}>
                  <Typography sx={{ fontSize: 20 }} variant="h5">
                    <Mail /> Email Us :
                  </Typography>
                </Grid>
                <Grid item sx={7}>
                  <Link
                    href="mailto:letsdo@prithivigrowyoga.com"
                    sx={{ color: "secondary.dark", fontSize: 20 }}
                    variant="h4"
                    rel="noopener noreferrer"
                    target="_blank"
                    underline="hover"
                  >
                    {"letsdo@prithivigrowyoga.com"}
                  </Link>
                </Grid>
              </Grid>
              <Grid
                container
                paddingLeft={3}
                justifyContent={"left"}
                spacing={2}
              >
                <Grid item sx={6}>
                  <Typography sx={{ fontSize: 20 }} variant="h5">
                    <Phone /> Call Us :{" "}
                  </Typography>
                </Grid>
                <Grid item sx={7}>
                  <Typography
                    type="number"
                    variant="h5"
                    sx={{
                      color: "secondary.dark",
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                  >
                    +1 (732)599-8914
                  </Typography>
                </Grid>
              </Grid>
              <Box paddingLeft={16}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-end"
                  spacing={2}
                  sx={{ height: 120 }}
                >
                  <Grid item sx={{ display: "flex" }}>
                    <Box
                      component="a"
                      href="https://www.facebook.com/prithivigrowyoga"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={iconStyle}
                    >
                      <img src={fb} alt="Facebook" />
                    </Box>
                    <Box
                      component="a"
                      href="https://twitter.com/prithiviyoga"
                      rel="noopener noreferrer"
                      target="_blank"
                      sx={iconStyle}
                    >
                      <img src={twitter} alt="Twitter" />
                    </Box>
                    <Box
                      component="a"
                      href="https://www.instagram.com/prithivigrowyoga/"
                      rel="noopener noreferrer"
                      target="_blank"
                      sx={iconStyle}
                    >
                      <img src={insta} alt="insta" />
                    </Box>
                    <Box
                      component="a"
                      href="https://www.linkedin.com/company/prithivigrowyoga"
                      rel="noopener noreferrer"
                      target="_blank"
                      sx={iconStyle}
                    >
                      <img src={linkedIn} alt="linkedIn" />
                    </Box>
                    <Box
                      component="a"
                      href="https://wa.me/17325998914"
                      rel="noopener noreferrer"
                      target="_blank"
                      sx={iconStyle}
                    >
                      <img src={wapp} alt="Whatsapp" />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <AppFooter />
    </React.Fragment>
  );
};

export default withRoot(ContactUs);
