import { gql } from "@apollo/client";

// MUTATIONS
export const Admin_Login = gql`
  mutation AdminLogin($data: AdminLogInput!) {
    adminLogin(data: $data) {
      name
      uuid
    }
  }
`;

export const Admin_Approve = gql`
  mutation AdminApprove($data: CourseIdInput!) {
    adminApprove(data: $data) {
      id
      status
    }
  }
`;
export const Admin_Teacher_Approve = gql`
  mutation AdminApproveTeachers($data: CourseIdInput!) {
    AdminApproveTeachers(data: $data) {
      name
      username
      id
      email
      currency
    }
  }
`;

export const Admin_Reject = gql`
  mutation AdminReject($data: CourseIdInput!) {
    adminReject(data: $data) {
      id
      status
    }
  }
`;

export const Admin_Edit = gql`
  mutation AdminUpdateClass($data: ClassEditInput!) {
    adminUpdateClass(data: $data) {
      currReg
      name
    }
  }
`;

// QUERIES
export const Admin_Teacher_Fetch = gql`
  query Backup_courses {
    fetchAdminTeachers {
      backup_courses {
        backup_status
        createdAt
        description
        id
        title
        status
        teacher {
          email
          id
          name
        }
      }
      courses {
        backup_status
        backup_teacher {
          email
          id
          name
        }
        createdAt
        description
        id
        title
        status
      }
      approved
      currency
      email
      id
      name
      username
    }
  }
`;

export const Admin_Courses_Fetch = gql`
  query FetchAdminCourses {
    fetchAdminCourses {
      backup_status
      backup_teacher {
        email
        name
        id
      }
      createdAt
      description
      id
      title
      status
      teacher {
        email
        id
        name
      }
      classes {
        id
        days
        currency
        description
        fees
        link
        name
        startTime
        meetingId
        passCode
        currReg
      }
    }
  }
`;

export const Admin_Students_Fetch = gql`
  query Query {
    fetchStudents {
      students {
        name
        activity
        age
        comments
        country
        email
        gender
        id
        isInjury
        isMedicalCondition
        isTips
        occupation
        phone
        preferredPAM
        preferredYoga
        primary
        registered
        secondary
        timeZone
      }
    }
  }
`;
