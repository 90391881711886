import React from "react";
import withRoot from "../../withRoot";
import AdminAppBar from "../../views/AdminAppBar";
import { Admin_Teacher_Approve, Admin_Teacher_Fetch } from "../../schema/admin";
import { useMutation, useQuery } from "@apollo/client";
import {
  StyledCell,
  StyledTableCell,
  StyledTableRow,
} from "../teacher/MyCourse";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import Typography from "../../custom/Typography";
import Paper from "../../custom/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ButtoN from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row.id}
        </StyledTableCell>
        <StyledTableCell align="center">{row?.name}</StyledTableCell>
        <StyledTableCell align="center">{row?.username}</StyledTableCell>
        <StyledTableCell align="center"> {row.email}</StyledTableCell>
        <StyledTableCell align="center">
          {row.approved ? (
            <Typography color="textColor">Approved</Typography>
          ) : (
            <Typography color="error">Not Approved</Typography>
          )}
        </StyledTableCell>
        <StyledTableCell align="center">
          <Button
            variant="contained"
            onClick={async () => {
              await props
                .teacherApprove({
                  variables: {
                    data: {
                      id: row.id,
                    },
                  },
                })
                .then((res) => {
                  props.refetch();
                  return res;
                });
            }}
            color="textColor"
          >
            Approve
          </Button>
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Courses
              </Typography>
              <Table size="large" aria-label="course">
                <TableHead>
                  <StyledTableRow>
                    <StyledCell>Course Title</StyledCell>
                    <StyledCell>Created At</StyledCell>
                    <StyledCell align="center">Description</StyledCell>
                    <StyledCell align="center">Course Status</StyledCell>
                    <StyledCell align="center">BackUp</StyledCell>
                    <StyledCell align="center">BackUp Status</StyledCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {row?.courses?.map((course) => (
                    <StyledTableRow key={course?.id}>
                      <StyledTableCell component="th" scope="row">
                        {course?.title}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {new Date(
                          parseInt(course?.createdAt)
                        ).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        })}
                      </StyledTableCell>
                      <StyledTableCell>{course.description}</StyledTableCell>
                      <StyledTableCell align="right">
                        {course?.status === 1 ? (
                          <Typography sx={{ color: "#FF8F00" }}>
                            Pending
                          </Typography>
                        ) : (
                          <>
                            {row.backup_status === 2 ? (
                              <Typography color={"#008000"}>
                                Approved
                              </Typography>
                            ) : (
                              <Typography color={"red"}>Rejected</Typography>
                            )}
                          </>
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        {course?.backup_teacher?.name}-
                        {course?.backup_teacher?.email}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {course?.backup_status === 1 ? (
                          <Typography sx={{ color: "#FF8F00" }}>
                            Pending
                          </Typography>
                        ) : (
                          <>
                            {course?.backup_status === 2 ? (
                              <Typography color={"#008000"}>
                                Approved
                              </Typography>
                            ) : (
                              <Typography color={"red"}>Rejected</Typography>
                            )}
                          </>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Back Up Courses
              </Typography>
              <Table size="large" aria-label="course">
                <TableHead>
                  <StyledTableRow>
                    <StyledCell>Course Title</StyledCell>
                    <StyledCell>Assigned By</StyledCell>
                    <StyledCell>Course Description</StyledCell>
                    <StyledCell>Created At</StyledCell>
                    <StyledCell align="center">Status</StyledCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {row?.backup_courses?.map((backup) => (
                    <StyledTableRow key={backup.id}>
                      <StyledTableCell component="th" scope="row">
                        {backup.title}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {backup?.teacher?.name}- {backup?.teacher?.email}
                      </StyledTableCell>
                      <StyledTableCell>{backup.description}</StyledTableCell>
                      <StyledTableCell align="center">
                        {new Date(
                          parseInt(backup.createdAt)
                        ).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        })}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {backup.backup_status === 1 ? (
                          <Typography sx={{ color: "#FF8F00" }}>
                            Pending
                          </Typography>
                        ) : (
                          <>
                            {backup.backup_status === 2 ? (
                              <Typography color={"#008000"}>
                                Approved
                              </Typography>
                            ) : (
                              <Typography color={"red"}>Rejected</Typography>
                            )}
                          </>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledCell>
      </StyledTableRow>
    </React.Fragment>
  );
}
const AdminTeachers = () => {
  const { data, loading, error, refetch } = useQuery(Admin_Teacher_Fetch);
  const [teacherApprove] = useMutation(Admin_Teacher_Approve);
  const [opeN, setOpeN] = React.useState(false);
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpeN(true);
  };

  const handleClose = () => {
    setOpeN(false);
  };

  const handleOK = () => {
    setOpeN(false);
    navigate("/");
    localStorage.clear();
  };

  if (loading) {
    return <pre>Loading</pre>;
  }
  // if (error) {
  //   return <pre>{error?.message}&nbsp;</pre>;
  // }
  if (error) {
    if (!opeN) {
      handleClickOpen();
    }
    return (
      <div>
        <Dialog
          open={opeN}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Error Encountered!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              We have encountered an error! Click on <strong>OK</strong> and try
              logging in again
              <br />
              Reason: {error?.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtoN color="error" onClick={handleClose}>
              Cancel
            </ButtoN>
            <ButtoN color="success" onClick={handleOK} autoFocus>
              OK
            </ButtoN>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  return (
    <React.Fragment>
      <AdminAppBar />
      <Box align="center" padding={1}>
        <Typography variant={"h4"} color="textColor">
          Instructors
        </Typography>
      </Box>
      <Box
        margin={2.5}
        sx={{
          display: "flex",
          mt: 2,
          mb: 4,
        }}
      >
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="collapsible table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell />
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell align="center">User Name</StyledTableCell>
                <StyledTableCell align="center">Email</StyledTableCell>
                <StyledTableCell align="center">
                  Approval Status
                </StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {data.fetchAdminTeachers?.map((row) => (
                <Row
                  key={row.id}
                  row={row}
                  teacherApprove={teacherApprove}
                  //   rejectf={rejectf}
                  refetch={refetch}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </React.Fragment>
  );
};

export default withRoot(AdminTeachers);
