import React from "react";
import AppAppBar from "../views/AppAppBar";
import AppFooter from "../views/AppFooter";
import withRoot from "../withRoot";
import Button from "../custom/Button";
import Typography from "../custom/Typography";
import pic from "../assets/yogaSil.jpg";
import image from "../assets/productCurvyLines.png";
import ProductHeroLayout from "../views/LandingFrontPageLayout";
import {
  Box,
  Container,
  Link,
} from "@mui/material";
const ImageComponent = () => {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${pic})`,
        backgroundColor: "#7fc7d9", // Average color of the background image.
        backgroundPosition: "center",
      }}
    >
      <img style={{ display: "none" }} src={pic} alt="increase priority" />
      <Typography
        color="#ffffff"
        align="center"
        sx={{ fontSize: 50, fontFamily: "revert" }}
        marked="center"
      >
        Crafting Connections Through Yoga: Our Story of Mind, Body, and
        Community
      </Typography>
      <Typography
        color="inherit"
        align="center"
        marked="center"
        variant="h5"
        sx={{
          mb: 4,
          mt: { xs: 4, sm: 10 },
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        Elevate Your Practice, Elevate Your Life"
      </Typography>
      <Box>
        <Button
          color="secondary"
          variant="outlined"
          size="large"
          component="a"
          sx={{ minWidth: 200 }}
        >
          About Us
        </Button>
      </Box>
    </ProductHeroLayout>
  );
};

const AboutUs = () => {
  return (
    <React.Fragment>
      <AppAppBar />
      <ImageComponent />
      <Box
        sx={{
          display: "flex",
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container
          sx={{
            mt: 2,
            mb: 15,
            position: "relative",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
          }}
        >
          <Box align="center" paddingTop={2}>
            <Typography
              variant="h4"
              marked="center"
              component="h2"
              sx={{ mb: 5, textTransform: "capitalize" }}
            >
              About Us
            </Typography>
          </Box>
          <Box padding={1.5}>
            <Typography
              variant={"h5"}
              sx={{ fontSize: 22, fontFamily: "oxygen" }}
            >
              {
                "The road to good health need not pass through long and winding stretches of consultations & continued medicine."
              }
            </Typography>
          </Box>
          <Box padding={1.5}>
            <Typography
              variant={"h5"}
              sx={{ fontSize: 22, fontFamily: "oxygen" }}
            >
              {
                "Prithvi Grow Yoga is a holistic & categorical approach to attaining good health & well-being. "
              }
            </Typography>
          </Box>
          <Box padding={1.5}>
            <Typography
              variant={"h5"}
              sx={{ fontSize: 22, fontFamily: "oxygen" }}
            >
              '
              <Link
                underline="hover"
                sx={{ fontFamily: "oxygen", fontSize: 27 }}
                color="secondary.dark"
              >
                Prithvi
              </Link>
              ' means earth which is the grossest of the five elements, air,
              water, fire, earth and ether. Our body is the grossest form of our
              existence which is made of the earth element. Attending to it with
              awareness, we can have good health. Therein lies the doorway to a
              calm mind.
            </Typography>
          </Box>
          <Box padding={1.5}>
            <Typography
              variant={"h5"}
              sx={{ fontSize: 22, fontFamily: "oxygen" }}
            >
              '
              <Link
                underline="hover"
                sx={{ fontFamily: "oxygen", fontSize: 27 }}
                color="secondary.dark"
              >
                Grow
              </Link>
              ' signifies growth in all the dimensions of life, in health,
              finances, and spirit. That's what we call holistic growth, inside
              and out! The best way to show this? An undying smile.
            </Typography>
          </Box>
          <Box paddingTop={2} align="center">
            <Typography
              variant="h4"
              marked="center"
              component="h2"
              sx={{ mb: 5, textTransform: "capitalize" }}
            >
              Our Vision & Mission
            </Typography>
          </Box>
          <Box padding={1.5}>
            <Typography
              variant={"h5"}
              sx={{ fontSize: 22, fontFamily: "oxygen" }}
            >
              {"Our vision is making Yoga accessible to every individual!"}
            </Typography>
          </Box>
          <Box padding={1.5}>
            <Typography
              variant={"h5"}
              sx={{ fontSize: 22, fontFamily: "oxygen" }}
            >
              {
                "Trained yoga experts conduct lessons for anyone with the slightest intention & willingness to pursue and explore yoga in their own time."
              }
            </Typography>
          </Box>
          <Box padding={1.5}>
            <Typography
              variant={"h5"}
              sx={{ fontSize: 22, fontFamily: "oxygen" }}
            >
              {
                "Healthy living & living Well Is the birthright of every individual."
              }
            </Typography>
          </Box>
        </Container>
      </Box>
      <AppFooter />
    </React.Fragment>
  );
};

export default withRoot(AboutUs);
