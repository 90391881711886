import React from "react";
import ReactDOM from "react-dom/client";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
} from "@apollo/client";
import ContactUs from "./components/ContactUs";
import TeacherHome from "./components/teacher/TeacherHome";
import Home from "./Home";
import SignIn from "./components/student/SignIn";
import SignUp from "./components/student/SignUp";
import AdminLogin from "./components/admin/AdminLogin";
import TeacherLogin from "./components/teacher/TeacherLogin";
import StudentCourses from "./components/student/StudentCourses";
import Payer from "./components/student/Payer";
import AboutFounder from "./components/AboutFounder";
import StudentRegister from "./components/student/StudentRegister";
import TeacherSignUp from "./components/teacher/TeacherSignUp";
import CreateCourse from "./components/teacher/CreateCourse";
import MyCourse from "./components/teacher/MyCourse";
import BackUpCourse from "./components/teacher/BackUpCourse";
import MyCourses from "./components/student/MyCourses";
import AdminHome from "./components/admin/AdminHome";
import Knowledge from "./components/Knowledge";
import AdminCourse from "./components/admin/AdminCourse";
import AdminTeachers from "./components/admin/AdminTeachers";
import AdminStudents from "./components/admin/AdminStudents";
import PaymentCheckout from "./components/student/PaymentCheckout";
import AboutUs from "./components/AboutUs";

const link = new HttpLink({
  uri: "https://api.prithivigrowyoga.com/graphql",
  // uri: "http://localhost:4000/graphql",
  credentials: "include",
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: link,
  credentials: "include",
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ApolloProvider client={client}>
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about-founder" element={<AboutFounder />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/knowledge" element={<Knowledge />} />
        <Route path="/student/sign-in" element={<SignIn />} />
        <Route path="/student/sign-up" element={<SignUp />} />
        <Route path="/student/register" element={<StudentRegister />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/teacher/login" element={<TeacherLogin />} />
        <Route path="/student/courses" element={<StudentCourses />} />
        <Route path="/pay-off" element={<Payer />} />
        <Route path="/checkout-success" element={<PaymentCheckout />} />
        <Route path="/teacher/sign-up" element={<TeacherSignUp />} />
        <Route path="/student/mycourses" element={<MyCourses />} />
        <Route path="/teacher/:id" element={<TeacherHome />} />
        <Route path="/teacher/:id/create-course" element={<CreateCourse />} />
        <Route path="/teacher/:id/my-courses" element={<MyCourse />} />
        <Route path="/teacher/:id/backup-courses" element={<BackUpCourse />} />
        <Route path="/admin/home" element={<AdminHome />} />
        <Route path="/admin/teacher-courses" element={<AdminCourse />} />
        <Route path="/admin/teachers" element={<AdminTeachers />} />
        <Route path="/admin/students" element={<AdminStudents />} />
      </Routes>
    </Router>
  </ApolloProvider>
  // </React.StrictMode>
);
