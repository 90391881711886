import { Box, Container, Grid, Link } from "@mui/material";
import React from "react";
import Typography from "../../custom/Typography";
import image from "../../assets/appCurvyLines.png";
import Paper from "../../custom/Paper";
import withRoot from "../../withRoot";
import sucess from "../../assets/success.png";
import Button from "../../custom/Button";
import { useNavigate } from "react-router-dom";
const PaymentCheckout = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          backgroundImage: `url(${image})`,
          backgroundRepeat: "space",
        }}
      >
        <Container maxWidth="md">
          <Box sx={{ mt: "10vh", mb: "20vh" }}>
            <Paper
              background="light"
              sx={{ py: { xs: 4, md: 7 }, px: { xs: 3, md: 6 } }}
            >
              <Box align="center">
                <Box
                  component={"img"}
                  src={sucess}
                  alt="call to action"
                  sx={{
                    maxWidth: 100,
                    maxHeight: 70,
                  }}
                ></Box>
                <Typography sx={{ fontWeight: 600, fontSize: 30 }}>
                  Payment Successful
                </Typography>
                <Box padding={2} maxWidth={700}>
                  <Typography sx={{ fontSize: 20 }}>
                    You have been registered to the class/workshop that you have
                    selected. Check your e-mail for the invoice confirmation.
                    You can see your registered courses by clicking the "Your
                    Courses" button below. If you haven't received the invoice,
                    kindly contact us to the mentioned e-mail. Thank you and
                    looking forward to see you in many more courses.
                  </Typography>
                  <Box padding={2}>
                    <Box align="center">
                      <Grid padding={2} container spacing={2}>
                        <Grid item xs={3}>
                          <Typography sx={{ fontSize: 20 }}>
                            Contact Us @
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Link
                            sx={{ color: "secondary.dark", fontSize: 20 }}
                            variant="h4"
                            underline="hover"
                            target="_top"
                            rel="noopener noreferrer"
                            href={`mailto:letsdo@prithivigrowyoga.com`}
                          >
                            {"letsdo@prithivigrowyoga.com"}
                          </Link>
                        </Grid>
                      </Grid>
                    </Box>
                    <Button
                      variant="contained"
                      onClick={() => {
                        navigate("/student/mycourses");
                      }}
                      color="secondary"
                    >
                      Your Courses
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default withRoot(PaymentCheckout);
