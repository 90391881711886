import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import AppBar from "../custom/AppBar";
import Toolbar from "../custom/ToolBar";
import { useMutation } from "@apollo/client";
import { Logout } from "../schema/student";
import {
  Avatar,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import logo from "../assets/Logo-removebg-preview.png";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Book,
  Create,
  Dashboard,
  Login,
  LogoutOutlined,
} from "@mui/icons-material";
import { Badge } from "@mui/material";
const rightLink = {
  fontSize: 16,
  color: "common.white",
  ml: 2,
};
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#008000",
    color: "#008000",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));
const LogoImg = styled("img")({
  marginRight: (theme) => theme.spacing(2),
  height: 70, // Adjust the height of the logo as needed
});
function AppAppBar() {
  const [logoutsuccess] = useMutation(Logout);
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar
          // sx={{ justifyContent: "space-between" }}
          disableGutters
        >
          <LogoImg
            src={logo}
            alt="Logo"
            sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
          />
          <LogoImg
            src={logo}
            alt="Logo"
            sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
          />
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            <Grid item>
              <Box>
                <Link
                  // variant="h6"P
                  underline="none"
                  noWrap
                  color="inherit"
                  href="/"
                  sx={{
                    fontSize: 36,
                    // fontFamily: "Cinzel Decorative",
                    fontFamily: "'DM Serif Text', serif",
                    mr: -35,
                    display: { xs: "none", md: "flex" },
                    justifyContent: "center",
                  }}
                >
                  {"Prithivi Grow Yoga"}
                </Link>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "100vh", display: { xs: "flex", md: "none" } }}
          >
            <Grid item xs={12}>
              <Box align="center">
                <Link
                  // variant="h6"P
                  underline="none"
                  color="inherit"
                  href="/"
                  sx={{
                    fontSize: 26,
                    // fontFamily: "Cinzel Decorative",
                    fontFamily: "DM Serif Text",
                    mr: 2,
                    display: { xs: "flex", md: "none" },
                    flexGrow: 1,
                  }}
                >
                  {"Prithivi Grow Yoga"}
                </Link>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            {localStorage.getItem("user") === "Student" ? (
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                onClick={handleOpenNavMenu}
                variant="dot"
              >
                <Avatar
                  alt={localStorage.getItem("name").toUpperCase()}
                  sx={{ bgcolor: "#8BC34A" }}
                  src
                />
              </StyledBadge>
            ) : (
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            )}

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuList>
                <MenuItem>
                  <Link
                    variant="h6"
                    underline="none"
                    onClick={(e) => {
                      navigate("/student/courses");
                    }}
                    // to="/student/courses"
                    sx={{ ...rightLink, color: "secondary.dark" }}
                  >
                    <Book /> {"Courses"}
                  </Link>
                </MenuItem>
                {localStorage.getItem("user") === "Student" ? (
                  <>
                    <MenuItem>
                      <Link
                        color="inherit"
                        variant="h6"
                        underline="none"
                        onClick={(e) => {
                          navigate("/student/mycourses");
                        }}
                        sx={{ ...rightLink, color: "black" }}
                      >
                        <Dashboard /> {"My Courses"}
                      </Link>
                    </MenuItem>

                    <MenuItem>
                      <Link
                        variant="h6"
                        underline="none"
                        onClick={async () => {
                          await logoutsuccess()
                            .then((data) => {
                              localStorage.removeItem("accessToken");
                              localStorage.removeItem("email");
                              localStorage.removeItem("user");
                              localStorage.removeItem("id");
                              localStorage.removeItem("name");
                              localStorage.removeItem("__paypal_storage__");
                              navigate("/");
                            })
                            .catch((error) => {
                              console.log(JSON.stringify(error, null, 2));
                            });
                        }}
                        sx={{ ...rightLink, color: "secondary.dark" }}
                      >
                        <LogoutOutlined /> {"Logout"}
                      </Link>
                    </MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem>
                      <Link
                        color="inherit"
                        variant="h6"
                        underline="none"
                        onClick={(e) => {
                          navigate("/student/sign-in");
                        }}
                        sx={{ ...rightLink, color: "black" }}
                      >
                        <Login /> {"Login"}
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link
                        variant="h6"
                        underline="none"
                        onClick={(e) => {
                          navigate("/student/sign-up");
                        }}
                        sx={{ ...rightLink, color: "secondary.dark" }}
                      >
                        <Create /> {"Sign Up"}
                      </Link>
                    </MenuItem>
                  </>
                )}
              </MenuList>
            </Menu>
          </Box>

          <Box
            sx={{
              // flex: -1,
              display: { xs: "none", md: "flex" },
              justifyContent: "end",
            }}
          >
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={1.5}
            >
              <Link
                variant="h6"
                underline="none"
                onClick={(e) => {
                  navigate("/student/courses");
                }}
                sx={{
                  ...rightLink,
                  color: "secondary.dark",
                  minWidth: 90,
                  ":hover": {
                    fontSize: 18,
                    cursor: "pointer",
                  },
                }}
              >
                {"Courses"}
              </Link>
            </Stack>

            {localStorage.getItem("user") === "Student" ? (
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1.5}
              >
                <Link
                  color="inherit"
                  variant="h6"
                  underline="none"
                  onClick={(e) => {
                    navigate("/student/mycourses");
                  }}
                  sx={{
                    ...rightLink,
                    color: "black",
                    minWidth: 115,
                    ":hover": {
                      fontSize: 18,
                      cursor: "pointer",
                    },
                  }}
                >
                  {"My Courses"}
                </Link>
                <Link
                  variant="h6"
                  underline="none"
                  onClick={async () => {
                    await logoutsuccess()
                      .then((data) => {
                        localStorage.removeItem("accessToken");
                        localStorage.removeItem("email");
                        localStorage.removeItem("user");
                        localStorage.removeItem("id");
                        localStorage.removeItem("name");
                        localStorage.removeItem("__paypal_storage__");
                        navigate("/");
                      })
                      .catch((error) => {
                        console.log(JSON.stringify(error, null, 2));
                      });
                  }}
                  sx={{
                    ...rightLink,
                    color: "secondary.dark",
                    minWidth: 78,
                    ":hover": {
                      fontSize: 18,

                      cursor: "pointer",
                    },
                  }}
                >
                  {"Logout"}
                </Link>
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                  onClick={handleClick}
                >
                  <Avatar
                    alt={localStorage.getItem("name").toUpperCase()}
                    sx={{ bgcolor: "#8BC34A" }}
                    src
                  />
                </StyledBadge>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Typography sx={{ p: 2 }} color={"secondary.dark"}>
                    Hii, {localStorage.getItem("name")}!
                  </Typography>
                </Popover>
              </Stack>
            ) : (
              <Box>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1.5}
                >
                  <Link
                    color="inherit"
                    variant="h6"
                    underline="none"
                    onClick={(e) => {
                      navigate("/student/sign-in");
                    }}
                    sx={{
                      ...rightLink,
                      color: "black",
                      minWidth: 91,
                      paddingLeft: 2,
                      ":hover": {
                        fontSize: 18,
                        cursor: "pointer",
                      },
                    }}
                  >
                    {"Login"}
                  </Link>

                  <Link
                    variant="h6"
                    underline="none"
                    onClick={(e) => {
                      navigate("/student/sign-up");
                    }}
                    sx={{
                      ...rightLink,
                      color: "secondary.dark",
                      minWidth: 82,
                      paddingRight: 1,
                      ":hover": {
                        fontSize: 18,
                        cursor: "pointer",
                      },
                    }}
                  >
                    {"Sign Up"}
                  </Link>
                </Stack>
              </Box>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default AppAppBar;
