export const currencies = [
    {
      "Entity": "AFGHANISTAN",
      "Currency": "Afghani",
      "AlphabeticCode": "AFN",
      "NumericCode": 971,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "ÅLAND ISLANDS",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "ALBANIA",
      "Currency": "Lek",
      "AlphabeticCode": "ALL",
      "NumericCode": 8,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "ALGERIA",
      "Currency": "Algerian Dinar",
      "AlphabeticCode": "DZD",
      "NumericCode": 12,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "AMERICAN SAMOA",
      "Currency": "US Dollar",
      "AlphabeticCode": "USD",
      "NumericCode": 840,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "ANDORRA",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "ANGOLA",
      "Currency": "Kwanza",
      "AlphabeticCode": "AOA",
      "NumericCode": 973,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "ANGUILLA",
      "Currency": "East Caribbean Dollar",
      "AlphabeticCode": "XCD",
      "NumericCode": 951,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "ANTARCTICA",
      "Currency": "No universal currency",
      "AlphabeticCode": "",
      "NumericCode": "",
      "MinorUnit": "",
      "WithdrawalDate": ""
    },
    {
      "Entity": "ANTIGUA AND BARBUDA",
      "Currency": "East Caribbean Dollar",
      "AlphabeticCode": "XCD",
      "NumericCode": 951,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "ARGENTINA",
      "Currency": "Argentine Peso",
      "AlphabeticCode": "ARS",
      "NumericCode": 32,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "ARMENIA",
      "Currency": "Armenian Dram",
      "AlphabeticCode": "AMD",
      "NumericCode": 51,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "ARUBA",
      "Currency": "Aruban Florin",
      "AlphabeticCode": "AWG",
      "NumericCode": 533,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "AUSTRALIA",
      "Currency": "Australian Dollar",
      "AlphabeticCode": "AUD",
      "NumericCode": 36,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "AUSTRIA",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "AZERBAIJAN",
      "Currency": "Azerbaijan Manat",
      "AlphabeticCode": "AZN",
      "NumericCode": 944,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "BAHAMAS (THE)",
      "Currency": "Bahamian Dollar",
      "AlphabeticCode": "BSD",
      "NumericCode": 44,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "BAHRAIN",
      "Currency": "Bahraini Dinar",
      "AlphabeticCode": "BHD",
      "NumericCode": 48,
      "MinorUnit": 3,
      "WithdrawalDate": ""
    },
    {
      "Entity": "BANGLADESH",
      "Currency": "Taka",
      "AlphabeticCode": "BDT",
      "NumericCode": 50,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "BARBADOS",
      "Currency": "Barbados Dollar",
      "AlphabeticCode": "BBD",
      "NumericCode": 52,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "BELARUS",
      "Currency": "Belarusian Ruble",
      "AlphabeticCode": "BYN",
      "NumericCode": 933,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "BELGIUM",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "BELIZE",
      "Currency": "Belize Dollar",
      "AlphabeticCode": "BZD",
      "NumericCode": 84,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "BENIN",
      "Currency": "CFA Franc BCEAO",
      "AlphabeticCode": "XOF",
      "NumericCode": 952,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "BERMUDA",
      "Currency": "Bermudian Dollar",
      "AlphabeticCode": "BMD",
      "NumericCode": 60,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "BHUTAN",
      "Currency": "Indian Rupee",
      "AlphabeticCode": "INR",
      "NumericCode": 356,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "BHUTAN",
      "Currency": "Ngultrum",
      "AlphabeticCode": "BTN",
      "NumericCode": 64,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "BOLIVIA (PLURINATIONAL STATE OF)",
      "Currency": "Boliviano",
      "AlphabeticCode": "BOB",
      "NumericCode": 68,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "BOLIVIA (PLURINATIONAL STATE OF)",
      "Currency": "Mvdol",
      "AlphabeticCode": "BOV",
      "NumericCode": 984,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "BONAIRE, SINT EUSTATIUS AND SABA",
      "Currency": "US Dollar",
      "AlphabeticCode": "USD",
      "NumericCode": 840,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "BOSNIA AND HERZEGOVINA",
      "Currency": "Convertible Mark",
      "AlphabeticCode": "BAM",
      "NumericCode": 977,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "BOTSWANA",
      "Currency": "Pula",
      "AlphabeticCode": "BWP",
      "NumericCode": 72,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "BOUVET ISLAND",
      "Currency": "Norwegian Krone",
      "AlphabeticCode": "NOK",
      "NumericCode": 578,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "BRAZIL",
      "Currency": "Brazilian Real",
      "AlphabeticCode": "BRL",
      "NumericCode": 986,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "BRITISH INDIAN OCEAN TERRITORY (THE)",
      "Currency": "US Dollar",
      "AlphabeticCode": "USD",
      "NumericCode": 840,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "BRUNEI DARUSSALAM",
      "Currency": "Brunei Dollar",
      "AlphabeticCode": "BND",
      "NumericCode": 96,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "BULGARIA",
      "Currency": "Bulgarian Lev",
      "AlphabeticCode": "BGN",
      "NumericCode": 975,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "BURKINA FASO",
      "Currency": "CFA Franc BCEAO",
      "AlphabeticCode": "XOF",
      "NumericCode": 952,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "BURUNDI",
      "Currency": "Burundi Franc",
      "AlphabeticCode": "BIF",
      "NumericCode": 108,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "CABO VERDE",
      "Currency": "Cabo Verde Escudo",
      "AlphabeticCode": "CVE",
      "NumericCode": 132,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "CAMBODIA",
      "Currency": "Riel",
      "AlphabeticCode": "KHR",
      "NumericCode": 116,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "CAMEROON",
      "Currency": "CFA Franc BEAC",
      "AlphabeticCode": "XAF",
      "NumericCode": 950,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "CANADA",
      "Currency": "Canadian Dollar",
      "AlphabeticCode": "CAD",
      "NumericCode": 124,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "CAYMAN ISLANDS (THE)",
      "Currency": "Cayman Islands Dollar",
      "AlphabeticCode": "KYD",
      "NumericCode": 136,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "CENTRAL AFRICAN REPUBLIC (THE)",
      "Currency": "CFA Franc BEAC",
      "AlphabeticCode": "XAF",
      "NumericCode": 950,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "CHAD",
      "Currency": "CFA Franc BEAC",
      "AlphabeticCode": "XAF",
      "NumericCode": 950,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "CHILE",
      "Currency": "Chilean Peso",
      "AlphabeticCode": "CLP",
      "NumericCode": 152,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "CHILE",
      "Currency": "Unidad de Fomento",
      "AlphabeticCode": "CLF",
      "NumericCode": 990,
      "MinorUnit": 4,
      "WithdrawalDate": ""
    },
    {
      "Entity": "CHINA",
      "Currency": "Yuan Renminbi",
      "AlphabeticCode": "CNY",
      "NumericCode": 156,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "CHRISTMAS ISLAND",
      "Currency": "Australian Dollar",
      "AlphabeticCode": "AUD",
      "NumericCode": 36,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "COCOS (KEELING) ISLANDS (THE)",
      "Currency": "Australian Dollar",
      "AlphabeticCode": "AUD",
      "NumericCode": 36,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "COLOMBIA",
      "Currency": "Colombian Peso",
      "AlphabeticCode": "COP",
      "NumericCode": 170,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "COLOMBIA",
      "Currency": "Unidad de Valor Real",
      "AlphabeticCode": "COU",
      "NumericCode": 970,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "COMOROS (THE)",
      "Currency": "Comorian Franc",
      "AlphabeticCode": "KMF",
      "NumericCode": 174,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "CONGO (THE DEMOCRATIC REPUBLIC OF THE)",
      "Currency": "Congolese Franc",
      "AlphabeticCode": "CDF",
      "NumericCode": 976,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "CONGO (THE)",
      "Currency": "CFA Franc BEAC",
      "AlphabeticCode": "XAF",
      "NumericCode": 950,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "COOK ISLANDS (THE)",
      "Currency": "New Zealand Dollar",
      "AlphabeticCode": "NZD",
      "NumericCode": 554,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "COSTA RICA",
      "Currency": "Costa Rican Colon",
      "AlphabeticCode": "CRC",
      "NumericCode": 188,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "CÔTE D'IVOIRE",
      "Currency": "CFA Franc BCEAO",
      "AlphabeticCode": "XOF",
      "NumericCode": 952,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "CROATIA",
      "Currency": "Kuna",
      "AlphabeticCode": "HRK",
      "NumericCode": 191,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "CUBA",
      "Currency": "Cuban Peso",
      "AlphabeticCode": "CUP",
      "NumericCode": 192,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "CUBA",
      "Currency": "Peso Convertible",
      "AlphabeticCode": "CUC",
      "NumericCode": 931,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "CURAÇAO",
      "Currency": "Netherlands Antillean Guilder",
      "AlphabeticCode": "ANG",
      "NumericCode": 532,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "CYPRUS",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "CZECHIA",
      "Currency": "Czech Koruna",
      "AlphabeticCode": "CZK",
      "NumericCode": 203,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "DENMARK",
      "Currency": "Danish Krone",
      "AlphabeticCode": "DKK",
      "NumericCode": 208,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "DJIBOUTI",
      "Currency": "Djibouti Franc",
      "AlphabeticCode": "DJF",
      "NumericCode": 262,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "DOMINICA",
      "Currency": "East Caribbean Dollar",
      "AlphabeticCode": "XCD",
      "NumericCode": 951,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "DOMINICAN REPUBLIC (THE)",
      "Currency": "Dominican Peso",
      "AlphabeticCode": "DOP",
      "NumericCode": 214,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "ECUADOR",
      "Currency": "US Dollar",
      "AlphabeticCode": "USD",
      "NumericCode": 840,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "EGYPT",
      "Currency": "Egyptian Pound",
      "AlphabeticCode": "EGP",
      "NumericCode": 818,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "EL SALVADOR",
      "Currency": "El Salvador Colon",
      "AlphabeticCode": "SVC",
      "NumericCode": 222,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "EL SALVADOR",
      "Currency": "US Dollar",
      "AlphabeticCode": "USD",
      "NumericCode": 840,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "EQUATORIAL GUINEA",
      "Currency": "CFA Franc BEAC",
      "AlphabeticCode": "XAF",
      "NumericCode": 950,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "ERITREA",
      "Currency": "Nakfa",
      "AlphabeticCode": "ERN",
      "NumericCode": 232,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "ESTONIA",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "ESWATINI",
      "Currency": "Lilangeni",
      "AlphabeticCode": "SZL",
      "NumericCode": 748,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "ETHIOPIA",
      "Currency": "Ethiopian Birr",
      "AlphabeticCode": "ETB",
      "NumericCode": 230,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "EUROPEAN UNION",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "FALKLAND ISLANDS (THE) [MALVINAS]",
      "Currency": "Falkland Islands Pound",
      "AlphabeticCode": "FKP",
      "NumericCode": 238,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "FAROE ISLANDS (THE)",
      "Currency": "Danish Krone",
      "AlphabeticCode": "DKK",
      "NumericCode": 208,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "FIJI",
      "Currency": "Fiji Dollar",
      "AlphabeticCode": "FJD",
      "NumericCode": 242,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "FINLAND",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "FRANCE",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "FRENCH GUIANA",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "FRENCH POLYNESIA",
      "Currency": "CFP Franc",
      "AlphabeticCode": "XPF",
      "NumericCode": 953,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "FRENCH SOUTHERN TERRITORIES (THE)",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "GABON",
      "Currency": "CFA Franc BEAC",
      "AlphabeticCode": "XAF",
      "NumericCode": 950,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "GAMBIA (THE)",
      "Currency": "Dalasi",
      "AlphabeticCode": "GMD",
      "NumericCode": 270,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "GEORGIA",
      "Currency": "Lari",
      "AlphabeticCode": "GEL",
      "NumericCode": 981,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "GERMANY",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "GHANA",
      "Currency": "Ghana Cedi",
      "AlphabeticCode": "GHS",
      "NumericCode": 936,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "GIBRALTAR",
      "Currency": "Gibraltar Pound",
      "AlphabeticCode": "GIP",
      "NumericCode": 292,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "GREECE",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "GREENLAND",
      "Currency": "Danish Krone",
      "AlphabeticCode": "DKK",
      "NumericCode": 208,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "GRENADA",
      "Currency": "East Caribbean Dollar",
      "AlphabeticCode": "XCD",
      "NumericCode": 951,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "GUADELOUPE",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "GUAM",
      "Currency": "US Dollar",
      "AlphabeticCode": "USD",
      "NumericCode": 840,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "GUATEMALA",
      "Currency": "Quetzal",
      "AlphabeticCode": "GTQ",
      "NumericCode": 320,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "GUERNSEY",
      "Currency": "Pound Sterling",
      "AlphabeticCode": "GBP",
      "NumericCode": 826,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "GUINEA",
      "Currency": "Guinean Franc",
      "AlphabeticCode": "GNF",
      "NumericCode": 324,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "GUINEA-BISSAU",
      "Currency": "CFA Franc BCEAO",
      "AlphabeticCode": "XOF",
      "NumericCode": 952,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "GUYANA",
      "Currency": "Guyana Dollar",
      "AlphabeticCode": "GYD",
      "NumericCode": 328,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "HAITI",
      "Currency": "Gourde",
      "AlphabeticCode": "HTG",
      "NumericCode": 332,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "HAITI",
      "Currency": "US Dollar",
      "AlphabeticCode": "USD",
      "NumericCode": 840,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "HEARD ISLAND AND McDONALD ISLANDS",
      "Currency": "Australian Dollar",
      "AlphabeticCode": "AUD",
      "NumericCode": 36,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "HOLY SEE (THE)",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "HONDURAS",
      "Currency": "Lempira",
      "AlphabeticCode": "HNL",
      "NumericCode": 340,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "HONG KONG",
      "Currency": "Hong Kong Dollar",
      "AlphabeticCode": "HKD",
      "NumericCode": 344,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "HUNGARY",
      "Currency": "Forint",
      "AlphabeticCode": "HUF",
      "NumericCode": 348,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "ICELAND",
      "Currency": "Iceland Krona",
      "AlphabeticCode": "ISK",
      "NumericCode": 352,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "INDIA",
      "Currency": "Indian Rupee",
      "AlphabeticCode": "INR",
      "NumericCode": 356,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "INDONESIA",
      "Currency": "Rupiah",
      "AlphabeticCode": "IDR",
      "NumericCode": 360,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "INTERNATIONAL MONETARY FUND (IMF)",
      "Currency": "SDR (Special Drawing Right)",
      "AlphabeticCode": "XDR",
      "NumericCode": 960,
      "MinorUnit": "-",
      "WithdrawalDate": ""
    },
    {
      "Entity": "IRAN (ISLAMIC REPUBLIC OF)",
      "Currency": "Iranian Rial",
      "AlphabeticCode": "IRR",
      "NumericCode": 364,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "IRAQ",
      "Currency": "Iraqi Dinar",
      "AlphabeticCode": "IQD",
      "NumericCode": 368,
      "MinorUnit": 3,
      "WithdrawalDate": ""
    },
    {
      "Entity": "IRELAND",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "ISLE OF MAN",
      "Currency": "Pound Sterling",
      "AlphabeticCode": "GBP",
      "NumericCode": 826,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "ISRAEL",
      "Currency": "New Israeli Sheqel",
      "AlphabeticCode": "ILS",
      "NumericCode": 376,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "ITALY",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "JAMAICA",
      "Currency": "Jamaican Dollar",
      "AlphabeticCode": "JMD",
      "NumericCode": 388,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "JAPAN",
      "Currency": "Yen",
      "AlphabeticCode": "JPY",
      "NumericCode": 392,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "JERSEY",
      "Currency": "Pound Sterling",
      "AlphabeticCode": "GBP",
      "NumericCode": 826,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "JORDAN",
      "Currency": "Jordanian Dinar",
      "AlphabeticCode": "JOD",
      "NumericCode": 400,
      "MinorUnit": 3,
      "WithdrawalDate": ""
    },
    {
      "Entity": "KAZAKHSTAN",
      "Currency": "Tenge",
      "AlphabeticCode": "KZT",
      "NumericCode": 398,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "KENYA",
      "Currency": "Kenyan Shilling",
      "AlphabeticCode": "KES",
      "NumericCode": 404,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "KIRIBATI",
      "Currency": "Australian Dollar",
      "AlphabeticCode": "AUD",
      "NumericCode": 36,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "KOREA (THE DEMOCRATIC PEOPLE'S REPUBLIC OF)",
      "Currency": "North Korean Won",
      "AlphabeticCode": "KPW",
      "NumericCode": 408,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "KOREA (THE REPUBLIC OF)",
      "Currency": "Won",
      "AlphabeticCode": "KRW",
      "NumericCode": 410,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "KUWAIT",
      "Currency": "Kuwaiti Dinar",
      "AlphabeticCode": "KWD",
      "NumericCode": 414,
      "MinorUnit": 3,
      "WithdrawalDate": ""
    },
    {
      "Entity": "KYRGYZSTAN",
      "Currency": "Som",
      "AlphabeticCode": "KGS",
      "NumericCode": 417,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "LAO PEOPLE'S DEMOCRATIC REPUBLIC (THE)",
      "Currency": "Lao Kip",
      "AlphabeticCode": "LAK",
      "NumericCode": 418,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "LATVIA",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "LEBANON",
      "Currency": "Lebanese Pound",
      "AlphabeticCode": "LBP",
      "NumericCode": 422,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "LESOTHO",
      "Currency": "Loti",
      "AlphabeticCode": "LSL",
      "NumericCode": 426,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "LESOTHO",
      "Currency": "Rand",
      "AlphabeticCode": "ZAR",
      "NumericCode": 710,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "LIBERIA",
      "Currency": "Liberian Dollar",
      "AlphabeticCode": "LRD",
      "NumericCode": 430,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "LIBYA",
      "Currency": "Libyan Dinar",
      "AlphabeticCode": "LYD",
      "NumericCode": 434,
      "MinorUnit": 3,
      "WithdrawalDate": ""
    },
    {
      "Entity": "LIECHTENSTEIN",
      "Currency": "Swiss Franc",
      "AlphabeticCode": "CHF",
      "NumericCode": 756,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "LITHUANIA",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "LUXEMBOURG",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "MACAO",
      "Currency": "Pataca",
      "AlphabeticCode": "MOP",
      "NumericCode": 446,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "NORTH MACEDONIA",
      "Currency": "Denar",
      "AlphabeticCode": "MKD",
      "NumericCode": 807,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "MADAGASCAR",
      "Currency": "Malagasy Ariary",
      "AlphabeticCode": "MGA",
      "NumericCode": 969,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "MALAWI",
      "Currency": "Malawi Kwacha",
      "AlphabeticCode": "MWK",
      "NumericCode": 454,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "MALAYSIA",
      "Currency": "Malaysian Ringgit",
      "AlphabeticCode": "MYR",
      "NumericCode": 458,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "MALDIVES",
      "Currency": "Rufiyaa",
      "AlphabeticCode": "MVR",
      "NumericCode": 462,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "MALI",
      "Currency": "CFA Franc BCEAO",
      "AlphabeticCode": "XOF",
      "NumericCode": 952,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "MALTA",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "MARSHALL ISLANDS (THE)",
      "Currency": "US Dollar",
      "AlphabeticCode": "USD",
      "NumericCode": 840,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "MARTINIQUE",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "MAURITANIA",
      "Currency": "Ouguiya",
      "AlphabeticCode": "MRU",
      "NumericCode": 929,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "MAURITIUS",
      "Currency": "Mauritius Rupee",
      "AlphabeticCode": "MUR",
      "NumericCode": 480,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "MAYOTTE",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "MEMBER COUNTRIES OF THE AFRICAN DEVELOPMENT BANK GROUP",
      "Currency": "ADB Unit of Account",
      "AlphabeticCode": "XUA",
      "NumericCode": 965,
      "MinorUnit": "-",
      "WithdrawalDate": ""
    },
    {
      "Entity": "MEXICO",
      "Currency": "Mexican Peso",
      "AlphabeticCode": "MXN",
      "NumericCode": 484,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "MEXICO",
      "Currency": "Mexican Unidad de Inversion (UDI)",
      "AlphabeticCode": "MXV",
      "NumericCode": 979,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "MICRONESIA (FEDERATED STATES OF)",
      "Currency": "US Dollar",
      "AlphabeticCode": "USD",
      "NumericCode": 840,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "MOLDOVA (THE REPUBLIC OF)",
      "Currency": "Moldovan Leu",
      "AlphabeticCode": "MDL",
      "NumericCode": 498,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "MONACO",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "MONGOLIA",
      "Currency": "Tugrik",
      "AlphabeticCode": "MNT",
      "NumericCode": 496,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "MONTENEGRO",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "MONTSERRAT",
      "Currency": "East Caribbean Dollar",
      "AlphabeticCode": "XCD",
      "NumericCode": 951,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "MOROCCO",
      "Currency": "Moroccan Dirham",
      "AlphabeticCode": "MAD",
      "NumericCode": 504,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "MOZAMBIQUE",
      "Currency": "Mozambique Metical",
      "AlphabeticCode": "MZN",
      "NumericCode": 943,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "MYANMAR",
      "Currency": "Kyat",
      "AlphabeticCode": "MMK",
      "NumericCode": 104,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "NAMIBIA",
      "Currency": "Namibia Dollar",
      "AlphabeticCode": "NAD",
      "NumericCode": 516,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "NAMIBIA",
      "Currency": "Rand",
      "AlphabeticCode": "ZAR",
      "NumericCode": 710,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "NAURU",
      "Currency": "Australian Dollar",
      "AlphabeticCode": "AUD",
      "NumericCode": 36,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "NEPAL",
      "Currency": "Nepalese Rupee",
      "AlphabeticCode": "NPR",
      "NumericCode": 524,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "NETHERLANDS (THE)",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "NEW CALEDONIA",
      "Currency": "CFP Franc",
      "AlphabeticCode": "XPF",
      "NumericCode": 953,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "NEW ZEALAND",
      "Currency": "New Zealand Dollar",
      "AlphabeticCode": "NZD",
      "NumericCode": 554,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "NICARAGUA",
      "Currency": "Cordoba Oro",
      "AlphabeticCode": "NIO",
      "NumericCode": 558,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "NIGER (THE)",
      "Currency": "CFA Franc BCEAO",
      "AlphabeticCode": "XOF",
      "NumericCode": 952,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "NIGERIA",
      "Currency": "Naira",
      "AlphabeticCode": "NGN",
      "NumericCode": 566,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "NIUE",
      "Currency": "New Zealand Dollar",
      "AlphabeticCode": "NZD",
      "NumericCode": 554,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "NORFOLK ISLAND",
      "Currency": "Australian Dollar",
      "AlphabeticCode": "AUD",
      "NumericCode": 36,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "NORTHERN MARIANA ISLANDS (THE)",
      "Currency": "US Dollar",
      "AlphabeticCode": "USD",
      "NumericCode": 840,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "NORWAY",
      "Currency": "Norwegian Krone",
      "AlphabeticCode": "NOK",
      "NumericCode": 578,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "OMAN",
      "Currency": "Rial Omani",
      "AlphabeticCode": "OMR",
      "NumericCode": 512,
      "MinorUnit": 3,
      "WithdrawalDate": ""
    },
    {
      "Entity": "PAKISTAN",
      "Currency": "Pakistan Rupee",
      "AlphabeticCode": "PKR",
      "NumericCode": 586,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "PALAU",
      "Currency": "US Dollar",
      "AlphabeticCode": "USD",
      "NumericCode": 840,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "PALESTINE, STATE OF",
      "Currency": "No universal currency",
      "AlphabeticCode": "",
      "NumericCode": "",
      "MinorUnit": "",
      "WithdrawalDate": ""
    },
    {
      "Entity": "PANAMA",
      "Currency": "Balboa",
      "AlphabeticCode": "PAB",
      "NumericCode": 590,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "PANAMA",
      "Currency": "US Dollar",
      "AlphabeticCode": "USD",
      "NumericCode": 840,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "PAPUA NEW GUINEA",
      "Currency": "Kina",
      "AlphabeticCode": "PGK",
      "NumericCode": 598,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "PARAGUAY",
      "Currency": "Guarani",
      "AlphabeticCode": "PYG",
      "NumericCode": 600,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "PERU",
      "Currency": "Sol",
      "AlphabeticCode": "PEN",
      "NumericCode": 604,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "PHILIPPINES (THE)",
      "Currency": "Philippine Peso",
      "AlphabeticCode": "PHP",
      "NumericCode": 608,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "PITCAIRN",
      "Currency": "New Zealand Dollar",
      "AlphabeticCode": "NZD",
      "NumericCode": 554,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "POLAND",
      "Currency": "Zloty",
      "AlphabeticCode": "PLN",
      "NumericCode": 985,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "PORTUGAL",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "PUERTO RICO",
      "Currency": "US Dollar",
      "AlphabeticCode": "USD",
      "NumericCode": 840,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "QATAR",
      "Currency": "Qatari Rial",
      "AlphabeticCode": "QAR",
      "NumericCode": 634,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "RÉUNION",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "ROMANIA",
      "Currency": "Romanian Leu",
      "AlphabeticCode": "RON",
      "NumericCode": 946,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "RUSSIAN FEDERATION (THE)",
      "Currency": "Russian Ruble",
      "AlphabeticCode": "RUB",
      "NumericCode": 643,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "RWANDA",
      "Currency": "Rwanda Franc",
      "AlphabeticCode": "RWF",
      "NumericCode": 646,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SAINT BARTHÉLEMY",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA",
      "Currency": "Saint Helena Pound",
      "AlphabeticCode": "SHP",
      "NumericCode": 654,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SAINT KITTS AND NEVIS",
      "Currency": "East Caribbean Dollar",
      "AlphabeticCode": "XCD",
      "NumericCode": 951,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SAINT LUCIA",
      "Currency": "East Caribbean Dollar",
      "AlphabeticCode": "XCD",
      "NumericCode": 951,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SAINT MARTIN (FRENCH PART)",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SAINT PIERRE AND MIQUELON",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SAINT VINCENT AND THE GRENADINES",
      "Currency": "East Caribbean Dollar",
      "AlphabeticCode": "XCD",
      "NumericCode": 951,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SAMOA",
      "Currency": "Tala",
      "AlphabeticCode": "WST",
      "NumericCode": 882,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SAN MARINO",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SAO TOME AND PRINCIPE",
      "Currency": "Dobra",
      "AlphabeticCode": "STN",
      "NumericCode": 930,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SAUDI ARABIA",
      "Currency": "Saudi Riyal",
      "AlphabeticCode": "SAR",
      "NumericCode": 682,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SENEGAL",
      "Currency": "CFA Franc BCEAO",
      "AlphabeticCode": "XOF",
      "NumericCode": 952,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SERBIA",
      "Currency": "Serbian Dinar",
      "AlphabeticCode": "RSD",
      "NumericCode": 941,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SEYCHELLES",
      "Currency": "Seychelles Rupee",
      "AlphabeticCode": "SCR",
      "NumericCode": 690,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SIERRA LEONE",
      "Currency": "Leone",
      "AlphabeticCode": "SLL",
      "NumericCode": 694,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SINGAPORE",
      "Currency": "Singapore Dollar",
      "AlphabeticCode": "SGD",
      "NumericCode": 702,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SINT MAARTEN (DUTCH PART)",
      "Currency": "Netherlands Antillean Guilder",
      "AlphabeticCode": "ANG",
      "NumericCode": 532,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SISTEMA UNITARIO DE COMPENSACION REGIONAL DE PAGOS \"SUCRE",
      "Currency": "Sucre",
      "AlphabeticCode": "XSU",
      "NumericCode": 994,
      "MinorUnit": "-",
      "WithdrawalDate": ""
    },
    {
      "Entity": "SLOVAKIA",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SLOVENIA",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SOLOMON ISLANDS",
      "Currency": "Solomon Islands Dollar",
      "AlphabeticCode": "SBD",
      "NumericCode": 90,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SOMALIA",
      "Currency": "Somali Shilling",
      "AlphabeticCode": "SOS",
      "NumericCode": 706,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SOUTH AFRICA",
      "Currency": "Rand",
      "AlphabeticCode": "ZAR",
      "NumericCode": 710,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
      "Currency": "No universal currency",
      "AlphabeticCode": "",
      "NumericCode": "",
      "MinorUnit": "",
      "WithdrawalDate": ""
    },
    {
      "Entity": "SOUTH SUDAN",
      "Currency": "South Sudanese Pound",
      "AlphabeticCode": "SSP",
      "NumericCode": 728,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SPAIN",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SRI LANKA",
      "Currency": "Sri Lanka Rupee",
      "AlphabeticCode": "LKR",
      "NumericCode": 144,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SUDAN (THE)",
      "Currency": "Sudanese Pound",
      "AlphabeticCode": "SDG",
      "NumericCode": 938,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SURINAME",
      "Currency": "Surinam Dollar",
      "AlphabeticCode": "SRD",
      "NumericCode": 968,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SVALBARD AND JAN MAYEN",
      "Currency": "Norwegian Krone",
      "AlphabeticCode": "NOK",
      "NumericCode": 578,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SWEDEN",
      "Currency": "Swedish Krona",
      "AlphabeticCode": "SEK",
      "NumericCode": 752,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SWITZERLAND",
      "Currency": "Swiss Franc",
      "AlphabeticCode": "CHF",
      "NumericCode": 756,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SWITZERLAND",
      "Currency": "WIR Euro",
      "AlphabeticCode": "CHE",
      "NumericCode": 947,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SWITZERLAND",
      "Currency": "WIR Franc",
      "AlphabeticCode": "CHW",
      "NumericCode": 948,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "SYRIAN ARAB REPUBLIC",
      "Currency": "Syrian Pound",
      "AlphabeticCode": "SYP",
      "NumericCode": 760,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "TAIWAN (PROVINCE OF CHINA)",
      "Currency": "New Taiwan Dollar",
      "AlphabeticCode": "TWD",
      "NumericCode": 901,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "TAJIKISTAN",
      "Currency": "Somoni",
      "AlphabeticCode": "TJS",
      "NumericCode": 972,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "TANZANIA, UNITED REPUBLIC OF",
      "Currency": "Tanzanian Shilling",
      "AlphabeticCode": "TZS",
      "NumericCode": 834,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "THAILAND",
      "Currency": "Baht",
      "AlphabeticCode": "THB",
      "NumericCode": 764,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "TIMOR-LESTE",
      "Currency": "US Dollar",
      "AlphabeticCode": "USD",
      "NumericCode": 840,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "TOGO",
      "Currency": "CFA Franc BCEAO",
      "AlphabeticCode": "XOF",
      "NumericCode": 952,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "TOKELAU",
      "Currency": "New Zealand Dollar",
      "AlphabeticCode": "NZD",
      "NumericCode": 554,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "TONGA",
      "Currency": "Pa'anga",
      "AlphabeticCode": "TOP",
      "NumericCode": 776,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "TRINIDAD AND TOBAGO",
      "Currency": "Trinidad and Tobago Dollar",
      "AlphabeticCode": "TTD",
      "NumericCode": 780,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "TUNISIA",
      "Currency": "Tunisian Dinar",
      "AlphabeticCode": "TND",
      "NumericCode": 788,
      "MinorUnit": 3,
      "WithdrawalDate": ""
    },
    {
      "Entity": "TURKEY",
      "Currency": "Turkish Lira",
      "AlphabeticCode": "TRY",
      "NumericCode": 949,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "TURKMENISTAN",
      "Currency": "Turkmenistan New Manat",
      "AlphabeticCode": "TMT",
      "NumericCode": 934,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "TURKS AND CAICOS ISLANDS (THE)",
      "Currency": "US Dollar",
      "AlphabeticCode": "USD",
      "NumericCode": 840,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "TUVALU",
      "Currency": "Australian Dollar",
      "AlphabeticCode": "AUD",
      "NumericCode": 36,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "UGANDA",
      "Currency": "Uganda Shilling",
      "AlphabeticCode": "UGX",
      "NumericCode": 800,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "UKRAINE",
      "Currency": "Hryvnia",
      "AlphabeticCode": "UAH",
      "NumericCode": 980,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "UNITED ARAB EMIRATES (THE)",
      "Currency": "UAE Dirham",
      "AlphabeticCode": "AED",
      "NumericCode": 784,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND (THE)",
      "Currency": "Pound Sterling",
      "AlphabeticCode": "GBP",
      "NumericCode": 826,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "UNITED STATES MINOR OUTLYING ISLANDS (THE)",
      "Currency": "US Dollar",
      "AlphabeticCode": "USD",
      "NumericCode": 840,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "UNITED STATES OF AMERICA (THE)",
      "Currency": "US Dollar",
      "AlphabeticCode": "USD",
      "NumericCode": 840,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "UNITED STATES OF AMERICA (THE)",
      "Currency": "US Dollar (Next day)",
      "AlphabeticCode": "USN",
      "NumericCode": 997,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "URUGUAY",
      "Currency": "Peso Uruguayo",
      "AlphabeticCode": "UYU",
      "NumericCode": 858,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "URUGUAY",
      "Currency": "Uruguay Peso en Unidades Indexadas (UI)",
      "AlphabeticCode": "UYI",
      "NumericCode": 940,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "URUGUAY",
      "Currency": "Unidad Previsional",
      "AlphabeticCode": "UYW",
      "NumericCode": 927,
      "MinorUnit": 4,
      "WithdrawalDate": ""
    },
    {
      "Entity": "UZBEKISTAN",
      "Currency": "Uzbekistan Sum",
      "AlphabeticCode": "UZS",
      "NumericCode": 860,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "VANUATU",
      "Currency": "Vatu",
      "AlphabeticCode": "VUV",
      "NumericCode": 548,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "VENEZUELA (BOLIVARIAN REPUBLIC OF)",
      "Currency": "Bolívar Soberano",
      "AlphabeticCode": "VES",
      "NumericCode": 928,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "VIET NAM",
      "Currency": "Dong",
      "AlphabeticCode": "VND",
      "NumericCode": 704,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "VIRGIN ISLANDS (BRITISH)",
      "Currency": "US Dollar",
      "AlphabeticCode": "USD",
      "NumericCode": 840,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "VIRGIN ISLANDS (U.S.)",
      "Currency": "US Dollar",
      "AlphabeticCode": "USD",
      "NumericCode": 840,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "WALLIS AND FUTUNA",
      "Currency": "CFP Franc",
      "AlphabeticCode": "XPF",
      "NumericCode": 953,
      "MinorUnit": 0,
      "WithdrawalDate": ""
    },
    {
      "Entity": "WESTERN SAHARA",
      "Currency": "Moroccan Dirham",
      "AlphabeticCode": "MAD",
      "NumericCode": 504,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "YEMEN",
      "Currency": "Yemeni Rial",
      "AlphabeticCode": "YER",
      "NumericCode": 886,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "ZAMBIA",
      "Currency": "Zambian Kwacha",
      "AlphabeticCode": "ZMW",
      "NumericCode": 967,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "ZIMBABWE",
      "Currency": "Zimbabwe Dollar",
      "AlphabeticCode": "ZWL",
      "NumericCode": 932,
      "MinorUnit": 2,
      "WithdrawalDate": ""
    },
    {
      "Entity": "ZZ01_Bond Markets Unit European_EURCO",
      "Currency": "Bond Markets Unit European Composite Unit (EURCO)",
      "AlphabeticCode": "XBA",
      "NumericCode": 955,
      "MinorUnit": "-",
      "WithdrawalDate": ""
    },
    {
      "Entity": "ZZ02_Bond Markets Unit European_EMU-6",
      "Currency": "Bond Markets Unit European Monetary Unit (E.M.U.-6)",
      "AlphabeticCode": "XBB",
      "NumericCode": 956,
      "MinorUnit": "-",
      "WithdrawalDate": ""
    },
    {
      "Entity": "ZZ03_Bond Markets Unit European_EUA-9",
      "Currency": "Bond Markets Unit European Unit of Account 9 (E.U.A.-9)",
      "AlphabeticCode": "XBC",
      "NumericCode": 957,
      "MinorUnit": "-",
      "WithdrawalDate": ""
    },
    {
      "Entity": "ZZ04_Bond Markets Unit European_EUA-17",
      "Currency": "Bond Markets Unit European Unit of Account 17 (E.U.A.-17)",
      "AlphabeticCode": "XBD",
      "NumericCode": 958,
      "MinorUnit": "-",
      "WithdrawalDate": ""
    },
    {
      "Entity": "ZZ06_Testing_Code",
      "Currency": "Codes specifically reserved for testing purposes",
      "AlphabeticCode": "XTS",
      "NumericCode": 963,
      "MinorUnit": "-",
      "WithdrawalDate": ""
    },
    {
      "Entity": "ZZ07_No_Currency",
      "Currency": "The codes assigned for transactions where no currency is involved",
      "AlphabeticCode": "XXX",
      "NumericCode": 999,
      "MinorUnit": "-",
      "WithdrawalDate": ""
    },
    {
      "Entity": "ZZ08_Gold",
      "Currency": "Gold",
      "AlphabeticCode": "XAU",
      "NumericCode": 959,
      "MinorUnit": "-",
      "WithdrawalDate": ""
    },
    {
      "Entity": "ZZ09_Palladium",
      "Currency": "Palladium",
      "AlphabeticCode": "XPD",
      "NumericCode": 964,
      "MinorUnit": "-",
      "WithdrawalDate": ""
    },
    {
      "Entity": "ZZ10_Platinum",
      "Currency": "Platinum",
      "AlphabeticCode": "XPT",
      "NumericCode": 962,
      "MinorUnit": "-",
      "WithdrawalDate": ""
    },
    {
      "Entity": "ZZ11_Silver",
      "Currency": "Silver",
      "AlphabeticCode": "XAG",
      "NumericCode": 961,
      "MinorUnit": "-",
      "WithdrawalDate": ""
    },
    {
      "Entity": "AFGHANISTAN",
      "Currency": "Afghani",
      "AlphabeticCode": "AFA",
      "NumericCode": 4,
      "MinorUnit": "",
      "WithdrawalDate": "2003-01"
    },
    {
      "Entity": "ÅLAND ISLANDS",
      "Currency": "Markka",
      "AlphabeticCode": "FIM",
      "NumericCode": 246,
      "MinorUnit": "",
      "WithdrawalDate": "2002-03"
    },
    {
      "Entity": "ALBANIA",
      "Currency": "Old Lek",
      "AlphabeticCode": "ALK",
      "NumericCode": 8,
      "MinorUnit": "",
      "WithdrawalDate": "1989-12"
    },
    {
      "Entity": "ANDORRA",
      "Currency": "Andorran Peseta",
      "AlphabeticCode": "ADP",
      "NumericCode": 20,
      "MinorUnit": "",
      "WithdrawalDate": "2003-07"
    },
    {
      "Entity": "ANDORRA",
      "Currency": "Spanish Peseta",
      "AlphabeticCode": "ESP",
      "NumericCode": 724,
      "MinorUnit": "",
      "WithdrawalDate": "2002-03"
    },
    {
      "Entity": "ANDORRA",
      "Currency": "French Franc",
      "AlphabeticCode": "FRF",
      "NumericCode": 250,
      "MinorUnit": "",
      "WithdrawalDate": "2002-03"
    },
    {
      "Entity": "ANGOLA",
      "Currency": "Kwanza",
      "AlphabeticCode": "AOK",
      "NumericCode": 24,
      "MinorUnit": "",
      "WithdrawalDate": "1991-03"
    },
    {
      "Entity": "ANGOLA",
      "Currency": "New Kwanza",
      "AlphabeticCode": "AON",
      "NumericCode": 24,
      "MinorUnit": "",
      "WithdrawalDate": "2000-02"
    },
    {
      "Entity": "ANGOLA",
      "Currency": "Kwanza Reajustado",
      "AlphabeticCode": "AOR",
      "NumericCode": 982,
      "MinorUnit": "",
      "WithdrawalDate": "2000-02"
    },
    {
      "Entity": "ARGENTINA",
      "Currency": "Austral",
      "AlphabeticCode": "ARA",
      "NumericCode": 32,
      "MinorUnit": "",
      "WithdrawalDate": "1992-01"
    },
    {
      "Entity": "ARGENTINA",
      "Currency": "Peso Argentino",
      "AlphabeticCode": "ARP",
      "NumericCode": 32,
      "MinorUnit": "",
      "WithdrawalDate": "1985-07"
    },
    {
      "Entity": "ARGENTINA",
      "Currency": "Peso",
      "AlphabeticCode": "ARY",
      "NumericCode": 32,
      "MinorUnit": "",
      "WithdrawalDate": "1989 to 1990"
    },
    {
      "Entity": "ARMENIA",
      "Currency": "Russian Ruble",
      "AlphabeticCode": "RUR",
      "NumericCode": 810,
      "MinorUnit": "",
      "WithdrawalDate": "1994-08"
    },
    {
      "Entity": "AUSTRIA",
      "Currency": "Schilling",
      "AlphabeticCode": "ATS",
      "NumericCode": 40,
      "MinorUnit": "",
      "WithdrawalDate": "2002-03"
    },
    {
      "Entity": "AZERBAIJAN",
      "Currency": "Azerbaijan Manat",
      "AlphabeticCode": "AYM",
      "NumericCode": 945,
      "MinorUnit": "",
      "WithdrawalDate": "2005-10"
    },
    {
      "Entity": "AZERBAIJAN",
      "Currency": "Azerbaijanian Manat",
      "AlphabeticCode": "AZM",
      "NumericCode": 31,
      "MinorUnit": "",
      "WithdrawalDate": "2005-12"
    },
    {
      "Entity": "AZERBAIJAN",
      "Currency": "Russian Ruble",
      "AlphabeticCode": "RUR",
      "NumericCode": 810,
      "MinorUnit": "",
      "WithdrawalDate": "1994-08"
    },
    {
      "Entity": "BELARUS",
      "Currency": "Belarusian Ruble",
      "AlphabeticCode": "BYB",
      "NumericCode": 112,
      "MinorUnit": "",
      "WithdrawalDate": "2001-01"
    },
    {
      "Entity": "BELARUS",
      "Currency": "Belarusian Ruble",
      "AlphabeticCode": "BYR",
      "NumericCode": 974,
      "MinorUnit": "",
      "WithdrawalDate": "2017-01"
    },
    {
      "Entity": "BELARUS",
      "Currency": "Russian Ruble",
      "AlphabeticCode": "RUR",
      "NumericCode": 810,
      "MinorUnit": "",
      "WithdrawalDate": "1994-06"
    },
    {
      "Entity": "BELGIUM",
      "Currency": "Convertible Franc",
      "AlphabeticCode": "BEC",
      "NumericCode": 993,
      "MinorUnit": "",
      "WithdrawalDate": "1990-03"
    },
    {
      "Entity": "BELGIUM",
      "Currency": "Belgian Franc",
      "AlphabeticCode": "BEF",
      "NumericCode": 56,
      "MinorUnit": "",
      "WithdrawalDate": "2002-03"
    },
    {
      "Entity": "BELGIUM",
      "Currency": "Financial Franc",
      "AlphabeticCode": "BEL",
      "NumericCode": 992,
      "MinorUnit": "",
      "WithdrawalDate": "1990-03"
    },
    {
      "Entity": "BOLIVIA",
      "Currency": "Peso boliviano",
      "AlphabeticCode": "BOP",
      "NumericCode": 68,
      "MinorUnit": "",
      "WithdrawalDate": "1987-02"
    },
    {
      "Entity": "BOSNIA AND HERZEGOVINA",
      "Currency": "Dinar",
      "AlphabeticCode": "BAD",
      "NumericCode": 70,
      "MinorUnit": "",
      "WithdrawalDate": "1998-07"
    },
    {
      "Entity": "BRAZIL",
      "Currency": "Cruzeiro",
      "AlphabeticCode": "BRB",
      "NumericCode": 76,
      "MinorUnit": "",
      "WithdrawalDate": "1986-03"
    },
    {
      "Entity": "BRAZIL",
      "Currency": "Cruzado",
      "AlphabeticCode": "BRC",
      "NumericCode": 76,
      "MinorUnit": "",
      "WithdrawalDate": "1989-02"
    },
    {
      "Entity": "BRAZIL",
      "Currency": "Cruzeiro",
      "AlphabeticCode": "BRE",
      "NumericCode": 76,
      "MinorUnit": "",
      "WithdrawalDate": "1993-03"
    },
    {
      "Entity": "BRAZIL",
      "Currency": "New Cruzado",
      "AlphabeticCode": "BRN",
      "NumericCode": 76,
      "MinorUnit": "",
      "WithdrawalDate": "1990-03"
    },
    {
      "Entity": "BRAZIL",
      "Currency": "Cruzeiro Real",
      "AlphabeticCode": "BRR",
      "NumericCode": 987,
      "MinorUnit": "",
      "WithdrawalDate": "1994-07"
    },
    {
      "Entity": "BULGARIA",
      "Currency": "Lev A/52",
      "AlphabeticCode": "BGJ",
      "NumericCode": 100,
      "MinorUnit": "",
      "WithdrawalDate": "1989 to 1990"
    },
    {
      "Entity": "BULGARIA",
      "Currency": "Lev A/62",
      "AlphabeticCode": "BGK",
      "NumericCode": 100,
      "MinorUnit": "",
      "WithdrawalDate": "1989 to 1990"
    },
    {
      "Entity": "BULGARIA",
      "Currency": "Lev",
      "AlphabeticCode": "BGL",
      "NumericCode": 100,
      "MinorUnit": "",
      "WithdrawalDate": "2003-11"
    },
    {
      "Entity": "BURMA",
      "Currency": "Kyat",
      "AlphabeticCode": "BUK",
      "NumericCode": 104,
      "MinorUnit": "",
      "WithdrawalDate": "1990-02"
    },
    {
      "Entity": "CROATIA",
      "Currency": "Croatian Dinar",
      "AlphabeticCode": "HRD",
      "NumericCode": 191,
      "MinorUnit": "",
      "WithdrawalDate": "1995-01"
    },
    {
      "Entity": "CROATIA",
      "Currency": "Croatian Kuna",
      "AlphabeticCode": "HRK",
      "NumericCode": 191,
      "MinorUnit": "",
      "WithdrawalDate": "2015-06"
    },
    {
      "Entity": "CYPRUS",
      "Currency": "Cyprus Pound",
      "AlphabeticCode": "CYP",
      "NumericCode": 196,
      "MinorUnit": "",
      "WithdrawalDate": "2008-01"
    },
    {
      "Entity": "CZECHOSLOVAKIA",
      "Currency": "Krona A/53",
      "AlphabeticCode": "CSJ",
      "NumericCode": 203,
      "MinorUnit": "",
      "WithdrawalDate": "1989 to 1990"
    },
    {
      "Entity": "CZECHOSLOVAKIA",
      "Currency": "Koruna",
      "AlphabeticCode": "CSK",
      "NumericCode": 200,
      "MinorUnit": "",
      "WithdrawalDate": "1993-03"
    },
    {
      "Entity": "ECUADOR",
      "Currency": "Sucre",
      "AlphabeticCode": "ECS",
      "NumericCode": 218,
      "MinorUnit": "",
      "WithdrawalDate": "2000-09"
    },
    {
      "Entity": "ECUADOR",
      "Currency": "Unidad de Valor Constante (UVC)",
      "AlphabeticCode": "ECV",
      "NumericCode": 983,
      "MinorUnit": "",
      "WithdrawalDate": "2000-09"
    },
    {
      "Entity": "EQUATORIAL GUINEA",
      "Currency": "Ekwele",
      "AlphabeticCode": "GQE",
      "NumericCode": 226,
      "MinorUnit": "",
      "WithdrawalDate": "1986-06"
    },
    {
      "Entity": "ESTONIA",
      "Currency": "Kroon",
      "AlphabeticCode": "EEK",
      "NumericCode": 233,
      "MinorUnit": "",
      "WithdrawalDate": "2011-01"
    },
    {
      "Entity": "EUROPEAN MONETARY CO-OPERATION FUND (EMCF)",
      "Currency": "European Currency Unit (E.C.U)",
      "AlphabeticCode": "XEU",
      "NumericCode": 954,
      "MinorUnit": "",
      "WithdrawalDate": "1999-01"
    },
    {
      "Entity": "FINLAND",
      "Currency": "Markka",
      "AlphabeticCode": "FIM",
      "NumericCode": 246,
      "MinorUnit": "",
      "WithdrawalDate": "2002-03"
    },
    {
      "Entity": "FRANCE",
      "Currency": "French Franc",
      "AlphabeticCode": "FRF",
      "NumericCode": 250,
      "MinorUnit": "",
      "WithdrawalDate": "2002-03"
    },
    {
      "Entity": "FRENCH GUIANA",
      "Currency": "French Franc",
      "AlphabeticCode": "FRF",
      "NumericCode": 250,
      "MinorUnit": "",
      "WithdrawalDate": "2002-03"
    },
    {
      "Entity": "FRENCH SOUTHERN TERRITORIES",
      "Currency": "French Franc",
      "AlphabeticCode": "FRF",
      "NumericCode": 250,
      "MinorUnit": "",
      "WithdrawalDate": "2002-03"
    },
    {
      "Entity": "GEORGIA",
      "Currency": "Georgian Coupon",
      "AlphabeticCode": "GEK",
      "NumericCode": 268,
      "MinorUnit": "",
      "WithdrawalDate": "1995-10"
    },
    {
      "Entity": "GEORGIA",
      "Currency": "Russian Ruble",
      "AlphabeticCode": "RUR",
      "NumericCode": 810,
      "MinorUnit": "",
      "WithdrawalDate": "1994-04"
    },
    {
      "Entity": "GERMAN DEMOCRATIC REPUBLIC",
      "Currency": "Mark der DDR",
      "AlphabeticCode": "DDM",
      "NumericCode": 278,
      "MinorUnit": "",
      "WithdrawalDate": "1990-07 to 1990-09"
    },
    {
      "Entity": "GERMANY",
      "Currency": "Deutsche Mark",
      "AlphabeticCode": "DEM",
      "NumericCode": 276,
      "MinorUnit": "",
      "WithdrawalDate": "2002-03"
    },
    {
      "Entity": "GHANA",
      "Currency": "Cedi",
      "AlphabeticCode": "GHC",
      "NumericCode": 288,
      "MinorUnit": "",
      "WithdrawalDate": "2008-01"
    },
    {
      "Entity": "GHANA",
      "Currency": "Ghana Cedi",
      "AlphabeticCode": "GHP",
      "NumericCode": 939,
      "MinorUnit": "",
      "WithdrawalDate": "2007-06"
    },
    {
      "Entity": "GREECE",
      "Currency": "Drachma",
      "AlphabeticCode": "GRD",
      "NumericCode": 300,
      "MinorUnit": "",
      "WithdrawalDate": "2002-03"
    },
    {
      "Entity": "GUADELOUPE",
      "Currency": "French Franc",
      "AlphabeticCode": "FRF",
      "NumericCode": 250,
      "MinorUnit": "",
      "WithdrawalDate": "2002-03"
    },
    {
      "Entity": "GUINEA",
      "Currency": "Syli",
      "AlphabeticCode": "GNE",
      "NumericCode": 324,
      "MinorUnit": "",
      "WithdrawalDate": "1989-12"
    },
    {
      "Entity": "GUINEA",
      "Currency": "Syli",
      "AlphabeticCode": "GNS",
      "NumericCode": 324,
      "MinorUnit": "",
      "WithdrawalDate": "1986-02"
    },
    {
      "Entity": "GUINEA-BISSAU",
      "Currency": "Guinea Escudo",
      "AlphabeticCode": "GWE",
      "NumericCode": 624,
      "MinorUnit": "",
      "WithdrawalDate": "1978 to 1981"
    },
    {
      "Entity": "GUINEA-BISSAU",
      "Currency": "Guinea-Bissau Peso",
      "AlphabeticCode": "GWP",
      "NumericCode": 624,
      "MinorUnit": "",
      "WithdrawalDate": "1997-05"
    },
    {
      "Entity": "HOLY SEE (VATICAN CITY STATE)",
      "Currency": "Italian Lira",
      "AlphabeticCode": "ITL",
      "NumericCode": 380,
      "MinorUnit": "",
      "WithdrawalDate": "2002-03"
    },
    {
      "Entity": "ICELAND",
      "Currency": "Old Krona",
      "AlphabeticCode": "ISJ",
      "NumericCode": 352,
      "MinorUnit": "",
      "WithdrawalDate": "1989 to 1990"
    },
    {
      "Entity": "IRELAND",
      "Currency": "Irish Pound",
      "AlphabeticCode": "IEP",
      "NumericCode": 372,
      "MinorUnit": "",
      "WithdrawalDate": "2002-03"
    },
    {
      "Entity": "ISRAEL",
      "Currency": "Pound",
      "AlphabeticCode": "ILP",
      "NumericCode": 376,
      "MinorUnit": "",
      "WithdrawalDate": "1978 to 1981"
    },
    {
      "Entity": "ISRAEL",
      "Currency": "Old Shekel",
      "AlphabeticCode": "ILR",
      "NumericCode": 376,
      "MinorUnit": "",
      "WithdrawalDate": "1989 to 1990"
    },
    {
      "Entity": "ITALY",
      "Currency": "Italian Lira",
      "AlphabeticCode": "ITL",
      "NumericCode": 380,
      "MinorUnit": "",
      "WithdrawalDate": "2002-03"
    },
    {
      "Entity": "KAZAKHSTAN",
      "Currency": "Russian Ruble",
      "AlphabeticCode": "RUR",
      "NumericCode": 810,
      "MinorUnit": "",
      "WithdrawalDate": "1994-05"
    },
    {
      "Entity": "KYRGYZSTAN",
      "Currency": "Russian Ruble",
      "AlphabeticCode": "RUR",
      "NumericCode": 810,
      "MinorUnit": "",
      "WithdrawalDate": "1993-01"
    },
    {
      "Entity": "LAO",
      "Currency": "Pathet Lao Kip",
      "AlphabeticCode": "LAJ",
      "NumericCode": 418,
      "MinorUnit": "",
      "WithdrawalDate": "1979-12"
    },
    {
      "Entity": "LATVIA",
      "Currency": "Latvian Lats",
      "AlphabeticCode": "LVL",
      "NumericCode": 428,
      "MinorUnit": "",
      "WithdrawalDate": "2014-01"
    },
    {
      "Entity": "LATVIA",
      "Currency": "Latvian Ruble",
      "AlphabeticCode": "LVR",
      "NumericCode": 428,
      "MinorUnit": "",
      "WithdrawalDate": "1994-12"
    },
    {
      "Entity": "LESOTHO",
      "Currency": "Loti",
      "AlphabeticCode": "LSM",
      "NumericCode": 426,
      "MinorUnit": "",
      "WithdrawalDate": "1985-05"
    },
    {
      "Entity": "LESOTHO",
      "Currency": "Financial Rand",
      "AlphabeticCode": "ZAL",
      "NumericCode": 991,
      "MinorUnit": "",
      "WithdrawalDate": "1995-03"
    },
    {
      "Entity": "LITHUANIA",
      "Currency": "Lithuanian Litas",
      "AlphabeticCode": "LTL",
      "NumericCode": 440,
      "MinorUnit": "",
      "WithdrawalDate": "2014-12"
    },
    {
      "Entity": "LITHUANIA",
      "Currency": "Talonas",
      "AlphabeticCode": "LTT",
      "NumericCode": 440,
      "MinorUnit": "",
      "WithdrawalDate": "1993-07"
    },
    {
      "Entity": "LUXEMBOURG",
      "Currency": "Luxembourg Convertible Franc",
      "AlphabeticCode": "LUC",
      "NumericCode": 989,
      "MinorUnit": "",
      "WithdrawalDate": "1990-03"
    },
    {
      "Entity": "LUXEMBOURG",
      "Currency": "Luxembourg Franc",
      "AlphabeticCode": "LUF",
      "NumericCode": 442,
      "MinorUnit": "",
      "WithdrawalDate": "2002-03"
    },
    {
      "Entity": "LUXEMBOURG",
      "Currency": "Luxembourg Financial Franc",
      "AlphabeticCode": "LUL",
      "NumericCode": 988,
      "MinorUnit": "",
      "WithdrawalDate": "1990-03"
    },
    {
      "Entity": "MADAGASCAR",
      "Currency": "Malagasy Franc",
      "AlphabeticCode": "MGF",
      "NumericCode": 450,
      "MinorUnit": "",
      "WithdrawalDate": "2004-12"
    },
    {
      "Entity": "MALAWI",
      "Currency": "Kwacha",
      "AlphabeticCode": "MWK",
      "NumericCode": 454,
      "MinorUnit": "",
      "WithdrawalDate": "2016-02"
    },
    {
      "Entity": "MALDIVES",
      "Currency": "Maldive Rupee",
      "AlphabeticCode": "MVQ",
      "NumericCode": 462,
      "MinorUnit": "",
      "WithdrawalDate": "1989-12"
    },
    {
      "Entity": "MALI",
      "Currency": "Mali Franc",
      "AlphabeticCode": "MLF",
      "NumericCode": 466,
      "MinorUnit": "",
      "WithdrawalDate": "1984-11"
    },
    {
      "Entity": "MALTA",
      "Currency": "Maltese Lira",
      "AlphabeticCode": "MTL",
      "NumericCode": 470,
      "MinorUnit": "",
      "WithdrawalDate": "2008-01"
    },
    {
      "Entity": "MALTA",
      "Currency": "Maltese Pound",
      "AlphabeticCode": "MTP",
      "NumericCode": 470,
      "MinorUnit": "",
      "WithdrawalDate": "1983-06"
    },
    {
      "Entity": "MARTINIQUE",
      "Currency": "French Franc",
      "AlphabeticCode": "FRF",
      "NumericCode": 250,
      "MinorUnit": "",
      "WithdrawalDate": "2002-03"
    },
    {
      "Entity": "MAURITANIA",
      "Currency": "Ouguiya",
      "AlphabeticCode": "MRO",
      "NumericCode": 478,
      "MinorUnit": "",
      "WithdrawalDate": "2017-12"
    },
    {
      "Entity": "MAYOTTE",
      "Currency": "French Franc",
      "AlphabeticCode": "FRF",
      "NumericCode": 250,
      "MinorUnit": "",
      "WithdrawalDate": "2002-03"
    },
    {
      "Entity": "MEXICO",
      "Currency": "Mexican Peso",
      "AlphabeticCode": "MXP",
      "NumericCode": 484,
      "MinorUnit": "",
      "WithdrawalDate": "1993-01"
    },
    {
      "Entity": "MOLDOVA, REPUBLIC OF",
      "Currency": "Russian Ruble",
      "AlphabeticCode": "RUR",
      "NumericCode": 810,
      "MinorUnit": "",
      "WithdrawalDate": "1993-12"
    },
    {
      "Entity": "MONACO",
      "Currency": "French Franc",
      "AlphabeticCode": "FRF",
      "NumericCode": 250,
      "MinorUnit": "",
      "WithdrawalDate": "2002-03"
    },
    {
      "Entity": "MOZAMBIQUE",
      "Currency": "Mozambique Escudo",
      "AlphabeticCode": "MZE",
      "NumericCode": 508,
      "MinorUnit": "",
      "WithdrawalDate": "1978 to 1981"
    },
    {
      "Entity": "MOZAMBIQUE",
      "Currency": "Mozambique Metical",
      "AlphabeticCode": "MZM",
      "NumericCode": 508,
      "MinorUnit": "",
      "WithdrawalDate": "2006-06"
    },
    {
      "Entity": "NETHERLANDS",
      "Currency": "Netherlands Guilder",
      "AlphabeticCode": "NLG",
      "NumericCode": 528,
      "MinorUnit": "",
      "WithdrawalDate": "2002-03"
    },
    {
      "Entity": "NETHERLANDS ANTILLES",
      "Currency": "Netherlands Antillean Guilder",
      "AlphabeticCode": "ANG",
      "NumericCode": 532,
      "MinorUnit": "",
      "WithdrawalDate": "2010-10"
    },
    {
      "Entity": "NICARAGUA",
      "Currency": "Cordoba",
      "AlphabeticCode": "NIC",
      "NumericCode": 558,
      "MinorUnit": "",
      "WithdrawalDate": "1990-10"
    },
    {
      "Entity": "PERU",
      "Currency": "Sol",
      "AlphabeticCode": "PEH",
      "NumericCode": 604,
      "MinorUnit": "",
      "WithdrawalDate": "1989 to 1990"
    },
    {
      "Entity": "PERU",
      "Currency": "Inti",
      "AlphabeticCode": "PEI",
      "NumericCode": 604,
      "MinorUnit": "",
      "WithdrawalDate": "1991-07"
    },
    {
      "Entity": "PERU",
      "Currency": "Nuevo Sol",
      "AlphabeticCode": "PEN",
      "NumericCode": 604,
      "MinorUnit": "",
      "WithdrawalDate": "2015-12"
    },
    {
      "Entity": "PERU",
      "Currency": "Sol",
      "AlphabeticCode": "PES",
      "NumericCode": 604,
      "MinorUnit": "",
      "WithdrawalDate": "1986-02"
    },
    {
      "Entity": "POLAND",
      "Currency": "Zloty",
      "AlphabeticCode": "PLZ",
      "NumericCode": 616,
      "MinorUnit": "",
      "WithdrawalDate": "1997-01"
    },
    {
      "Entity": "PORTUGAL",
      "Currency": "Portuguese Escudo",
      "AlphabeticCode": "PTE",
      "NumericCode": 620,
      "MinorUnit": "",
      "WithdrawalDate": "2002-03"
    },
    {
      "Entity": "RÉUNION",
      "Currency": "French Franc",
      "AlphabeticCode": "FRF",
      "NumericCode": 250,
      "MinorUnit": "",
      "WithdrawalDate": "2002-03"
    },
    {
      "Entity": "ROMANIA",
      "Currency": "Leu A/52",
      "AlphabeticCode": "ROK",
      "NumericCode": 642,
      "MinorUnit": "",
      "WithdrawalDate": "1989 to 1990"
    },
    {
      "Entity": "ROMANIA",
      "Currency": "Old Leu",
      "AlphabeticCode": "ROL",
      "NumericCode": 642,
      "MinorUnit": "",
      "WithdrawalDate": "2005-06"
    },
    {
      "Entity": "ROMANIA",
      "Currency": "New Romanian Leu",
      "AlphabeticCode": "RON",
      "NumericCode": 946,
      "MinorUnit": "",
      "WithdrawalDate": "2015-06"
    },
    {
      "Entity": "RUSSIAN FEDERATION",
      "Currency": "Russian Ruble",
      "AlphabeticCode": "RUR",
      "NumericCode": 810,
      "MinorUnit": "",
      "WithdrawalDate": "2004-01"
    },
    {
      "Entity": "SAINT MARTIN",
      "Currency": "French Franc",
      "AlphabeticCode": "FRF",
      "NumericCode": 250,
      "MinorUnit": "",
      "WithdrawalDate": "1999-01"
    },
    {
      "Entity": "SAINT PIERRE AND MIQUELON",
      "Currency": "French Franc",
      "AlphabeticCode": "FRF",
      "NumericCode": 250,
      "MinorUnit": "",
      "WithdrawalDate": "2002-03"
    },
    {
      "Entity": "SAINT-BARTHÉLEMY",
      "Currency": "French Franc",
      "AlphabeticCode": "FRF",
      "NumericCode": 250,
      "MinorUnit": "",
      "WithdrawalDate": "1999-01"
    },
    {
      "Entity": "SAN MARINO",
      "Currency": "Italian Lira",
      "AlphabeticCode": "ITL",
      "NumericCode": 380,
      "MinorUnit": "",
      "WithdrawalDate": "2002-03"
    },
    {
      "Entity": "SAO TOME AND PRINCIPE",
      "Currency": "Dobra",
      "AlphabeticCode": "STD",
      "NumericCode": 678,
      "MinorUnit": "",
      "WithdrawalDate": "2017-12"
    },
    {
      "Entity": "SERBIA AND MONTENEGRO",
      "Currency": "Serbian Dinar",
      "AlphabeticCode": "CSD",
      "NumericCode": 891,
      "MinorUnit": "",
      "WithdrawalDate": "2006-10"
    },
    {
      "Entity": "SERBIA AND MONTENEGRO",
      "Currency": "Euro",
      "AlphabeticCode": "EUR",
      "NumericCode": 978,
      "MinorUnit": "",
      "WithdrawalDate": "2006-10"
    },
    {
      "Entity": "SLOVAKIA",
      "Currency": "Slovak Koruna",
      "AlphabeticCode": "SKK",
      "NumericCode": 703,
      "MinorUnit": "",
      "WithdrawalDate": "2009-01"
    },
    {
      "Entity": "SLOVENIA",
      "Currency": "Tolar",
      "AlphabeticCode": "SIT",
      "NumericCode": 705,
      "MinorUnit": "",
      "WithdrawalDate": "2007-01"
    },
    {
      "Entity": "SOUTH AFRICA",
      "Currency": "Financial Rand",
      "AlphabeticCode": "ZAL",
      "NumericCode": 991,
      "MinorUnit": "",
      "WithdrawalDate": "1995-03"
    },
    {
      "Entity": "SOUTH SUDAN",
      "Currency": "Sudanese Pound",
      "AlphabeticCode": "SDG",
      "NumericCode": 938,
      "MinorUnit": "",
      "WithdrawalDate": "2012-09"
    },
    {
      "Entity": "SOUTHERN RHODESIA",
      "Currency": "Rhodesian Dollar",
      "AlphabeticCode": "RHD",
      "NumericCode": 716,
      "MinorUnit": "",
      "WithdrawalDate": "1978 to 1981"
    },
    {
      "Entity": "SPAIN",
      "Currency": "Spanish Peseta",
      "AlphabeticCode": "ESA",
      "NumericCode": 996,
      "MinorUnit": "",
      "WithdrawalDate": "1978 to 1981"
    },
    {
      "Entity": "SPAIN",
      "Currency": "A\" Account (convertible Peseta Account)",
      "AlphabeticCode": "ESB",
      "NumericCode": 995,
      "MinorUnit": "",
      "WithdrawalDate": "1994-12"
    },
    {
      "Entity": "SPAIN",
      "Currency": "Spanish Peseta",
      "AlphabeticCode": "ESP",
      "NumericCode": 724,
      "MinorUnit": "",
      "WithdrawalDate": "2002-03"
    },
    {
      "Entity": "SUDAN",
      "Currency": "Sudanese Dinar",
      "AlphabeticCode": "SDD",
      "NumericCode": 736,
      "MinorUnit": "",
      "WithdrawalDate": "2007-07"
    },
    {
      "Entity": "SUDAN",
      "Currency": "Sudanese Pound",
      "AlphabeticCode": "SDP",
      "NumericCode": 736,
      "MinorUnit": "",
      "WithdrawalDate": "1998-06"
    },
    {
      "Entity": "SURINAME",
      "Currency": "Surinam Guilder",
      "AlphabeticCode": "SRG",
      "NumericCode": 740,
      "MinorUnit": "",
      "WithdrawalDate": "2003-12"
    },
    {
      "Entity": "SWAZILAND",
      "Currency": "Lilangeni",
      "AlphabeticCode": "SZL",
      "NumericCode": 748,
      "MinorUnit": "",
      "WithdrawalDate": "2018-08"
    },
    {
      "Entity": "SWITZERLAND",
      "Currency": "WIR Franc (for electronic)",
      "AlphabeticCode": "CHC",
      "NumericCode": 948,
      "MinorUnit": "",
      "WithdrawalDate": "2004-11"
    },
    {
      "Entity": "TAJIKISTAN",
      "Currency": "Russian Ruble",
      "AlphabeticCode": "RUR",
      "NumericCode": 810,
      "MinorUnit": "",
      "WithdrawalDate": "1995-05"
    },
    {
      "Entity": "TAJIKISTAN",
      "Currency": "Tajik Ruble",
      "AlphabeticCode": "TJR",
      "NumericCode": 762,
      "MinorUnit": "",
      "WithdrawalDate": "2001-04"
    },
    {
      "Entity": "TIMOR-LESTE",
      "Currency": "Rupiah",
      "AlphabeticCode": "IDR",
      "NumericCode": 360,
      "MinorUnit": "",
      "WithdrawalDate": "2002-07"
    },
    {
      "Entity": "TIMOR-LESTE",
      "Currency": "Timor Escudo",
      "AlphabeticCode": "TPE",
      "NumericCode": 626,
      "MinorUnit": "",
      "WithdrawalDate": "2002-11"
    },
    {
      "Entity": "TURKEY",
      "Currency": "Old Turkish Lira",
      "AlphabeticCode": "TRL",
      "NumericCode": 792,
      "MinorUnit": "",
      "WithdrawalDate": "2005-12"
    },
    {
      "Entity": "TURKEY",
      "Currency": "New Turkish Lira",
      "AlphabeticCode": "TRY",
      "NumericCode": 949,
      "MinorUnit": "",
      "WithdrawalDate": "2009-01"
    },
    {
      "Entity": "TURKMENISTAN",
      "Currency": "Russian Ruble",
      "AlphabeticCode": "RUR",
      "NumericCode": 810,
      "MinorUnit": "",
      "WithdrawalDate": "1993-10"
    },
    {
      "Entity": "TURKMENISTAN",
      "Currency": "Turkmenistan Manat",
      "AlphabeticCode": "TMM",
      "NumericCode": 795,
      "MinorUnit": "",
      "WithdrawalDate": "2009-01"
    },
    {
      "Entity": "UGANDA",
      "Currency": "Uganda Shilling",
      "AlphabeticCode": "UGS",
      "NumericCode": 800,
      "MinorUnit": "",
      "WithdrawalDate": "1987-05"
    },
    {
      "Entity": "UGANDA",
      "Currency": "Old Shilling",
      "AlphabeticCode": "UGW",
      "NumericCode": 800,
      "MinorUnit": "",
      "WithdrawalDate": "1989 to 1990"
    },
    {
      "Entity": "UKRAINE",
      "Currency": "Karbovanet",
      "AlphabeticCode": "UAK",
      "NumericCode": 804,
      "MinorUnit": "",
      "WithdrawalDate": "1996-09"
    },
    {
      "Entity": "UNION OF SOVIET SOCIALIST REPUBLICS",
      "Currency": "Rouble",
      "AlphabeticCode": "SUR",
      "NumericCode": 810,
      "MinorUnit": "",
      "WithdrawalDate": "1990-12"
    },
    {
      "Entity": "UNITED STATES",
      "Currency": "US Dollar (Same day)",
      "AlphabeticCode": "USS",
      "NumericCode": 998,
      "MinorUnit": "",
      "WithdrawalDate": "2014-03"
    },
    {
      "Entity": "URUGUAY",
      "Currency": "Old Uruguay Peso",
      "AlphabeticCode": "UYN",
      "NumericCode": 858,
      "MinorUnit": "",
      "WithdrawalDate": "1989-12"
    },
    {
      "Entity": "URUGUAY",
      "Currency": "Uruguayan Peso",
      "AlphabeticCode": "UYP",
      "NumericCode": 858,
      "MinorUnit": "",
      "WithdrawalDate": "1993-03"
    },
    {
      "Entity": "UZBEKISTAN",
      "Currency": "Russian Ruble",
      "AlphabeticCode": "RUR",
      "NumericCode": 810,
      "MinorUnit": "",
      "WithdrawalDate": "1994-07"
    },
    {
      "Entity": "VENEZUELA",
      "Currency": "Bolivar",
      "AlphabeticCode": "VEB",
      "NumericCode": 862,
      "MinorUnit": "",
      "WithdrawalDate": "2008-01"
    },
    {
      "Entity": "VENEZUELA",
      "Currency": "Bolivar Fuerte",
      "AlphabeticCode": "VEF",
      "NumericCode": 937,
      "MinorUnit": "",
      "WithdrawalDate": "2011-12"
    },
    {
      "Entity": "VENEZUELA (BOLIVARIAN REPUBLIC OF)",
      "Currency": "Bolivar",
      "AlphabeticCode": "VEF",
      "NumericCode": 937,
      "MinorUnit": "",
      "WithdrawalDate": "2016-02"
    },
    {
      "Entity": "VENEZUELA (BOLIVARIAN REPUBLIC OF)",
      "Currency": "Bolívar",
      "AlphabeticCode": "VEF",
      "NumericCode": 937,
      "MinorUnit": "",
      "WithdrawalDate": "2018-08"
    },
    {
      "Entity": "VIETNAM",
      "Currency": "Old Dong",
      "AlphabeticCode": "VNC",
      "NumericCode": 704,
      "MinorUnit": "",
      "WithdrawalDate": "1989-1990"
    },
    {
      "Entity": "YEMEN, DEMOCRATIC",
      "Currency": "Yemeni Dinar",
      "AlphabeticCode": "YDD",
      "NumericCode": 720,
      "MinorUnit": "",
      "WithdrawalDate": "1991-09"
    },
    {
      "Entity": "YUGOSLAVIA",
      "Currency": "New Yugoslavian Dinar",
      "AlphabeticCode": "YUD",
      "NumericCode": 890,
      "MinorUnit": "",
      "WithdrawalDate": "1990-01"
    },
    {
      "Entity": "YUGOSLAVIA",
      "Currency": "New Dinar",
      "AlphabeticCode": "YUM",
      "NumericCode": 891,
      "MinorUnit": "",
      "WithdrawalDate": "2003-07"
    },
    {
      "Entity": "YUGOSLAVIA",
      "Currency": "Yugoslavian Dinar",
      "AlphabeticCode": "YUN",
      "NumericCode": 890,
      "MinorUnit": "",
      "WithdrawalDate": "1995-11"
    },
    {
      "Entity": "ZAIRE",
      "Currency": "New Zaire",
      "AlphabeticCode": "ZRN",
      "NumericCode": 180,
      "MinorUnit": "",
      "WithdrawalDate": "1999-06"
    },
    {
      "Entity": "ZAIRE",
      "Currency": "Zaire",
      "AlphabeticCode": "ZRZ",
      "NumericCode": 180,
      "MinorUnit": "",
      "WithdrawalDate": "1994-02"
    },
    {
      "Entity": "ZAMBIA",
      "Currency": "Zambian Kwacha",
      "AlphabeticCode": "ZMK",
      "NumericCode": 894,
      "MinorUnit": "",
      "WithdrawalDate": "2012-12"
    },
    {
      "Entity": "ZIMBABWE",
      "Currency": "Rhodesian Dollar",
      "AlphabeticCode": "ZWC",
      "NumericCode": 716,
      "MinorUnit": "",
      "WithdrawalDate": "1989-12"
    },
    {
      "Entity": "ZIMBABWE",
      "Currency": "Zimbabwe Dollar (old)",
      "AlphabeticCode": "ZWD",
      "NumericCode": 716,
      "MinorUnit": "",
      "WithdrawalDate": "2006-08"
    },
    {
      "Entity": "ZIMBABWE",
      "Currency": "Zimbabwe Dollar",
      "AlphabeticCode": "ZWD",
      "NumericCode": 716,
      "MinorUnit": "",
      "WithdrawalDate": "2008-08"
    },
    {
      "Entity": "ZIMBABWE",
      "Currency": "Zimbabwe Dollar (new)",
      "AlphabeticCode": "ZWN",
      "NumericCode": 942,
      "MinorUnit": "",
      "WithdrawalDate": "2006-09"
    },
    {
      "Entity": "ZIMBABWE",
      "Currency": "Zimbabwe Dollar",
      "AlphabeticCode": "ZWR",
      "NumericCode": 935,
      "MinorUnit": "",
      "WithdrawalDate": "2009-06"
    },
    {
      "Entity": "ZZ01_Gold-Franc",
      "Currency": "Gold-Franc",
      "AlphabeticCode": "XFO",
      "NumericCode": "",
      "MinorUnit": "",
      "WithdrawalDate": "2006-10"
    },
    {
      "Entity": "ZZ02_RINET Funds Code",
      "Currency": "RINET Funds Code",
      "AlphabeticCode": "XRE",
      "NumericCode": "",
      "MinorUnit": "",
      "WithdrawalDate": "1999-11"
    },
    {
      "Entity": "ZZ05_UIC-Franc",
      "Currency": "UIC-Franc",
      "AlphabeticCode": "XFU",
      "NumericCode": "",
      "MinorUnit": "",
      "WithdrawalDate": "2013-11"
    }
  ]