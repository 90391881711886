import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import AppBar from "../custom/AppBar";
import Toolbar from "../custom/ToolBar";
import { useMutation } from "@apollo/client";
import AdminMenu from "../components/admin/AdminMenu";
import { logout } from "../schema/teacher";
import { useNavigate } from "react-router-dom";

const rightLink = {
  fontSize: 16,
  color: "common.white",
  ml: 3,
};

function AdminAppBar() {
  const [logoutf] = useMutation(logout);
  const navigate = useNavigate();
  return (
    <div>
      <AppBar position="fixed">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box sx={{ flex: 1 }} />
          <Link
            variant="h6"
            underline="none"
            color="inherit"
            href="/admin/home"
            sx={{ fontSize: 26 }}
            style={{ cursor: "pointer" }}
            fontFamily={"DM Serif Text"}
          >
            {"Prithivi Grow Yoga - Admin"}
          </Link>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            {localStorage.getItem("user") === "Admin" ? (
              <Box justifyContent={"center"} paddingRight={1.5}>
                <AdminMenu />
              </Box>
            ) : null}

            {localStorage.getItem("user") === "Admin" ? (
              <Link
                color="inherit"
                variant="h6"
                underline="none"
                padding={1}
                // href="/admin/login"
                onClick={async () => {
                  await logoutf();
                  localStorage.removeItem("name");
                  localStorage.removeItem("user");
                  navigate("/admin/login");
                }}
                sx={{ rightLink, color: "black" }}
              >
                {"Logout"}
              </Link>
            ) : (
              <Link
                color="inherit"
                variant="h6"
                underline="none"
                padding={1}
                href="/admin/login"
                sx={{ rightLink, color: "black" }}
              >
                {"Login"}
              </Link>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default AdminAppBar;
