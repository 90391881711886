import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "../custom/Button";
import Typography from "../custom/Typography";
import scheduler from '../assets/scheduler.png'
import practice from '../assets/practice.png'
import video from '../assets/video.png'
import lines from '../assets/productCurvyLines.png'

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

const number = {
  fontSize: 24,
  fontFamily: "default",
  color: "secondary.main",
  fontWeight: "medium",
};

const image = {
  height: 55,
  my: 4,
};

function ProductHowItWorks() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", bgcolor: "secondary.light", overflow: "hidden" }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src={lines}
          alt="curvy lines"
          sx={{
            pointerEvents: "none",
            position: "absolute",
            top: -180,
            opacity: 0.7,
          }}
        />
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 14 }}>
          How it works
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>1.</Box>
                <Box
                  component="img"
                  src={scheduler}
                  alt="suitcase"
                  sx={image}
                />
                <Typography variant="h5" align="center">
                  {
                    <Typography variant="h5" sx={{ fontSize:25,color: "secondary.main" }}>
                      Register
                    </Typography>
                  }
                  for our diverse range of Yoga classes and workshops, tailored
                  to cater to your specific preferences and requirements. Select
                  the option that aligns with your needs and interests.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>2.</Box>
                <Box
                  component="img"
                  src={practice}
                  alt="graph"
                  sx={{ height: 75,
                    my: 3,}}
                />
                <Typography variant="h5" align="center">
                  {
                    <Typography variant="h5" sx={{ fontSize:25,color: "secondary.main" }}>
                      Experience
                    </Typography>
                  }
                  the world of yoga in the convenience of your own home through
                  our scheduled classes and workshops.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>3.</Box>
                <Box
                  component="img"
                  src={video}
                  alt="clock"
                  sx={image}
                />
                <Typography variant="h5" align="center">
                  {
                    <Typography variant="h5" sx={{ fontSize:25, color: "secondary.main" }}>
                      Immerse
                    </Typography>
                  }
                  {
                    "yourself in the practice of yoga with us, enjoying the flexibility to revisit our classes and workshops at your convenience."
                  }
                  {
                    "Expand your contentment and relaxation by enrolling in additional classes to enhance your experience."
                  }
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <Button
          color="secondary"
          size="large"
          variant="contained"
          component="a"
          href="/student/sign-up"
          sx={{ mt: 8 }}
        >
          Get started
        </Button>
      </Container>
    </Box>
  );
}

export default ProductHowItWorks;
