import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "../custom/Typography";
import bg from "../assets/appCurvyLines.png";
import { Paper } from "@mui/material";
import Carousel from "react-material-ui-carousel";
const items = [
  {
    name: "Kirubakaran Chinnasamy",
    location: "Singapore",
    occupation: "Freelancer",
    description:
      "It was good feeling to do yoga with the group for fitness and healthy lifestyle. Start of the workshop I could barely bend forward, after 6 weeks of daily practice I could touch my feet first time in many years. The daily practice of Pranayama and Relaxation helped me to improve my focus and regulate my Blood Pressure.",
  },
  {
    name: "Usha Rani",
    location: "Singapore",
    occupation: "School Teacher",
    description:
      "I have taken BASIC level workshop in JAN 2023 that opened my mind to new way to care for my mind and body. My body is not flexible at all, having thyroid and many other issues. I have been doing simple yoga practice on daily basis that has helped me to bend forward and now I am close to touching my feet, earlier cant bend forward at all. I see lot of improvement in my body postures and mindfulness.",
  },
  {
    name: "Sangeetha Subramanian",
    location: "Dallas",
    occupation: "Speech Therapist",
    description:
      "Very informative yoga sessions. I enjoyed Yoga poses and diet and cooking tips, tips like what to eat, what to use while cooking etc.",
  },
  {
    name: "Sudarsan Maddur",
    occupation: "Revenue Analyst",
    location: "New York City",
    description:
      "The workshop was easy to follow, and the combination of the pranayama and asanas are very beneficial. Amazing that I had to spend only 30 mins daily for a wonderful day.",
  },
  {
    name: "Sateesh Surampally",
    occupation: "IT Professional",
    location: "Dallas",
    description:
      "Yoga constitues of asanas, pranayamas, relaxation techniques, diet, nutrition, meditation, sleep, and positive thinking. This workshop focuses on each of these aspects unlike yoga studios here in US who think yoga is just a bunch of exercises. BASIC & INTERMEDIATE YOGA workshop is for everyone. Thanks to Shreedhar who patiently held our hands through out the workshop and made sure we not only learn but practiced what was thought in the sessions.",
  },
  {
    name: "Sudha C",
    occupation: "Customer Service Rep",
    location: "Houston",
    description:
      "Loved the yoga class with Sreedhar and he has explained each and every move clearly He also made sure we got the steps correctly and cleared any questions right away. I would say he is the best teacher I wish in my life all my teachers were like him. He also made sure that we did our practices checked on each individuals that were in the class. I am planning to take classes with him again. ",
  },
  {
    name: "Jegan Gomangalam",
    occupation: "Entrpreneur",
    location: "Boston",
    description:
      " I recently attended a six-week yoga workshop with Mr.Sreedhar and I am so glad I did. Not only did I feel more energized and focused after each class, but I also learned a lot of good habits that have continued to benefit me even after the workshop ended. Sreedhar was very knowledgeable and supportive, and the small group setting allowed for personalized attention. I highly recommend this workshop to anyone looking to improve their physical and mental well-being through the practice of yoga and learn tips on healthy lifestyle.",
  },
  {
    name: "Sampath kumar,",
    occupation: "Retired Engineer",
    location: "Long Island, New York",
    description:
      "I have some basic knowledge of Yoga having been practicing Yoga for a while. I attended Mr.Shreedhar’s Yoga class and observed his meticulous and excellent demonstration of various yoga exercises and evaluate Shreedhar as one cut above other Yoga teachers I have come across. I am impressed with his excellent demonstration of Surya Namaskar, Moon salutation, Super Brain Yoga, Asanas, Pranayama and various Mudras that contribute immensely to the good health of Yoga practitioners. Unlike other Yoga programs, Shreedhar also gave importance to consuming nutritious foods and suggested vegetables with nutrients that enhance our health. Shreedhar also keenly monitored the progress of attendees in practicing Yoga exercises at home. It was a pleasure for me to attend all his classes with interest and I consider Mr. Shreedhar as an accomplished Yoga teacher. I benefitted much by attending his Yoga classes and highly recommend without any reservations to others.",
  },
  {
    name: "Balaji S",
    location: "Cincinnati, USA",
    description:
      "I have taken BASIC & INTERMEDIATE level workshop. It gave me lot of insights on Yoga Practice and Diet. I have been suffering from Sinus for a long time, daily practice of Pranayama helped me come out of sinus problem. Now I can take head bath without fear of getting cold or headache. I would recommend the Yoga workshops to others suffering from sinus related ailments. ",
  },
];
function Project(props) {
  console.log(props);
  let { item } = props;
  return (
    <Paper
      className="Project"
      sx={{
        backgroundColor: "#FFF9ED",
        minHeight: 580,
      }}
      elevation={1}
    >
      <Box>
        <Box align="center">
          <Typography
            sx={{
              fontFamily: "'DM Serif Text', serif",
              fontSize: 32,
              paddingTop: 2,
              color: "#008000",
            }}
          >
            {item.name}
          </Typography>
          <Typography
            sx={{
              fontFamily: "'DM Serif Text', serif",
              fontSize: 22,
            }}
          >
            {item.occupation} from {item.location}
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontFamily: "oxygen", fontSize: 24, padding: 6 }}>
            {item.description}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}
function Testimonials() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", overflow: "hidden", bgcolor: "secondary.light" }}
    >
      <Container
        sx={{
          mt: 5,
          mb: 5,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src={bg}
          alt="curvy lines"
          sx={{
            pointerEvents: "none",
            position: "absolute",
            top: -80,
            opacity: 0.7,
          }}
        />
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 4 }}>
          Yoga Enthusiasts Speak: Testimonials from Our Practitioners
        </Typography>
        <Container
          component="section"
          sx={{
            flexWrap: "wrap",
            mt: 1,
            mb: 1,
          }}
        >
          <Carousel
            className="SecondExample"
            autoPlay
            animation="slide"
            navButtonsAlwaysVisible
            cycleNavigation
            swipe
            fullHeightHover
          >
            {items.map((item, index) => {
              return <Project item={item} key={index} />;
            })}
          </Carousel>
        </Container>
      </Container>
    </Box>
  );
}

export default Testimonials;
