import React from "react";
import { useQuery } from "@apollo/client";
import { MyCourses } from "../../schema/student";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import withRoot from "../../withRoot";
import AppAppBar from "../../views/AppAppBar";
import Typography from "../../custom/Typography";
import { Box, Collapse, IconButton, Link } from "@mui/material";
import Button from "../../custom/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ButtoN from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import {
  ArrowDownward,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import {
  StyledCell,
  StyledTableCell,
  StyledTableRow,
} from "../teacher/MyCourse";
import MyCoursesLayout from "../../views/MyCoursesLayout";

const VideoComponent = () => {
  return (
    <MyCoursesLayout
      sxBackground={{
        backgroundColor: "#7fc7d9", // Average color of the background image.
        backgroundPosition: "center",
      }}
    >
      <Typography
        color="secondary"
        align="center"
        variant={"h4"}
        // sx={{ fontSize: 50, fontFamily: "revert" }}
        marked={"center"}
      >
        My Courses
      </Typography>
      <Typography
        color="inherit"
        align="center"
        sx={{
          mb: 4,
          fontSize: 40,
          mt: { xs: 4, sm: 10 },
          // fontFamily: "'Poppins', sans-serif",
        }}
      >
        All your registered courses at one place
      </Typography>
      <Box sx={{ color: "#008000" }}>
        <Button
          color="secondary"
          variant="outlined"
          size="large"
          component="a"
          sx={{ minWidth: 200 }}
        >
          Attend Now <ArrowDownward />
        </Button>
      </Box>
    </MyCoursesLayout>
  );
};
function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  return (
    <React.Fragment>
      <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <StyledTableCell component="th" scope="row" align="center">
          {row?.course?.title.charAt(0).toUpperCase() +
            row.course?.title?.slice(1)}{" "}
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen1(!open1);
            }}
          >
            {open1 ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell align="center">
          {row?.course?.course_type.charAt(0).toUpperCase() +
          row.course?.course_type?.slice(1)
            ? row?.course?.course_type.charAt(0).toUpperCase() +
              row.course?.course_type?.slice(1)
            : "Class"}
        </StyledTableCell>
        <StyledTableCell align="center"> {row.teacher?.name}</StyledTableCell>
        <StyledTableCell align="center"> {row?.name}</StyledTableCell>
        <StyledTableCell align="center"> {row?.days}</StyledTableCell>
        <StyledTableCell align="center">{row?.startTime}</StyledTableCell>
        <StyledTableCell align="center">
          {!open ? (
            <Button
              variant="outlined"
              color="textColor"
              size="small"
              onClick={() => {
                setOpen(!open);
              }}
            >
              Click for Details
            </Button>
          ) : (
            <Button
              variant="standard"
              sx={{ color: "red" }}
              onClick={() => {
                setOpen(!open);
              }}
            >
              Close
            </Button>
          )}
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Meeting Details
              </Typography>
              <Table size="large" aria-label="course">
                <TableHead>
                  <StyledTableRow>
                    <StyledCell>Link (Primary)</StyledCell>
                    <StyledCell>Meeting ID</StyledCell>
                    <StyledCell align="center">Passcode</StyledCell>
                    <StyledCell align="center">Alternate Link</StyledCell>
                    <StyledCell align="center">Alternate Meeting ID</StyledCell>
                    <StyledCell align="center">Alternate Passcode</StyledCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow key={row?.id}>
                    <StyledTableCell component="th" scope="row">
                      <Link
                        underline="hover"
                        href={`${row.link}`}
                        target="_blank"
                        sx={{ color: "secondary.dark" }}
                      >
                        {row.link}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.meetingId}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.passCode}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Link
                        underline="hover"
                        href={`${row.altLink}`}
                        target="_blank"
                        sx={{ color: "secondary.dark" }}
                      >
                        {row.altLink}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.altMeetingId}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.altPassCode}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open1} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Course Details
              </Typography>
              <Table size="large" aria-label="course">
                <TableHead>
                  <StyledTableRow>
                    <StyledCell>Course Description</StyledCell>
                    <StyledCell>Fee Paid</StyledCell>
                    <StyledCell>Class Description</StyledCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow key={row?.id}>
                    <StyledTableCell component="th" scope="row">
                      {row?.course?.description.charAt(0).toUpperCase() +
                        row.course?.description?.slice(1)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row?.currency} {row?.fees}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row?.description.charAt(0).toUpperCase() +
                        row?.description?.slice(1)}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledCell>
      </StyledTableRow>
    </React.Fragment>
  );
}
const MyCourse = () => {
  const { data, loading, error, refetch } = useQuery(MyCourses);
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [opeN, setOpeN] = React.useState(false);
  const handleClickOpen = () => {
    setOpeN(true);
  };

  const handleClose = () => {
    setOpeN(false);
  };

  const handleOK = () => {
    setOpeN(false);
    navigate("/");
    localStorage.clear();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  if (loading) {
    return <pre>Loading....</pre>;
  }
  if (error) {
    if (!opeN) {
      handleClickOpen();
    }
    return (
      <div>
        <Dialog
          open={opeN}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Authentication Expired"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Either the Authentication token has expired and you have been
              automatically logged out or you haven't logged in. You need to
              sign in again to continue browsing on the website
              <br />
              Reason: {error?.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtoN color="error" onClick={handleClose}>
              Cancel
            </ButtoN>
            <ButtoN color="success" onClick={handleOK} autoFocus>
              OK
            </ButtoN>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  return (
    <React.Fragment>
      <AppAppBar />
      <VideoComponent />
      <Box
        margin={2.5}
        sx={{
          display: "flex",
          mt: 2,
          mb: 4,
        }}
      >
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="collapsible table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Course Title</StyledTableCell>
                <StyledTableCell align="center">Type</StyledTableCell>
                <StyledTableCell align="center">Instructor</StyledTableCell>
                <StyledTableCell align="center">Class Name</StyledTableCell>
                <StyledTableCell align="center">Class days</StyledTableCell>
                <StyledTableCell align="center">Class Time</StyledTableCell>
                <StyledTableCell align="center">
                  Meeting Information
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {data?.getMyCoursesStudent?.map((row) => (
                <Row
                  key={row.id}
                  row={row}
                  //   approvef={approvef}
                  //   rejectf={rejectf}
                  refetch={refetch}
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={data?.getMyCoursesStudent?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </React.Fragment>
  );
};

export default withRoot(MyCourse);
