import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { Field, Form, FormSpy } from "react-final-form";
import Typography from "../../custom/Typography";
import AppForm from "../../views/AppForm";
import { email, required } from "../../form/validation";
import RFTextField from "../../form/RFTextField";
import FormButton from "../../form/FormButton";
import FormFeedback from "../../form/FormFeedback";
import withRoot from "../../withRoot";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Create_Teacher } from "../../schema/teacher";
import TeacherAppBar from "../../views/TeacherAppBar";

function TeacherSignUp() {
  const [sent, setSent] = React.useState(false);
  const [fname, setFname] = React.useState("");
  const [uname, setUname] = React.useState("");
  const [mail, setMail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [createTeacher] = useMutation(Create_Teacher);
  const navigate = useNavigate();
  const validate = (values) => {
    const errors = required(
      ["fullName", "userName", "email", "phone", "password"],
      values
    );

    if (!errors.email) {
      const emailError = email(values.email);
      if (emailError) {
        errors.email = emailError;
      }
    }

    return errors;
  };

  const handleSubmit = async () => {
    setSent(true);
    try {
      let data = {
        email: mail,
        name: fname,
        password,
        phone,
        username: uname,
      };
      await createTeacher({ variables: { data } })
        .then((loginResult) => {
          navigate("/teacher/login");
        })
        .catch((error) => {
          console.log(JSON.stringify(error, null, 2));
        });
    } catch (error) {
      console.log(error);
      window.alert("something went wrong");
    }
  };

  return (
    <React.Fragment>
      <TeacherAppBar />
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Sign Up
          </Typography>
          <Typography variant="body2" align="center">
            <Link
              href="/teacher/login"
              style={{ cursor: "pointer" }}
              sx={{ fontSize: 15, color: "secondary.dark" }}
              underline="always"
            >
              Already signed up?
            </Link>
          </Typography>
        </React.Fragment>
        <Form
          onSubmit={handleSubmit}
          subscription={{ submitting: true }}
          validate={validate}
        >
          {({ handleSubmit: handleSubmit2, submitting }) => (
            <Box
              component="form"
              onSubmit={handleSubmit2}
              noValidate
              sx={{ mt: 0 }}
            >
              <Field
                component={RFTextField}
                disabled={submitting || sent}
                autoComplete="given-name"
                fullWidth
                margin="normal"
                label="Full name"
                name="fullName"
                value={fname}
                parse={(value) => {
                  setFname(value);
                  return value;
                }}
                required
              />

              <Field
                component={RFTextField}
                disabled={submitting || sent}
                autoComplete="family-name"
                fullWidth
                label="User Name"
                name="userName"
                value={uname}
                parse={(value) => {
                  setUname(value);
                  return value;
                }}
                margin="normal"
                required
              />

              <Field
                autoComplete="email"
                component={RFTextField}
                disabled={submitting || sent}
                fullWidth
                label="Email"
                margin="normal"
                name="email"
                required
                value={mail}
                parse={(value) => {
                  setMail(value.trim());
                  return value;
                }}
              />
              <Field
                fullWidth
                component={RFTextField}
                disabled={submitting || sent}
                required
                name="password"
                autoComplete="new-password"
                label="Password"
                type="password"
                margin="normal"
                value={password}
                parse={(value) => {
                  setPassword(value);
                  return value;
                }}
              />
              <Field
                fullWidth
                component={RFTextField}
                disabled={submitting || sent}
                required
                name="phone"
                label="Phone Number"
                type="number"
                margin="normal"
                value={phone}
                parse={(value) => {
                  setPhone(value);
                  return value;
                }}
              />
              <FormSpy subscription={{ submitError: true }}>
                {({ submitError }) =>
                  submitError ? (
                    <FormFeedback error sx={{ mt: 2 }}>
                      {submitError}
                    </FormFeedback>
                  ) : null
                }
              </FormSpy>
              <FormButton
                sx={{ mt: 3, mb: 2 }}
                disabled={submitting || sent}
                color="secondary"
                fullWidth
              >
                {submitting || sent ? "In progress…" : "Sign Up"}
              </FormButton>
            </Box>
          )}
        </Form>
      </AppForm>
    </React.Fragment>
  );
}

export default withRoot(TeacherSignUp);
