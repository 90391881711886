import React, { useState } from "react";
import withRoot from "../../withRoot";
import image from "../../assets/appCurvyLines.png";
import {
  Box,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import Typography from "../../custom/Typography";
import { Create, Person } from "@mui/icons-material";
import Paper from "../../custom/Paper";
import { timeZones } from "../../assets/files/timezones";
import { currencies } from "../../assets/files/currencies";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { RegStudent } from "../../schema/student";
import Button from "../../custom/Button";
import { CustomComplete, CustomField } from "../teacher/CreateCourse";
import AppAppBar from "../../views/AppAppBar";
const hours = ["1", "2", "3", "4", "5", "5+"];
const options = [
  "Flexibility",
  "Core strength",
  "Relaxation",
  "Boost Energy",
  "Muscle and Joint Strength",
  "Boost metabolism",
  "Improve lung capacity",
  "Better Sleep",
  "Group Energy",
  "Discipline",
  "Healthy lifestyle",
  "Improve Posture",
  "Improve Sports and Games Performance",
  "Mental calmness",
  "Emotional Balance",
  "Teacher Training",
  "Health Condition",
  "Yoga Therapy",
  "Self Empowerment",
  "De-addiction",
  "Lose weight",
  "Managing Stress",
  "Improve Focus and Concentration",
  "Others",
];
const age = ["14-25", "26-35", "36-45", "46-55", "56 above"];
const StudentRegister = () => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [ageRange, setAgeRange] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [occ, setOcc] = React.useState("");
  const [count, setCount] = React.useState("");
  const [curr, setCurr] = React.useState("");
  const [timezone, setTimezone] = React.useState("");
  const [r1, setR1] = React.useState("");
  const [r2, setR2] = React.useState("");
  const [com, setCom] = React.useState("");
  const [isActive, setIsActive] = useState("Yes");
  const [isTips, setIsTips] = useState("Yes");
  const [isInjury, setIsInjury] = useState("No");
  const [isMedicalCondition, setIsMedicalCondition] = useState("No");
  const [activeHrs, setActiveHrs] = useState("1");
  const [checked, setChecked] = React.useState(false);
  const navigate = useNavigate();
  const [regStudent] = useMutation(RegStudent);

  return (
    <React.Fragment>
      <AppAppBar />
      <Box
        sx={{
          display: "flex",
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
        }}
        //   component={Container}
      >
        <Container maxWidth="lg">
          <Box sx={{ mt: 7, mb: 12 }}>
            <Paper
              background="light"
              sx={{ py: { xs: 4, md: 3 }, px: { xs: 3, md: 6 } }}
            >
              <Box align="center">
                <Typography sx={{ fontSize: 35 }}>
                  <Person size="large" /> Student Registration Form
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: 25 }}>
                  <Create /> Personal Details
                </Typography>
              </Box>
              <Grid padding={1} container spacing={2}>
                <Grid item xs={3} paddingRight={2}>
                  <CustomField
                    variant="outlined"
                    label="Full Name"
                    placeholder="your full name"
                    type="text"
                    size="large"
                    fullWidth
                    required
                    margin="normal"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3} paddingRight={2}>
                  <CustomField
                    variant="outlined"
                    label="Email"
                    placeholder="same as login"
                    type="text"
                    size="large"
                    fullWidth
                    required
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3.5} paddingRight={2}>
                  <FormControl
                    required
                    color="textColor"
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                  >
                    <FormLabel sx={{ color: "#008000" }} id="radio">
                      Gender
                    </FormLabel>
                    <RadioGroup row aria-labelledby="radio" name="gender">
                      <FormControlLabel
                        value="female"
                        control={<Radio color="textColor" />}
                        label="Female"
                      />
                      <FormControlLabel
                        value="male"
                        control={<Radio color="textColor" />}
                        label="Male"
                      />
                      <FormControlLabel
                        value="others"
                        control={<Radio color="textColor" />}
                        label="Others"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={2} paddingRight={2}>
                  <CustomComplete
                    disablePortal
                    options={age}
                    onInputChange={(event, newInputValue) => {
                      setAgeRange(newInputValue);
                    }}
                    renderInput={(params) => (
                      <Box paddingTop={2}>
                        <CustomField
                          //   sx={{maxHeight:'20px'}}
                          {...params}
                          // fullWidth
                          variant="outlined"
                          label="Age"
                          placeholder="your age"
                          onChange={(e) => {
                            setAgeRange(e?.target?.value);
                          }}
                        />
                      </Box>
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container padding={1} spacing={2}>
                <Grid item xs={2.5}>
                  <CustomComplete
                    disablePortal
                    options={currencies
                      ?.map((t) => t.AlphabeticCode)
                      .filter(
                        (item, index) =>
                          currencies
                            ?.map((t) => t.AlphabeticCode)
                            .indexOf(item) === index
                      )}
                    onInputChange={(event, newInputValue) => {
                      setCurr(newInputValue);
                    }}
                    renderInput={(params) => (
                      <Box paddingTop={2}>
                        <CustomField
                          {...params}
                          variant="outlined"
                          label="Currency Preference"
                          required
                          onChange={(e) => {
                            setCurr(e.target.value);
                          }}
                        />
                      </Box>
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomField
                    variant="outlined"
                    type="number"
                    fullWidth
                    margin="normal"
                    label="Phone"
                    placeholder="your contact number"
                    required
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomField
                    variant="outlined"
                    type="text"
                    fullWidth
                    margin="normal"
                    label="Occupation"
                    onChange={(e) => setOcc(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container padding={1} spacing={2}>
                <Grid item xs={3.5} paddingRight={2}>
                  <CustomComplete
                    disablePortal
                    options={timeZones
                      ?.map((t) => t.Name)
                      .filter(
                        (item, index) =>
                          timeZones?.map((t) => t.Name).indexOf(item) === index
                      )}
                    onInputChange={(event, newInputValue) => {
                      setCount(newInputValue);
                    }}
                    renderInput={(params) => (
                      <Box paddingTop={2}>
                        <CustomField
                          {...params}
                          variant="outlined"
                          label="Country"
                          onChange={(e) => {
                            setCount(e.target.value);
                          }}
                        />
                      </Box>
                    )}
                  />
                </Grid>
                <Grid item xs={3.5} paddingRight={2}>
                  <CustomComplete
                    disablePortal
                    options={timeZones?.map((t, index) => t.TimeZone)}
                    onInputChange={(event, newInputValue) => {
                      setTimezone(newInputValue);
                    }}
                    renderInput={(params) => (
                      <Box paddingTop={2}>
                        <CustomField
                          {...params}
                          variant="outlined"
                          label="Time Zone"
                          onChange={(e) => {
                            setTimezone(e.target.value);
                          }}
                        />
                      </Box>
                    )}
                  />
                </Grid>
              </Grid>
              <Box padding={4}>
                <Divider />
              </Box>
              <Box padding="10px">
                <Typography sx={{ fontSize: 25 }}>
                  <Create /> Yoga experience
                </Typography>
              </Box>
              <Grid container padding={1} spacing={2}>
                <Grid item xs={3} paddingRight={2}>
                  <FormControl required color="textColor">
                    <FormLabel
                      sx={{ fontSize: 18, color: "#008000" }}
                      id="radio"
                    >
                      Are you physically active?
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="radio"
                      name="gender"
                      value={isActive}
                      onChange={(e) => {
                        setIsActive(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio color="textColor" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio color="textColor" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {isActive === "Yes" ? (
                  <Grid item xs={4.5} paddingRight={2}>
                    <FormControl required color="textColor">
                      <FormLabel
                        sx={{ fontSize: 18, color: "#008000" }}
                        id="radio"
                      >
                        How many hours are you active in a day?
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="radio"
                        name="gender"
                        value={activeHrs}
                        onChange={(e) => {
                          setActiveHrs(e.target.value);
                        }}
                      >
                        {hours.map((hour, index) => {
                          return (
                            <FormControlLabel
                              value={hour}
                              control={<Radio color="textColor" />}
                              label={hour}
                            />
                          );
                        })}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                ) : null}
              </Grid>
              <Grid container padding={1} spacing={2}>
                <Grid item xs={2.5} paddingRight={1}>
                  <Typography sx={{ fontSize: 18, color: "#008000" }}>
                    Reason for joining Yoga classes :
                  </Typography>
                </Grid>
                <Grid item xs={3} paddingRight={2}>
                  <CustomComplete
                    disablePortal
                    options={options}
                    onInputChange={(event, newInputValue) => {
                      setR1(newInputValue);
                    }}
                    renderInput={(params) => (
                      <Box>
                        <CustomField
                          {...params}
                          required
                          variant="outlined"
                          label="Primary Reason"
                          onChange={(e) => {
                            setR1(e.target.value);
                          }}
                        />
                      </Box>
                    )}
                  />
                </Grid>
                <Grid item xs={3} paddingRight={2}>
                  <CustomComplete
                    disablePortal
                    options={options}
                    onInputChange={(event, newInputValue) => {
                      setR2(newInputValue);
                    }}
                    renderInput={(params) => (
                      <Box>
                        <CustomField
                          {...params}
                          variant="outlined"
                          label="Secondary Reason"
                          onChange={(e) => {
                            //   setName(e?.target?.value);
                            setR2(e.target.value);
                          }}
                        />
                      </Box>
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container padding={1} spacing={2}>
                <Grid item xs={3} paddingRight={2}>
                  <FormControl required color="textColor">
                    <FormLabel
                      sx={{ fontSize: 18, color: "#008000" }}
                      id="radio"
                    >
                      Any Injury in last 3 Months?
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="radio"
                      name="injury"
                      value={isInjury}
                      onChange={(e) => {
                        setIsInjury(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio color="textColor" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio color="textColor" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={4.5} paddingRight={2}>
                  <FormControl required color="textColor">
                    <FormLabel
                      sx={{ fontSize: 18, color: "#008000" }}
                      id="radio"
                    >
                      Under any medication/ Have any condition?
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="radio"
                      name="condition"
                      value={isMedicalCondition}
                      onChange={(e) => {
                        setIsMedicalCondition(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio color="textColor" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio color="textColor" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={3.5} paddingRight={2}>
                  <FormControl required color="textColor">
                    <FormLabel
                      sx={{ fontSize: 18, color: "#008000" }}
                      id="radio"
                    >
                      Like to learn overall wellness tips?
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="radio"
                      name="tips"
                      value={isTips}
                      onChange={(e) => {
                        setIsTips(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio color="textColor" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio color="textColor" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container padding={1} spacing={2}>
                <Grid item xs={8} paddingRight={2}>
                  <CustomField
                    variant="outlined"
                    multiline
                    rows={3}
                    size="large"
                    fullWidth
                    label="Comments"
                    placeholder="Tell us about injury and medications if you mentioned 'yes', also let us know how did you hear about us."
                    value={com}
                    onChange={(e) => setCom(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Box padding={4}>
                <Divider />
              </Box>
              <Grid container padding={1} spacing={2}>
                <Grid item xs={8} paddingRight={2}>
                  <FormControl required color="textColor">
                    <FormLabel sx={{ fontSize: 18 }} id="radio">
                      Disclaimer: The contents of this workshop, such as the
                      Yoga knowledge, Yoga practice, Meditation, Life style/Diet
                      style Information, Graphics, Text, Material are not
                      intended to be a substitute for professional medical
                      advice, diagnosis, or treatment. Always seek the advice of
                      your physician or other qualified health providers for any
                      questions you may have regarding a medical condition.
                    </FormLabel>
                    <FormControlLabel
                      value="Yes"
                      control={
                        <Checkbox
                          size="large"
                          color="textColor"
                          checked={checked}
                          onChange={(e) => {
                            setChecked(e.target.checked);
                          }}
                        />
                      }
                      label="Yes I agree"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Box align="center">
                <Button
                  size="large"
                  // href="/student/sign-in"
                  disabled={!checked}
                  color="secondary"
                  variant="contained"
                  onClick={async (e) => {
                    await regStudent({
                      variables: {
                        data: {
                          name: name,
                          email: email,
                          gender: gender,
                          currency: curr,
                          age: ageRange,
                          phone: phone,
                          country: count,
                          timeZone: timezone,
                          occupation: occ,
                          primary: r1,
                          secondary: r2,
                          activity:
                            isActive === "Yes"
                              ? isActive + "--" + activeHrs
                              : isActive,
                          isInjury: isInjury === "Yes" ? true : false,
                          isTips: isTips === "Yes" ? true : false,
                          isMedicalCondition:
                            isMedicalCondition === "Yes" ? true : false,
                          comments: com,
                        },
                      },
                    })
                      .then(() => {
                        navigate(`/student/sign-in`);
                      })
                      .catch((error) => {
                        console.log(JSON.stringify(error, null, 2));
                      });
                  }}
                >
                  <Typography
                    color="primary"
                    fontWeight="bold"
                    sx={{ fontSize: 15 }}
                  >
                    Register
                  </Typography>
                </Button>
              </Box>
            </Paper>
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default withRoot(StudentRegister);
