import * as React from "react";
import Button from "../custom/Button";
import Typography from "../custom/Typography";
import ProductHeroLayout from "./LandingFrontPageLayout";

const backgroundImage =
  "https://images.unsplash.com/photo-1532798442725-41036acc7489?auto=format&fit=crop&w=400";

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: "#7fc7d9", // Average color of the background image.
        backgroundPosition: "center",
      }}
    >
      <img
        style={{ display: "none" }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Typography
        color="inherit"
        align="center"
        sx={{
          fontSize: 40,
          textTransform: "uppercase",
          fontFamily: "DM Serif Text",
        }}
        // variant="h2"
        marked="center"
      >
        Dive into the world of YOGA
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
      >
        Enroll yourselves to our workshops and yoga classes
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        href={
          localStorage.getItem("user") === "Student"
            ? "/student/register"
            : "/student/sign-in"
        }
        sx={{ minWidth: 200 }}
      >
        Register
      </Button>
      <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
        Relax. Rediscover. Reinvent.
      </Typography>
    </ProductHeroLayout>
  );
}
