import React from "react";
import AppAppBar from "../views/AppAppBar";
import AppFooter from "../views/AppFooter";
import withRoot from "../withRoot";
import Button from "../custom/Button";
import Typography from "../custom/Typography";
import pic from "../assets/knowledge.jpg";
import image from "../assets/productCurvyLines.png";
import ProductHeroLayout from "../views/LandingFrontPageLayout";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Stack,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
const ImageComponent = () => {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${pic})`,
        backgroundColor: "#7fc7d9", // Average color of the background image.
        backgroundPosition: "center",
      }}
    >
      <img style={{ display: "none" }} src={pic} alt="increase priority" />
      <Typography
        color="#ffffff"
        align="center"
        sx={{ fontSize: 50, fontFamily: "revert" }}
        marked="center"
      >
        Explore the World of Yoga and Transform Your Life
      </Typography>
      <Typography
        color="inherit"
        align="center"
        marked="center"
        variant="h5"
        sx={{
          mb: 4,
          mt: { xs: 4, sm: 10 },
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        Unleash the Magic of Yoga and Awaken Your True Potential
      </Typography>
      <Box>
        <Button
          color="secondary"
          variant="outlined"
          size="large"
          component="a"
          sx={{ minWidth: 200 }}
        >
          More Below
        </Button>
      </Box>
    </ProductHeroLayout>
  );
};

const Knowledge = () => {
  const [expanded, setExpanded] = React.useState(false);
  const [color, setColor] = React.useState("#FFF9ED");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <React.Fragment>
      <AppAppBar />
      <ImageComponent />
      <Box
        sx={{
          display: "flex",
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container
          component="section"
          sx={{
            flexWrap: "wrap",
            mt: 8,
            mb: 4,
          }}
        >
          <Accordion
            fullWidth
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            color="#FFF9ED"
            sx={{ backgroundColor: "#FFF9ED" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ backgroundColor: "primary" }}
              align="center"
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  width: "100%",
                  fontSize: "28px",
                  flexShrink: 0,
                  color: "#008000",
                }}
              >
                What is Yoga?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container alignContent={"space-evenly"} spacing={0.5}>
                <Grid item xs={7}>
                  <Box>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "Yoga is the practice of taking an appointment with oneself. Yoga practitioners focus on understanding our own faculties such as body, mind, breath, memory, and intellect. We cultivate awareness of our bodies, thoughts, emotions, and environments. Love is an integral part of yoga, as it is the substance of our existence, not merely an emotion."
                      }
                    </Typography>
                  </Box>
                  <Box paddingTop={1}>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "Yoga entails taking full responsibility for our overall well-being, encompassing the physical, emotional, mental, and spiritual aspects of our lives. It is the realization that we are interconnected with the universe and that everyone is a unique masterpiece. "
                      }
                    </Typography>
                  </Box>
                  <Box paddingTop={1}>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "Yoga encompasses various elements like postures, breathwork, meditation, yoga philosophy, ethics, cleansing processes, concentration, and withdrawal of senses. By combining postures, breathwork, and deep relaxation, yoga offers a holistic approach to health and well-being. Regular practice of yoga lays the foundation for vibrant health. It aims to calm the mind, improve quality of life, and increase the health span."
                      }{" "}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={5}></Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            sx={{ backgroundColor: "#E4F7E5" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              align="center"
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  width: "100%",
                  fontSize: "28px",
                  flexShrink: 0,
                  color: "#008000",
                }}
              >
                What are the paths of Yoga?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={0.5}>
                <Grid item xs={7}>
                  <Box>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "The Paths of Yoga offer different approaches to uniting the mind, body, and spirit."
                      }
                    </Typography>
                  </Box>
                  <Box paddingTop={1}>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "They include Bhakti Yoga, which focuses on reflecting God's love through devotion. Karma Yoga emphasizes selfless service to humanity. Gyana Yoga involves studying scriptures, listening to spiritual masters, and applying knowledge in daily life."
                      }
                    </Typography>
                  </Box>
                  <Box paddingTop={1}>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "Raja Yoga places greater emphasis on meditation and living without struggle. Hatha Yoga prioritizes physical efforts like Pranayama (breathwork) and Asanas (postures) for overall well-being. "
                      }
                    </Typography>
                  </Box>
                  <Box paddingTop={1}>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "Ashtanga Yoga, as prescribed by Maharishi Patanjali, follows an eight-limbed methodology for purifying the body, mind, and spirit. These Paths of Yoga provide diverse avenues to realize our full human potential and integrate different aspects of our being."
                      }{" "}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={5}></Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            sx={{ backgroundColor: color }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
              align="center"
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  width: "100%",
                  fontSize: "28px",
                  flexShrink: 0,
                  color: "#008000",
                }}
              >
                What is Prana?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={0.5}>
                <Grid item xs={7}>
                  <Typography
                    variant={"h5"}
                    sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                  >
                    Prana is the term used to describe the subtle life force
                    energy present within us. It is akin to the freshness found
                    in produce at a vegetable or fruit market, which diminishes
                    over time. The presence of prana is what gives us life,
                    vitality, and the ability to perceive and interact with the
                    world through our senses and actions. When someone passes
                    away, we refer to it as "Prana Gaya" or "Pranam Poiduchu" in
                    Tamil, indicating that their life force has departed from
                    the body. Prana is a concept known across various ancient
                    cultures, although referred to by different names such as
                    "Ra" in Egyptian, "Chi" in Chinese, "Ki" in Japanese, and
                    "Pneuma" in Greek tradition. It is the essential energy that
                    animates and sustains life.
                  </Typography>
                </Grid>
                <Grid item xs={5}></Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            sx={{ backgroundColor: "#E4F7E5" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              align="center"
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  width: "100%",
                  fontSize: "28px",
                  flexShrink: 0,
                  color: "#008000",
                }}
              >
                Why is Yoga so important?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={0.5}>
                <Grid item xs={7}>
                  <Box>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "Yoga is of utmost importance because our bodies are designed for movement and physical activity. Leading a sedentary lifestyle, characterized by prolonged periods of sitting and inactivity, has been linked to an increased risk of various health issues such as heart diseases, diabetes, insomnia, and premature mortality. This sedentary epidemic has led to the phrase 'sitting is the new smoking,' highlighting the detrimental effects of a lack of physical exercise."
                      }
                    </Typography>
                  </Box>
                  <Box paddingTop={1}>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "By engaging in yoga, we can effectively care for our mind and body. Yoga serves as a low-impact exercise that not only strengthens our muscles and joints but also benefits our internal organs. It offers a holistic approach to physical well-being by detoxifying the body and improving the cardiovascular system. Additionally, yoga promotes flexibility in the spine, joints, and muscles."
                      }
                    </Typography>
                  </Box>
                  <Box paddingTop={1}>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "The wisdom embedded in yoga helps us maintain good health and preserve wealth by reducing the frequency of sickness and hospitalization. Sri Sri aptly expresses the dilemma faced by many individuals who lose half their health in the pursuit of wealth and then spend half their wealth trying to regain their health. Yoga offers a way to break free from this cycle by prioritizing holistic health and well-being."
                      }
                    </Typography>
                  </Box>
                  <Box paddingTop={1}>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "By practicing yoga, we take care of our own health and contribute to the well-being of our loved ones. Health is indeed the greatest gift we can offer to those we care about. Through yoga, we can cultivate physical vitality, mental clarity, and emotional balance, enabling us to lead fulfilling lives and create positive impacts on ourselves and those around us."
                      }{" "}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={5}></Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
            sx={{ backgroundColor: color }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
              align="center"
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  width: "100%",
                  fontSize: "28px",
                  flexShrink: 0,
                  color: "#008000",
                }}
              >
                The Profounder of Yoga
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={0.5}>
                <Grid item xs={7}>
                  <Box>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "Maharishi Patanjali, hailed as the revered architect of Yoga, graced humanity with his profound wisdom centuries ago. His magnum opus, the Patanjali Yoga Sutras, encapsulates the essence of Yoga through a collection of aphorisms. Within this timeless masterpiece, Patanjali unveils an eight-limbed path that illuminates holistic well-being and purification of body, mind, and spirit. Known as Ashtanga Yoga, this multidimensional approach allows the simultaneous practice of all eight limbs."
                      }
                    </Typography>
                  </Box>
                  <Box paddingTop={1}>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "The Patanjali Yoga Sutras comprise 195 Sanskrit sutras, thoughtfully divided into four chapters: Samadhi Pada, Sadhana Pada, Vibhuti Pada, and Kaivalya Pada. Samadhi Pada, the first chapter, gracefully expounds on the practices of meditation to still the mind and attain profound tranquility."
                      }
                    </Typography>
                  </Box>
                  <Box paddingTop={1}>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "Sadhana Pada, the second chapter, delves into the disciplined Path of Yoga. Here, Patanjali elucidates two distinct systems: Kriya Yoga and Ashtanga Yoga, the renowned Eight-Limbed Yoga."
                      }
                    </Typography>
                  </Box>
                  <Box paddingTop={1}>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "Vibhuti Pada, the third chapter, explores the realm of power and manifestation, unveiling the transformative potential of Samyama."
                      }{" "}
                    </Typography>
                  </Box>
                  <Box paddingTop={1}>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "Kaivalya Pada, the final chapter, reveals the art of maintaining an undisturbed and serene mind. It serves as a guide to liberation and ultimate freedom."
                      }{" "}
                    </Typography>
                  </Box>
                  <Box paddingTop={1}>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "Ashtanga Yoga, the Yoga of Eight Limbs, lays the foundation for a comprehensive and harmonious practice:"
                      }{" "}
                    </Typography>
                  </Box>

                  <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={1}
                    paddingTop={1}
                    paddingLeft={2}
                  >
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      1. Yama – The restraints or ethical principles of
                      behavior, encompassing:
                      <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                        paddingTop={1}
                        paddingLeft={7}
                      >
                        <Typography
                          variant={"h5"}
                          sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                        >
                          1.1 Ahimsa (Non-violence)
                        </Typography>
                        <Typography
                          variant={"h5"}
                          sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                        >
                          1.2 Satya (Truthfulness)
                        </Typography>
                        <Typography
                          variant={"h5"}
                          sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                        >
                          1.3 Asteya (Non-stealing)
                        </Typography>
                        <Typography
                          variant={"h5"}
                          sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                        >
                          1.4 Brahmacharya (Chastity)
                        </Typography>
                        <Typography
                          variant={"h5"}
                          sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                        >
                          1.5 Aparigraha (Non-possession)
                        </Typography>
                      </Stack>
                    </Typography>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      2. Niyama – The observances, embracing:
                      <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                        paddingTop={1}
                        paddingLeft={7}
                      >
                        <Typography
                          variant={"h5"}
                          sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                        >
                          2.1 Saucha (Cleanliness)
                        </Typography>
                        <Typography
                          variant={"h5"}
                          sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                        >
                          2.2 Santosha (Contentment)
                        </Typography>
                        <Typography
                          variant={"h5"}
                          sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                        >
                          2.3 Tapas (Austerity)
                        </Typography>
                        <Typography
                          variant={"h5"}
                          sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                        >
                          2.4 Svadhyaya (Self-study)
                        </Typography>
                        <Typography
                          variant={"h5"}
                          sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                        >
                          2.5 Isvara Pranidhana (Devotion to the divine)
                        </Typography>
                      </Stack>
                    </Typography>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      3. Asana – The physical postures that promote stability
                      and comfort.
                    </Typography>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      4. Pranayama – The regulation and control of breath,
                      harnessing the power of prana.
                    </Typography>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      5. Pratyahara – The withdrawal of the senses, directing
                      awareness inward.
                    </Typography>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      6. Dharana – The cultivation of unwavering focus and
                      concentration.
                    </Typography>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      7. Dhyana – The practice of meditation, diving into the
                      depths of pure awareness.
                    </Typography>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      8. Samadhi – The pinnacle of absorption and union,
                      transcending the boundaries of the self.
                    </Typography>
                  </Stack>
                  <Box paddingTop={1}>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "Within this sacred framework of the eight limbs, Patanjali's teachings illuminate the path towards self-realization and profound inner transformation. Embrace the wisdom of Ashtanga Yoga and embark on a journey of holistic growth, harmony, and unity."
                      }{" "}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={5}></Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
            sx={{ backgroundColor: "#E4F7E5" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              align="center"
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  width: "100%",
                  fontSize: "28px",
                  flexShrink: 0,
                  color: "#008000",
                }}
              >
                What is Pranayama?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={0.5}>
                <Grid item xs={7}>
                  <Box>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "Pranayama, the art of breath regulation, is a profound practice that combines the essence of 'Prana' - life force energy, with 'Yama', which signifies the act of skilfully regulating or warehousing this vital energy. Engaging in pranayama offers a plethora of benefits through various breathing exercises. Here are some notable advantages:"
                      }
                    </Typography>
                  </Box>
                  <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={1}
                    paddingTop={1}
                    paddingLeft={2}
                  >
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      1. Diaphragmatic Breathing: By practicing pranayama, one
                      can activate all three lobes of the lungs, facilitating a
                      more complete and efficient utilization of our respiratory
                      system.
                    </Typography>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      2. Nitric Oxide Production: Pranayama stimulates the
                      production of nitric oxide within the body, which in turn
                      leads to the dilation of blood vessels and induces a state
                      of deep relaxation, promoting overall well-being.
                    </Typography>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      3. Increased Lung Capacity: Through pranayama, the alveoli
                      in the lungs are opened, enhancing lung capacity, and
                      enabling a greater intake of Oxygen, essential for vital
                      bodily functions.{" "}
                    </Typography>
                  </Stack>
                  <Box paddingTop={1}>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "By engaging in regular pranayama practices, one can experience these benefits, thereby fostering a harmonious union between the breath, body, and mind. Pranayama serves as a powerful tool to optimize our respiratory health and enhance our overall well-being."
                      }
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={5}></Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
            sx={{ backgroundColor: color }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
              align="center"
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  width: "100%",
                  fontSize: "28px",
                  flexShrink: 0,
                  color: "#008000",
                }}
              >
                What is Asana?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={0.5}>
                <Grid item xs={7}>
                  <Box>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "Asana, derived from the Sanskrit word meaning 'seat' or 'posture,' embodies the art of finding a steady and comfortable position. The practice of asanas, or yoga exercises, offers a myriad of benefits for our well-being."
                      }
                    </Typography>
                  </Box>
                  <Box paddingTop={1}>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "Yoga postures encompass a diverse range, including standing, sitting, and lying down positions. Engaging in standing postures improves our range of movement, enhances the flexibility of the spine, and cultivates overall strength."
                      }
                    </Typography>
                  </Box>
                  <Box paddingTop={1}>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "Sitting postures, on the other hand, provide a gentle massage to our internal organs and muscles, facilitating detoxification and revitalization of these vital systems."
                      }
                    </Typography>
                  </Box>
                  <Box paddingTop={1}>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "Lying down postures play a pivotal role in strengthening our abdominal and back muscles, leading to improved digestion and enhanced core stability."
                      }
                    </Typography>
                  </Box>
                  <Box paddingTop={1}>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "By embracing a regular practice of asanas, we embark on a transformative journey that harmonizes our physical body with our mental and spiritual well-being. Each posture cultivates balance, strength, and flexibility, nurturing a state of holistic health and vitality."
                      }{" "}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={5}></Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
            sx={{ backgroundColor: "#E4F7E5" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              align="center"
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  width: "100%",
                  fontSize: "28px",
                  flexShrink: 0,
                  color: "#008000",
                }}
              >
                What is Meditation?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={0.5}>
                <Grid item xs={7}>
                  <Box>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "Meditation, also known as Dhayana, holds the transformative power to grant us profound rest within a remarkably short span of time. By anchoring our mind in the present moment, meditation allows us to relinquish attachment to the past and experience deep serenity and stillness."
                      }
                    </Typography>
                  </Box>
                  <Box paddingTop={1}>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "The practice of meditation yields an array of remarkable benefits, including but not limited to:"
                      }
                    </Typography>
                  </Box>
                  <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={1}
                    paddingTop={1}
                    paddingLeft={2}
                  >
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      1. Increased Grey Matter: Regular meditation stimulates
                      the growth of grey matter in the brain, facilitating
                      enhanced cognitive function, memory, and overall mental
                      well-being.
                    </Typography>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      2. Induction of Happy Hormones: Engaging in meditation
                      triggers the release of blissful hormones such as
                      endorphins, dopamine, serotonin, and oxytocin, fostering a
                      sense of joy, contentment, and emotional balance.
                    </Typography>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      3. Reduction of Stress Hormones: Meditation effectively
                      reduces the levels of stress hormones, particularly
                      cortisol, thereby promoting a state of calmness,
                      relaxation, and inner peace.
                    </Typography>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      4. Blood Pressure Regulation: The practice of meditation
                      has been shown to have a positive impact on blood
                      pressure, helping to regulate and maintain healthy levels
                    </Typography>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      5. Anti-Aging Effects: Regular meditation has been
                      associated with anti-aging benefits, as it supports
                      cellular health, reduces oxidative stress, and enhances
                      overall vitality and resilience.
                    </Typography>
                  </Stack>
                  <Box paddingTop={1}>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "Through the profound practice of meditation, we embark on a journey of self-discovery, accessing a realm of inner tranquility, heightened self-awareness, and spiritual growth. Its remarkable effects ripple throughout our being, nurturing our physical, mental, and emotional well-being, and enriching our lives with a profound sense of harmony and fulfilment."
                      }
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={5}></Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel9"}
            onChange={handleChange("panel9")}
            sx={{ backgroundColor: color }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
              align="center"
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  width: "100%",
                  fontSize: "28px",
                  flexShrink: 0,
                  color: "#008000",
                }}
              >
                Five Pillars of Healthy Living
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={0.5}>
                <Grid item xs={7}>
                  <Box>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "Within the realm of healthy living, there exist five pillars that serve as the foundation for holistic well-being. These pillars of yogic knowledge offer profound guidance for the mind and body. Let us explore them in all their splendor:"
                      }
                    </Typography>
                  </Box>
                  <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={1}
                    paddingTop={1}
                    paddingLeft={2}
                  >
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      1. Pranayama - The Art of Breathwork: Pranayama, the first
                      pillar, encompasses the mastery of breath. Through
                      deliberate and conscious control of our breath, we unlock
                      a gateway to enhanced vitality, inner harmony, and
                      profound self-awareness.
                    </Typography>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      2. Asana - Physical Postures: The second pillar, asana,
                      invites us to embark on a transformative journey of
                      physical practice. By embracing a wide array of yogic
                      postures, we cultivate strength, flexibility, and balance
                      within our bodies, while simultaneously nurturing deep
                      connections between the mind, body, and spirit.{" "}
                    </Typography>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      3. Deep Relaxation: The third pillar offers solace in the
                      embrace of deep relaxation. By surrendering ourselves to a
                      state of profound rest, we allow our entire being to
                      rejuvenate, heal, and harmonize. This practice replenishes
                      our energy reserves, soothes our nervous system, and
                      ignites a profound sense of peace within.
                    </Typography>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      4. Diet - Nourishment for the Body: The fourth pillar,
                      diet, emphasizes the significance of mindful and
                      nourishing food choices. By embracing a balanced and
                      wholesome diet, rich in nutrient-dense whole foods, we
                      fuel our bodies with the essential elements required for
                      optimal functioning, vibrant health, and longevity.{" "}
                    </Typography>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      5. Lifestyle - Holistic Well-being: The final pillar,
                      lifestyle, encompasses the integration of yogic principles
                      into our daily lives. By adopting conscious and mindful
                      habits, we foster a harmonious and balanced existence.
                      This pillar encompasses aspects such as mindful movement,
                      stress management, mindfulness practices, and cultivating
                      positive relationships with ourselves and others.{" "}
                    </Typography>
                  </Stack>
                  <Box paddingTop={1}>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "Embracing these five pillars of healthy living, under the benevolent guidance of yogic wisdom, we unlock the keys to a vibrant, harmonious, and fulfilling life. By honoring our mind, body, and spirit through the cultivation of breath, physical practice, relaxation, nourishment, and mindful lifestyle choices, we pave the way towards holistic well-being and a profound sense of vitality."
                      }
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={5}></Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel10"}
            onChange={handleChange("panel10")}
            sx={{ backgroundColor: "#E4F7E5" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              align="center"
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  width: "100%",
                  fontSize: "28px",
                  flexShrink: 0,
                  color: "#008000",
                }}
              >
                Our Worshops and Classes
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={0.5}>
                <Grid item xs={7}>
                  <Box>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "Prithivi Grow Care presents a realm of transformative learning through two remarkable offerings: Yoga Workshops and Yoga Classes. These meticulously curated experiences cater to individuals seeking to deepen their understanding and practice of yoga."
                      }
                    </Typography>
                  </Box>
                  <Box paddingTop={1}>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "The Yoga Workshops are thoughtfully designed to accommodate practitioners at various stages of their journey, offering three progressive levels: Basic, Intermediate, and Advanced. These workshops serve as a comprehensive immersion into the realms of yoga, incorporating a rich tapestry of teachings, including Yoga knowledge, Pranayama, Asana, Meditation, Mudra, Power Sequences, Lifestyle Information, and Diet Guidance. Immerse yourself in these workshops and unlock the profound potential of your yoga practice."
                      }
                    </Typography>
                  </Box>
                  <Box paddingTop={1}>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "Complementing the workshops, the Yoga Follow-up Classes extend an opportunity to sustain and refine your practice. Like the workshops, the follow-up classes are offered in three levels: Basic, Intermediate, and Advanced. These classes delve deeper into the realms of Pranayama, Asana, Meditation, Mudra, and Power Sequences (PAM), guiding you towards a more nuanced and expansive experience of yoga."
                      }
                    </Typography>
                  </Box>
                  <Box paddingTop={1}>
                    <Typography
                      variant={"h5"}
                      sx={{ fontSize: 18, fontFamily: "Oxygen" }}
                    >
                      {
                        "Embark on this transformative journey with Prithivi Grow Care, where yoga knowledge and practice converge to illuminate the path towards holistic well-being, self-discovery, and inner transformation. Step into these workshops and classes and allow the profound wisdom of yoga to unfold within you, nurturing your mind, body, and spirit. "
                      }
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={5}></Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Container>
      </Box>
      <AppFooter />
    </React.Fragment>
  );
};

export default withRoot(Knowledge);
