import React, { useState } from "react";
import AdminAppBar from "../../views/AdminAppBar";
import withRoot from "../../withRoot";
import { useMutation, useQuery } from "@apollo/client";
import {
  Admin_Approve,
  Admin_Courses_Fetch,
  Admin_Reject,
  Admin_Edit,
} from "../../schema/admin";
import {
  StyledCell,
  StyledTableCell,
  StyledTableRow,
} from "../teacher/MyCourse";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Stack,
  useMediaQuery,
  InputAdornment,
} from "@mui/material";
import { Edit, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import Typography from "../../custom/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ButtoN from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { CustomComplete, CustomField, days } from "../teacher/CreateCourse";
import { useTheme } from "@mui/material/styles";

export const UpdateModal = (props) => {
  const [editf] = useMutation(Admin_Edit, {
    refetchQueries: [Admin_Courses_Fetch],
  });
  const [name, setName] = useState(props?.class?.name);
  const [desc, setDesc] = useState(props?.class?.description);
  const [fees, setFees] = useState(props?.class?.fees);
  const [days_, setDays] = useState(props?.class?.days);
  const [time, setTime] = useState(props?.class?.startTime);
  const [link, setLink] = useState(props?.class?.link);
  const [pass, setPass] = useState(props?.class?.passCode);
  const [mid, setMid] = useState(props?.class?.meetingId);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth
      fullScreen={fullScreen}
      maxWidth={"md"}
      scroll="paper"
    >
      <DialogTitle align="center" sx={{ color: "secondary.dark" }}>
        Update Class - {props?.class?.name}
      </DialogTitle>
      <DialogContent dividers={"paper"}>
        <DialogContentText>
          Change and update the fields of the Class - {props?.class?.name}
        </DialogContentText>

        <Grid paddingTop={1} container spacing={1}>
          <Grid item xs={4} sm={4}>
            <CustomField
              variant="outlined"
              margin="normal"
              id="name"
              label="Class Title"
              defaultValue={props?.class?.name}
              onChange={(e) => {
                // if (name === "") {
                //   setName(props?.class?.name);
                // }
                setName(e?.target?.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <CustomField
              variant="outlined"
              margin="normal"
              id="desc"
              label="Class Description"
              defaultValue={props?.class?.description}
              onChange={(e) => {
                setDesc(e?.target?.value);
              }}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid paddingTop={1} container spacing={1}>
          <Grid item xs={4} sm={4}>
            <CustomField
              variant="outlined"
              margin="normal"
              id="fees"
              type="number"
              label="Class Fess"
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    sx={{ color: "secondary.daark" }}
                    position="start"
                  >
                    USD
                  </InputAdornment>
                ),
              }}
              defaultValue={props?.class?.fees}
              onChange={(e) => {
                setFees(e?.target?.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <CustomComplete
              disablePortal
              options={days}
              defaultValue={props?.class?.days}
              onInputChange={(event, newInputValue) => {
                setDays(newInputValue);
              }}
              renderInput={(params) => (
                <Box paddingTop={2}>
                  <CustomField
                    {...params}
                    variant="outlined"
                    label="Days"
                    onChange={(e) => {
                      setDays(e.target.value);
                    }}
                  />
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <LocalizationProvider padding="none" dateAdapter={AdapterDayjs}>
              <DemoContainer padding="none" components={["TimePicker"]}>
                <TimePicker
                  label="Start Time"
                  sx={{ "& input": { color: "secondary.dark" } }}
                  // value={value[index]}
                  defaultValue={props?.class?.startTime}
                  onChange={
                    (event) => setTime(event)

                  }
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid paddingTop={1} container spacing={1}>
          <Grid item xs={5} sm={5}>
            <CustomField
              variant="outlined"
              margin="normal"
              id="desc"
              label="Class Link"
              defaultValue={props?.class?.link}
              onChange={(e) => {
                setLink(e?.target?.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <CustomField
              variant="outlined"
              margin="normal"
              id="mid"
              label="Meeting ID"
              defaultValue={props?.class?.meetingId}
              onChange={(e) => {
                setMid(e?.target?.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={3} sm={3}>
            <CustomField
              variant="outlined"
              margin="normal"
              id="pass"
              label="Passcode"
              defaultValue={props?.class?.passCode}
              onChange={(e) => {
                // if (name === "") {
                //   setName(props?.class?.name);
                // }
                setPass(e?.target?.value);
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="error" variant="outlined" onClick={props.handleClose}>
          Cancel
        </Button>
        <Button
          color="textColor"
          variant="contained"
          // onClick={props.handleClose}
          onClick={async (e) => {
            e.preventDefault();
            const arr = new Date(time.$d.getTime()).toString().split(" ");
            await editf({
              variables: {
                data: {
                  id: parseInt(props.id),
                  days: days_,
                  description: desc,
                  fees: parseFloat(fees),
                  link: link,
                  name: name,
                  passCode: pass,
                  meetingId: mid,
                  startTime: arr[4] + " " + arr[5],
                },
              },
            });
            window.location.reload();
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  const handleClickOpen = () => {
    setOpen1(true);
  };

  const handleClose = () => {
    setOpen1(false);
  };
  return (
    <React.Fragment>
      <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row.title}
        </StyledTableCell>
        <StyledTableCell align="center">{row.description}</StyledTableCell>
        <StyledTableCell align="center">{row.teacher.name}</StyledTableCell>
        <StyledTableCell align="center"> {row.teacher.email}</StyledTableCell>
        <StyledTableCell align="center">
          {new Date(parseInt(row.createdAt)).toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          })}
        </StyledTableCell>
        <StyledTableCell align="center">
          <Stack
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={1}
          >
            <Button
              variant="contained"
              onClick={async () => {
                await props
                  .approvef({
                    variables: {
                      data: {
                        id: row.id,
                      },
                    },
                  })
                  .then((res) => {
                    props.refetch();
                    return res;
                  });
              }}
              color="textColor"
            >
              Approve
            </Button>

            <Button
              variant="outlined"
              onClick={async () => {
                await props
                  .rejectf({
                    variables: {
                      data: {
                        id: row.id,
                      },
                    },
                  })
                  .then((res) => {
                    props.refetch();
                    return res;
                  });
              }}
              color="error"
            >
              Reject
            </Button>
          </Stack>
        </StyledTableCell>
        <StyledTableCell align="center">
          {row.status === 1 ? (
            <Typography sx={{ color: "#FF8F00" }}>Pending</Typography>
          ) : (
            <>
              {row.status === 2 ? (
                <Typography color={"#008000"}>Approved</Typography>
              ) : (
                <Typography color={"red"}>Rejected</Typography>
              )}
            </>
          )}
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Backup Instructor Details
              </Typography>
              <Table size="large" aria-label="backUp">
                <TableHead>
                  <StyledTableRow>
                    <StyledCell>Name</StyledCell>
                    <StyledCell>E-mail</StyledCell>
                    <StyledCell align="right">Status</StyledCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow key={row.id}>
                    <StyledTableCell component="th" scope="row">
                      {row.backup_teacher?.name}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.backup_teacher?.email}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.backup_status === 1 ? (
                        <Typography sx={{ color: "#FF8F00" }}>
                          Pending
                        </Typography>
                      ) : (
                        <>
                          {row.backup_status === 2 ? (
                            <Typography color={"#008000"}>Approved</Typography>
                          ) : (
                            <Typography color={"red"}>Rejected</Typography>
                          )}
                        </>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Classes
              </Typography>
              <Table size="large" aria-label="course">
                <TableHead>
                  <StyledTableRow>
                    <StyledCell>Class Title</StyledCell>
                    <StyledCell align="center">Description</StyledCell>
                    <StyledCell align="center">Fees</StyledCell>
                    <StyledCell align="center">Day</StyledCell>
                    <StyledCell align="center">Time</StyledCell>
                    <StyledCell align="center">Link</StyledCell>
                    <StyledCell align="center">Meeting Id</StyledCell>
                    <StyledCell align="center">Passcode</StyledCell>
                    <StyledCell align="center">Strength</StyledCell>
                    <StyledCell align="center">Update</StyledCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {row?.classes?.map((course) => (
                    <StyledTableRow key={course?.id}>
                      <StyledTableCell component="th" scope="row">
                        {course?.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {course?.description}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {course?.currency} {course?.fees}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {course?.days}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {course?.startTime}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {course?.link}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {course?.meetingId}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {course?.passCode}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {course?.currReg}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button color="textColor" onClick={handleClickOpen}>
                          <Edit />
                        </Button>
                        <UpdateModal
                          open={open1}
                          handleClose={handleClose}
                          class={course}
                          refetch={props.refetch}
                          id={course.id}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledCell>
      </StyledTableRow>
    </React.Fragment>
  );
}
const AdminCourse = () => {
  const {
    data: coursedata,
    loading: courseloading,
    error: courseerror,
    refetch: courserefetch,
  } = useQuery(Admin_Courses_Fetch);
  const [approvef] = useMutation(Admin_Approve);
  const [rejectf] = useMutation(Admin_Reject);
  const [opeN, setOpeN] = React.useState(false);
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpeN(true);
  };

  const handleClose = () => {
    setOpeN(false);
  };

  const handleOK = () => {
    setOpeN(false);
    navigate("/");
    localStorage.clear();
  };
  if (courseloading) {
    return <pre>Loading</pre>;
  }
  // if (courseerror) {
  //   return <pre>&nbsp;{courseerror?.message}</pre>;
  // }
  if (courseerror) {
    if (!opeN) {
      handleClickOpen();
    }
    return (
      <div>
        <Dialog
          open={opeN}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Error Encountered"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Sorry! We encountered the following error. Click{" "}
              <strong>OK</strong> to go back
              <br />
              Reason: {courseerror?.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtoN color="error" onClick={handleClose}>
              Cancel
            </ButtoN>
            <ButtoN color="success" onClick={handleOK} autoFocus>
              OK
            </ButtoN>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  return (
    <React.Fragment>
      <AdminAppBar />
      <Box align="center" padding={1}>
        <Typography variant={"h4"} color="textColor">
          Instructor Courses
        </Typography>
      </Box>
      <Box
        margin={2.5}
        sx={{
          display: "flex",
          mt: 2,
          mb: 4,
        }}
      >
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="collapsible table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell />
                <StyledTableCell>Course Title</StyledTableCell>
                <StyledTableCell align="center">Description</StyledTableCell>
                <StyledTableCell align="center">
                  Instructor Name
                </StyledTableCell>
                <StyledTableCell align="center">Email</StyledTableCell>
                <StyledTableCell align="center">Created</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {coursedata?.fetchAdminCourses?.map((row) => (
                <Row
                  key={row.id}
                  row={row}
                  approvef={approvef}
                  rejectf={rejectf}
                  refetch={courserefetch}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </React.Fragment>
  );
};

export default withRoot(AdminCourse);
