export const timeZones = [
  {
    Code: "AF",
    Name: "Afghanistan",
    TimeZone: "Asia/Kabul",
    Offset: "UTC +04:30",
  },
  {
    Code: "AL",
    Name: "Albania",
    TimeZone: "Europe/Tirane",
    Offset: "UTC +02:00",
  },
  {
    Code: "DZ",
    Name: "Algeria",
    TimeZone: "Africa/Algiers",
    Offset: "UTC +01:00",
  },
  {
    Code: "AS",
    Name: "American Samoa",
    TimeZone: "Pacific/Pago_Pago",
    Offset: "UTC -11:00",
  },
  {
    Code: "AD",
    Name: "Andorra",
    TimeZone: "Europe/Andorra",
    Offset: "UTC +02:00",
  },
  {
    Code: "AO",
    Name: "Angola",
    TimeZone: "Africa/Luanda",
    Offset: "UTC +01:00",
  },
  {
    Code: "AI",
    Name: "Anguilla",
    TimeZone: "America/Anguilla",
    Offset: "UTC -04:00",
  },
  {
    Code: "AQ",
    Name: "Antarctica",
    TimeZone: "Antarctica/Casey",
    Offset: "UTC +11:00",
  },
  {
    Code: "AQ",
    Name: "Antarctica",
    TimeZone: "Antarctica/Davis",
    Offset: "UTC +07:00",
  },
  {
    Code: "AQ",
    Name: "Antarctica",
    TimeZone: "Antarctica/DumontDUrville",
    Offset: "UTC +10:00",
  },
  {
    Code: "AQ",
    Name: "Antarctica",
    TimeZone: "Antarctica/Mawson",
    Offset: "UTC +05:00",
  },
  {
    Code: "AQ",
    Name: "Antarctica",
    TimeZone: "Antarctica/McMurdo",
    Offset: "UTC +12:00",
  },
  {
    Code: "AQ",
    Name: "Antarctica",
    TimeZone: "Antarctica/Palmer",
    Offset: "UTC -03:00",
  },
  {
    Code: "AQ",
    Name: "Antarctica",
    TimeZone: "Antarctica/Rothera",
    Offset: "UTC -03:00",
  },
  {
    Code: "AQ",
    Name: "Antarctica",
    TimeZone: "Antarctica/Syowa",
    Offset: "UTC +03:00",
  },
  {
    Code: "AQ",
    Name: "Antarctica",
    TimeZone: "Antarctica/Troll",
    Offset: "UTC +02:00",
  },
  {
    Code: "AQ",
    Name: "Antarctica",
    TimeZone: "Antarctica/Vostok",
    Offset: "UTC +06:00",
  },
  {
    Code: "AG",
    Name: "Antigua and Barbuda",
    TimeZone: "America/Antigua",
    Offset: "UTC -04:00",
  },
  {
    Code: "AR",
    Name: "Argentina",
    TimeZone: "America/Argentina/Buenos_Aires",
    Offset: "UTC -03:00",
  },
  {
    Code: "AR",
    Name: "Argentina",
    TimeZone: "America/Argentina/Catamarca",
    Offset: "UTC -03:00",
  },
  {
    Code: "AR",
    Name: "Argentina",
    TimeZone: "America/Argentina/Cordoba",
    Offset: "UTC -03:00",
  },
  {
    Code: "AR",
    Name: "Argentina",
    TimeZone: "America/Argentina/Jujuy",
    Offset: "UTC -03:00",
  },
  {
    Code: "AR",
    Name: "Argentina",
    TimeZone: "America/Argentina/La_Rioja",
    Offset: "UTC -03:00",
  },
  {
    Code: "AR",
    Name: "Argentina",
    TimeZone: "America/Argentina/Mendoza",
    Offset: "UTC -03:00",
  },
  {
    Code: "AR",
    Name: "Argentina",
    TimeZone: "America/Argentina/Rio_Gallegos",
    Offset: "UTC -03:00",
  },
  {
    Code: "AR",
    Name: "Argentina",
    TimeZone: "America/Argentina/Salta",
    Offset: "UTC -03:00",
  },
  {
    Code: "AR",
    Name: "Argentina",
    TimeZone: "America/Argentina/San_Juan",
    Offset: "UTC -03:00",
  },
  {
    Code: "AR",
    Name: "Argentina",
    TimeZone: "America/Argentina/San_Luis",
    Offset: "UTC -03:00",
  },
  {
    Code: "AR",
    Name: "Argentina",
    TimeZone: "America/Argentina/Tucuman",
    Offset: "UTC -03:00",
  },
  {
    Code: "AR",
    Name: "Argentina",
    TimeZone: "America/Argentina/Ushuaia",
    Offset: "UTC -03:00",
  },
  {
    Code: "AM",
    Name: "Armenia",
    TimeZone: "Asia/Yerevan",
    Offset: "UTC +04:00",
  },
  {
    Code: "AW",
    Name: "Aruba",
    TimeZone: "America/Aruba",
    Offset: "UTC -04:00",
  },
  {
    Code: "AU",
    Name: "Australia",
    TimeZone: "Antarctica/Macquarie",
    Offset: "UTC +10:00",
  },
  {
    Code: "AU",
    Name: "Australia",
    TimeZone: "Australia/Adelaide",
    Offset: "UTC +09:30",
  },
  {
    Code: "AU",
    Name: "Australia",
    TimeZone: "Australia/Brisbane",
    Offset: "UTC +10:00",
  },
  {
    Code: "AU",
    Name: "Australia",
    TimeZone: "Australia/Broken_Hill",
    Offset: "UTC +09:30",
  },
  {
    Code: "AU",
    Name: "Australia",
    TimeZone: "Australia/Darwin",
    Offset: "UTC +09:30",
  },
  {
    Code: "AU",
    Name: "Australia",
    TimeZone: "Australia/Eucla",
    Offset: "UTC +08:45",
  },
  {
    Code: "AU",
    Name: "Australia",
    TimeZone: "Australia/Hobart",
    Offset: "UTC +10:00",
  },
  {
    Code: "AU",
    Name: "Australia",
    TimeZone: "Australia/Lindeman",
    Offset: "UTC +10:00",
  },
  {
    Code: "AU",
    Name: "Australia",
    TimeZone: "Australia/Lord_Howe",
    Offset: "UTC +10:30",
  },
  {
    Code: "AU",
    Name: "Australia",
    TimeZone: "Australia/Melbourne",
    Offset: "UTC +10:00",
  },
  {
    Code: "AU",
    Name: "Australia",
    TimeZone: "Australia/Perth",
    Offset: "UTC +08:00",
  },
  {
    Code: "AU",
    Name: "Australia",
    TimeZone: "Australia/Sydney",
    Offset: "UTC +10:00",
  },
  {
    Code: "AT",
    Name: "Austria",
    TimeZone: "Europe/Vienna",
    Offset: "UTC +02:00",
  },
  {
    Code: "AZ",
    Name: "Azerbaijan",
    TimeZone: "Asia/Baku",
    Offset: "UTC +04:00",
  },
  {
    Code: "BS",
    Name: "Bahamas",
    TimeZone: "America/Nassau",
    Offset: "UTC -04:00",
  },
  {
    Code: "BH",
    Name: "Bahrain",
    TimeZone: "Asia/Bahrain",
    Offset: "UTC +03:00",
  },
  {
    Code: "BD",
    Name: "Bangladesh",
    TimeZone: "Asia/Dhaka",
    Offset: "UTC +06:00",
  },
  {
    Code: "BB",
    Name: "Barbados",
    TimeZone: "America/Barbados",
    Offset: "UTC -04:00",
  },
  {
    Code: "BY",
    Name: "Belarus",
    TimeZone: "Europe/Minsk",
    Offset: "UTC +03:00",
  },
  {
    Code: "BE",
    Name: "Belgium",
    TimeZone: "Europe/Brussels",
    Offset: "UTC +02:00",
  },
  {
    Code: "BZ",
    Name: "Belize",
    TimeZone: "America/Belize",
    Offset: "UTC -06:00",
  },
  {
    Code: "BJ",
    Name: "Benin",
    TimeZone: "Africa/Porto-Novo",
    Offset: "UTC +01:00",
  },
  {
    Code: "BM",
    Name: "Bermuda",
    TimeZone: "Atlantic/Bermuda",
    Offset: "UTC -03:00",
  },
  {
    Code: "BT",
    Name: "Bhutan",
    TimeZone: "Asia/Thimphu",
    Offset: "UTC +06:00",
  },
  {
    Code: "BO",
    Name: "Bolivia, Plurinational State of",
    TimeZone: "America/La_Paz",
    Offset: "UTC -04:00",
  },
  {
    Code: "BQ",
    Name: "Bonaire, Sint Eustatius and Saba",
    TimeZone: "America/Kralendijk",
    Offset: "UTC -04:00",
  },
  {
    Code: "BA",
    Name: "Bosnia and Herzegovina",
    TimeZone: "Europe/Sarajevo",
    Offset: "UTC +02:00",
  },
  {
    Code: "BW",
    Name: "Botswana",
    TimeZone: "Africa/Gaborone",
    Offset: "UTC +02:00",
  },
  {
    Code: "BR",
    Name: "Brazil",
    TimeZone: "America/Araguaina",
    Offset: "UTC -03:00",
  },
  {
    Code: "BR",
    Name: "Brazil",
    TimeZone: "America/Bahia",
    Offset: "UTC -03:00",
  },
  {
    Code: "BR",
    Name: "Brazil",
    TimeZone: "America/Belem",
    Offset: "UTC -03:00",
  },
  {
    Code: "BR",
    Name: "Brazil",
    TimeZone: "America/Boa_Vista",
    Offset: "UTC -04:00",
  },
  {
    Code: "BR",
    Name: "Brazil",
    TimeZone: "America/Campo_Grande",
    Offset: "UTC -04:00",
  },
  {
    Code: "BR",
    Name: "Brazil",
    TimeZone: "America/Cuiaba",
    Offset: "UTC -04:00",
  },
  {
    Code: "BR",
    Name: "Brazil",
    TimeZone: "America/Eirunepe",
    Offset: "UTC -05:00",
  },
  {
    Code: "BR",
    Name: "Brazil",
    TimeZone: "America/Fortaleza",
    Offset: "UTC -03:00",
  },
  {
    Code: "BR",
    Name: "Brazil",
    TimeZone: "America/Maceio",
    Offset: "UTC -03:00",
  },
  {
    Code: "BR",
    Name: "Brazil",
    TimeZone: "America/Manaus",
    Offset: "UTC -04:00",
  },
  {
    Code: "BR",
    Name: "Brazil",
    TimeZone: "America/Noronha",
    Offset: "UTC -02:00",
  },
  {
    Code: "BR",
    Name: "Brazil",
    TimeZone: "America/Porto_Velho",
    Offset: "UTC -04:00",
  },
  {
    Code: "BR",
    Name: "Brazil",
    TimeZone: "America/Recife",
    Offset: "UTC -03:00",
  },
  {
    Code: "BR",
    Name: "Brazil",
    TimeZone: "America/Rio_Branco",
    Offset: "UTC -05:00",
  },
  {
    Code: "BR",
    Name: "Brazil",
    TimeZone: "America/Santarem",
    Offset: "UTC -03:00",
  },
  {
    Code: "BR",
    Name: "Brazil",
    TimeZone: "America/Sao_Paulo",
    Offset: "UTC -03:00",
  },
  {
    Code: "IO",
    Name: "British Indian Ocean Territory",
    TimeZone: "Indian/Chagos",
    Offset: "UTC +06:00",
  },
  {
    Code: "BN",
    Name: "Brunei Darussalam",
    TimeZone: "Asia/Brunei",
    Offset: "UTC +08:00",
  },
  {
    Code: "BG",
    Name: "Bulgaria",
    TimeZone: "Europe/Sofia",
    Offset: "UTC +03:00",
  },
  {
    Code: "BF",
    Name: "Burkina Faso",
    TimeZone: "Africa/Ouagadougou",
    Offset: "UTC",
  },
  {
    Code: "BI",
    Name: "Burundi",
    TimeZone: "Africa/Bujumbura",
    Offset: "UTC +02:00",
  },
  {
    Code: "KH",
    Name: "Cambodia",
    TimeZone: "Asia/Phnom_Penh",
    Offset: "UTC +07:00",
  },
  {
    Code: "CM",
    Name: "Cameroon",
    TimeZone: "Africa/Douala",
    Offset: "UTC +01:00",
  },
  {
    Code: "CA",
    Name: "Canada",
    TimeZone: "America/Atikokan",
    Offset: "UTC -05:00",
  },
  {
    Code: "CA",
    Name: "Canada",
    TimeZone: "America/Blanc-Sablon",
    Offset: "UTC -04:00",
  },
  {
    Code: "CA",
    Name: "Canada",
    TimeZone: "America/Cambridge_Bay",
    Offset: "UTC -06:00",
  },
  {
    Code: "CA",
    Name: "Canada",
    TimeZone: "America/Creston",
    Offset: "UTC -07:00",
  },
  {
    Code: "CA",
    Name: "Canada",
    TimeZone: "America/Dawson",
    Offset: "UTC -07:00",
  },
  {
    Code: "CA",
    Name: "Canada",
    TimeZone: "America/Dawson_Creek",
    Offset: "UTC -07:00",
  },
  {
    Code: "CA",
    Name: "Canada",
    TimeZone: "America/Edmonton",
    Offset: "UTC -06:00",
  },
  {
    Code: "CA",
    Name: "Canada",
    TimeZone: "America/Fort_Nelson",
    Offset: "UTC -07:00",
  },
  {
    Code: "CA",
    Name: "Canada",
    TimeZone: "America/Glace_Bay",
    Offset: "UTC -03:00",
  },
  {
    Code: "CA",
    Name: "Canada",
    TimeZone: "America/Goose_Bay",
    Offset: "UTC -03:00",
  },
  {
    Code: "CA",
    Name: "Canada",
    TimeZone: "America/Halifax",
    Offset: "UTC -03:00",
  },
  {
    Code: "CA",
    Name: "Canada",
    TimeZone: "America/Inuvik",
    Offset: "UTC -06:00",
  },
  {
    Code: "CA",
    Name: "Canada",
    TimeZone: "America/Iqaluit",
    Offset: "UTC -04:00",
  },
  {
    Code: "CA",
    Name: "Canada",
    TimeZone: "America/Moncton",
    Offset: "UTC -03:00",
  },
  {
    Code: "CA",
    Name: "Canada",
    TimeZone: "America/Rankin_Inlet",
    Offset: "UTC -05:00",
  },
  {
    Code: "CA",
    Name: "Canada",
    TimeZone: "America/Regina",
    Offset: "UTC -06:00",
  },
  {
    Code: "CA",
    Name: "Canada",
    TimeZone: "America/Resolute",
    Offset: "UTC -05:00",
  },
  {
    Code: "CA",
    Name: "Canada",
    TimeZone: "America/St_Johns",
    Offset: "UTC -02:30",
  },
  {
    Code: "CA",
    Name: "Canada",
    TimeZone: "America/Swift_Current",
    Offset: "UTC -06:00",
  },
  {
    Code: "CA",
    Name: "Canada",
    TimeZone: "America/Toronto",
    Offset: "UTC -04:00",
  },
  {
    Code: "CA",
    Name: "Canada",
    TimeZone: "America/Vancouver",
    Offset: "UTC -07:00",
  },
  {
    Code: "CA",
    Name: "Canada",
    TimeZone: "America/Whitehorse",
    Offset: "UTC -07:00",
  },
  {
    Code: "CA",
    Name: "Canada",
    TimeZone: "America/Winnipeg",
    Offset: "UTC -05:00",
  },
  {
    Code: "CV",
    Name: "Cape Verde",
    TimeZone: "Atlantic/Cape_Verde",
    Offset: "UTC -01:00",
  },
  {
    Code: "KY",
    Name: "Cayman Islands",
    TimeZone: "America/Cayman",
    Offset: "UTC -05:00",
  },
  {
    Code: "CF",
    Name: "Central African Republic",
    TimeZone: "Africa/Bangui",
    Offset: "UTC +01:00",
  },
  {
    Code: "TD",
    Name: "Chad",
    TimeZone: "Africa/Ndjamena",
    Offset: "UTC +01:00",
  },
  {
    Code: "CL",
    Name: "Chile",
    TimeZone: "America/Punta_Arenas",
    Offset: "UTC -03:00",
  },
  {
    Code: "CL",
    Name: "Chile",
    TimeZone: "America/Santiago",
    Offset: "UTC -04:00",
  },
  {
    Code: "CL",
    Name: "Chile",
    TimeZone: "Pacific/Easter",
    Offset: "UTC -06:00",
  },
  {
    Code: "CN",
    Name: "China",
    TimeZone: "Asia/Shanghai",
    Offset: "UTC +08:00",
  },
  {
    Code: "CN",
    Name: "China",
    TimeZone: "Asia/Urumqi",
    Offset: "UTC +06:00",
  },
  {
    Code: "CX",
    Name: "Christmas Island",
    TimeZone: "Indian/Christmas",
    Offset: "UTC +07:00",
  },
  {
    Code: "CC",
    Name: "Cocos (Keeling) Islands",
    TimeZone: "Indian/Cocos",
    Offset: "UTC +06:30",
  },
  {
    Code: "CO",
    Name: "Colombia",
    TimeZone: "America/Bogota",
    Offset: "UTC -05:00",
  },
  {
    Code: "KM",
    Name: "Comoros",
    TimeZone: "Indian/Comoro",
    Offset: "UTC +03:00",
  },
  {
    Code: "CG",
    Name: "Congo",
    TimeZone: "Africa/Brazzaville",
    Offset: "UTC +01:00",
  },
  {
    Code: "CD",
    Name: "Congo, the Democratic Republic of the",
    TimeZone: "Africa/Kinshasa",
    Offset: "UTC +01:00",
  },
  {
    Code: "CD",
    Name: "Congo, the Democratic Republic of the",
    TimeZone: "Africa/Lubumbashi",
    Offset: "UTC +02:00",
  },
  {
    Code: "CK",
    Name: "Cook Islands",
    TimeZone: "Pacific/Rarotonga",
    Offset: "UTC -10:00",
  },
  {
    Code: "CR",
    Name: "Costa Rica",
    TimeZone: "America/Costa_Rica",
    Offset: "UTC -06:00",
  },
  {
    Code: "HR",
    Name: "Croatia",
    TimeZone: "Europe/Zagreb",
    Offset: "UTC +02:00",
  },
  {
    Code: "CU",
    Name: "Cuba",
    TimeZone: "America/Havana",
    Offset: "UTC -04:00",
  },
  {
    Code: "CW",
    Name: "Curaçao",
    TimeZone: "America/Curacao",
    Offset: "UTC -04:00",
  },
  {
    Code: "CY",
    Name: "Cyprus",
    TimeZone: "Asia/Famagusta",
    Offset: "UTC +03:00",
  },
  {
    Code: "CY",
    Name: "Cyprus",
    TimeZone: "Asia/Nicosia",
    Offset: "UTC +03:00",
  },
  {
    Code: "CZ",
    Name: "Czech Republic",
    TimeZone: "Europe/Prague",
    Offset: "UTC +02:00",
  },
  {
    Code: "CI",
    Name: "Côte d'Ivoire",
    TimeZone: "Africa/Abidjan",
    Offset: "UTC",
  },
  {
    Code: "DK",
    Name: "Denmark",
    TimeZone: "Europe/Copenhagen",
    Offset: "UTC +02:00",
  },
  {
    Code: "DJ",
    Name: "Djibouti",
    TimeZone: "Africa/Djibouti",
    Offset: "UTC +03:00",
  },
  {
    Code: "DM",
    Name: "Dominica",
    TimeZone: "America/Dominica",
    Offset: "UTC -04:00",
  },
  {
    Code: "DO",
    Name: "Dominican Republic",
    TimeZone: "America/Santo_Domingo",
    Offset: "UTC -04:00",
  },
  {
    Code: "EC",
    Name: "Ecuador",
    TimeZone: "America/Guayaquil",
    Offset: "UTC -05:00",
  },
  {
    Code: "EC",
    Name: "Ecuador",
    TimeZone: "Pacific/Galapagos",
    Offset: "UTC -06:00",
  },
  {
    Code: "EG",
    Name: "Egypt",
    TimeZone: "Africa/Cairo",
    Offset: "UTC +03:00",
  },
  {
    Code: "SV",
    Name: "El Salvador",
    TimeZone: "America/El_Salvador",
    Offset: "UTC -06:00",
  },
  {
    Code: "GQ",
    Name: "Equatorial Guinea",
    TimeZone: "Africa/Malabo",
    Offset: "UTC +01:00",
  },
  {
    Code: "ER",
    Name: "Eritrea",
    TimeZone: "Africa/Asmara",
    Offset: "UTC +03:00",
  },
  {
    Code: "EE",
    Name: "Estonia",
    TimeZone: "Europe/Tallinn",
    Offset: "UTC +03:00",
  },
  {
    Code: "ET",
    Name: "Ethiopia",
    TimeZone: "Africa/Addis_Ababa",
    Offset: "UTC +03:00",
  },
  {
    Code: "FK",
    Name: "Falkland Islands (Malvinas)",
    TimeZone: "Atlantic/Stanley",
    Offset: "UTC -03:00",
  },
  {
    Code: "FO",
    Name: "Faroe Islands",
    TimeZone: "Atlantic/Faroe",
    Offset: "UTC +01:00",
  },
  {
    Code: "FJ",
    Name: "Fiji",
    TimeZone: "Pacific/Fiji",
    Offset: "UTC +12:00",
  },
  {
    Code: "FI",
    Name: "Finland",
    TimeZone: "Europe/Helsinki",
    Offset: "UTC +03:00",
  },
  {
    Code: "FR",
    Name: "France",
    TimeZone: "Europe/Paris",
    Offset: "UTC +02:00",
  },
  {
    Code: "GF",
    Name: "French Guiana",
    TimeZone: "America/Cayenne",
    Offset: "UTC -03:00",
  },
  {
    Code: "PF",
    Name: "French Polynesia",
    TimeZone: "Pacific/Gambier",
    Offset: "UTC -09:00",
  },
  {
    Code: "PF",
    Name: "French Polynesia",
    TimeZone: "Pacific/Marquesas",
    Offset: "UTC -09:30",
  },
  {
    Code: "PF",
    Name: "French Polynesia",
    TimeZone: "Pacific/Tahiti",
    Offset: "UTC -10:00",
  },
  {
    Code: "TF",
    Name: "French Southern Territories",
    TimeZone: "Indian/Kerguelen",
    Offset: "UTC +05:00",
  },
  {
    Code: "GA",
    Name: "Gabon",
    TimeZone: "Africa/Libreville",
    Offset: "UTC +01:00",
  },
  {
    Code: "GM",
    Name: "Gambia",
    TimeZone: "Africa/Banjul",
    Offset: "UTC",
  },
  {
    Code: "GE",
    Name: "Georgia",
    TimeZone: "Asia/Tbilisi",
    Offset: "UTC +04:00",
  },
  {
    Code: "DE",
    Name: "Germany",
    TimeZone: "Europe/Berlin",
    Offset: "UTC +02:00",
  },
  {
    Code: "DE",
    Name: "Germany",
    TimeZone: "Europe/Busingen",
    Offset: "UTC +02:00",
  },
  {
    Code: "GH",
    Name: "Ghana",
    TimeZone: "Africa/Accra",
    Offset: "UTC",
  },
  {
    Code: "GI",
    Name: "Gibraltar",
    TimeZone: "Europe/Gibraltar",
    Offset: "UTC +02:00",
  },
  {
    Code: "GR",
    Name: "Greece",
    TimeZone: "Europe/Athens",
    Offset: "UTC +03:00",
  },
  {
    Code: "GL",
    Name: "Greenland",
    TimeZone: "America/Danmarkshavn",
    Offset: "UTC",
  },
  {
    Code: "GL",
    Name: "Greenland",
    TimeZone: "America/Nuuk",
    Offset: "UTC -02:00",
  },
  {
    Code: "GL",
    Name: "Greenland",
    TimeZone: "America/Scoresbysund",
    Offset: "UTC",
  },
  {
    Code: "GL",
    Name: "Greenland",
    TimeZone: "America/Thule",
    Offset: "UTC -03:00",
  },
  {
    Code: "GD",
    Name: "Grenada",
    TimeZone: "America/Grenada",
    Offset: "UTC -04:00",
  },
  {
    Code: "GP",
    Name: "Guadeloupe",
    TimeZone: "America/Guadeloupe",
    Offset: "UTC -04:00",
  },
  {
    Code: "GU",
    Name: "Guam",
    TimeZone: "Pacific/Guam",
    Offset: "UTC +10:00",
  },
  {
    Code: "GT",
    Name: "Guatemala",
    TimeZone: "America/Guatemala",
    Offset: "UTC -06:00",
  },
  {
    Code: "GG",
    Name: "Guernsey",
    TimeZone: "Europe/Guernsey",
    Offset: "UTC +01:00",
  },
  {
    Code: "GN",
    Name: "Guinea",
    TimeZone: "Africa/Conakry",
    Offset: "UTC",
  },
  {
    Code: "GW",
    Name: "Guinea-Bissau",
    TimeZone: "Africa/Bissau",
    Offset: "UTC",
  },
  {
    Code: "GY",
    Name: "Guyana",
    TimeZone: "America/Guyana",
    Offset: "UTC -04:00",
  },
  {
    Code: "HT",
    Name: "Haiti",
    TimeZone: "America/Port-au-Prince",
    Offset: "UTC -04:00",
  },
  {
    Code: "VA",
    Name: "Holy See (Vatican City State)",
    TimeZone: "Europe/Vatican",
    Offset: "UTC +02:00",
  },
  {
    Code: "HN",
    Name: "Honduras",
    TimeZone: "America/Tegucigalpa",
    Offset: "UTC -06:00",
  },
  {
    Code: "HK",
    Name: "Hong Kong",
    TimeZone: "Asia/Hong_Kong",
    Offset: "UTC +08:00",
  },
  {
    Code: "HU",
    Name: "Hungary",
    TimeZone: "Europe/Budapest",
    Offset: "UTC +02:00",
  },
  {
    Code: "IS",
    Name: "Iceland",
    TimeZone: "Atlantic/Reykjavik",
    Offset: "UTC",
  },
  {
    Code: "IN",
    Name: "India",
    TimeZone: "Asia/Kolkata",
    Offset: "UTC +05:30",
  },
  {
    Code: "ID",
    Name: "Indonesia",
    TimeZone: "Asia/Jakarta",
    Offset: "UTC +07:00",
  },
  {
    Code: "ID",
    Name: "Indonesia",
    TimeZone: "Asia/Jayapura",
    Offset: "UTC +09:00",
  },
  {
    Code: "ID",
    Name: "Indonesia",
    TimeZone: "Asia/Makassar",
    Offset: "UTC +08:00",
  },
  {
    Code: "ID",
    Name: "Indonesia",
    TimeZone: "Asia/Pontianak",
    Offset: "UTC +07:00",
  },
  {
    Code: "IR",
    Name: "Iran, Islamic Republic of",
    TimeZone: "Asia/Tehran",
    Offset: "UTC +03:30",
  },
  {
    Code: "IQ",
    Name: "Iraq",
    TimeZone: "Asia/Baghdad",
    Offset: "UTC +03:00",
  },
  {
    Code: "IE",
    Name: "Ireland",
    TimeZone: "Europe/Dublin",
    Offset: "UTC +01:00",
  },
  {
    Code: "IM",
    Name: "Isle of Man",
    TimeZone: "Europe/Isle_of_Man",
    Offset: "UTC +01:00",
  },
  {
    Code: "IL",
    Name: "Israel",
    TimeZone: "Asia/Jerusalem",
    Offset: "UTC +03:00",
  },
  {
    Code: "IT",
    Name: "Italy",
    TimeZone: "Europe/Rome",
    Offset: "UTC +02:00",
  },
  {
    Code: "JM",
    Name: "Jamaica",
    TimeZone: "America/Jamaica",
    Offset: "UTC -05:00",
  },
  {
    Code: "JP",
    Name: "Japan",
    TimeZone: "Asia/Tokyo",
    Offset: "UTC +09:00",
  },
  {
    Code: "JE",
    Name: "Jersey",
    TimeZone: "Europe/Jersey",
    Offset: "UTC +01:00",
  },
  {
    Code: "JO",
    Name: "Jordan",
    TimeZone: "Asia/Amman",
    Offset: "UTC +03:00",
  },
  {
    Code: "KZ",
    Name: "Kazakhstan",
    TimeZone: "Asia/Almaty",
    Offset: "UTC +06:00",
  },
  {
    Code: "KZ",
    Name: "Kazakhstan",
    TimeZone: "Asia/Aqtau",
    Offset: "UTC +05:00",
  },
  {
    Code: "KZ",
    Name: "Kazakhstan",
    TimeZone: "Asia/Aqtobe",
    Offset: "UTC +05:00",
  },
  {
    Code: "KZ",
    Name: "Kazakhstan",
    TimeZone: "Asia/Atyrau",
    Offset: "UTC +05:00",
  },
  {
    Code: "KZ",
    Name: "Kazakhstan",
    TimeZone: "Asia/Oral",
    Offset: "UTC +05:00",
  },
  {
    Code: "KZ",
    Name: "Kazakhstan",
    TimeZone: "Asia/Qostanay",
    Offset: "UTC +06:00",
  },
  {
    Code: "KZ",
    Name: "Kazakhstan",
    TimeZone: "Asia/Qyzylorda",
    Offset: "UTC +05:00",
  },
  {
    Code: "KE",
    Name: "Kenya",
    TimeZone: "Africa/Nairobi",
    Offset: "UTC +03:00",
  },
  {
    Code: "KI",
    Name: "Kiribati",
    TimeZone: "Pacific/Kanton",
    Offset: "UTC +13:00",
  },
  {
    Code: "KI",
    Name: "Kiribati",
    TimeZone: "Pacific/Kiritimati",
    Offset: "UTC +14:00",
  },
  {
    Code: "KI",
    Name: "Kiribati",
    TimeZone: "Pacific/Tarawa",
    Offset: "UTC +12:00",
  },
  {
    Code: "KP",
    Name: "Korea, Democratic People's Republic of",
    TimeZone: "Asia/Pyongyang",
    Offset: "UTC +09:00",
  },
  {
    Code: "KR",
    Name: "Korea, Republic of",
    TimeZone: "Asia/Seoul",
    Offset: "UTC +09:00",
  },
  {
    Code: "KW",
    Name: "Kuwait",
    TimeZone: "Asia/Kuwait",
    Offset: "UTC +03:00",
  },
  {
    Code: "KG",
    Name: "Kyrgyzstan",
    TimeZone: "Asia/Bishkek",
    Offset: "UTC +06:00",
  },
  {
    Code: "LA",
    Name: "Lao People's Democratic Republic",
    TimeZone: "Asia/Vientiane",
    Offset: "UTC +07:00",
  },
  {
    Code: "LV",
    Name: "Latvia",
    TimeZone: "Europe/Riga",
    Offset: "UTC +03:00",
  },
  {
    Code: "LB",
    Name: "Lebanon",
    TimeZone: "Asia/Beirut",
    Offset: "UTC +03:00",
  },
  {
    Code: "LS",
    Name: "Lesotho",
    TimeZone: "Africa/Maseru",
    Offset: "UTC +02:00",
  },
  {
    Code: "LR",
    Name: "Liberia",
    TimeZone: "Africa/Monrovia",
    Offset: "UTC",
  },
  {
    Code: "LY",
    Name: "Libya",
    TimeZone: "Africa/Tripoli",
    Offset: "UTC +02:00",
  },
  {
    Code: "LI",
    Name: "Liechtenstein",
    TimeZone: "Europe/Vaduz",
    Offset: "UTC +02:00",
  },
  {
    Code: "LT",
    Name: "Lithuania",
    TimeZone: "Europe/Vilnius",
    Offset: "UTC +03:00",
  },
  {
    Code: "LU",
    Name: "Luxembourg",
    TimeZone: "Europe/Luxembourg",
    Offset: "UTC +02:00",
  },
  {
    Code: "MO",
    Name: "Macao",
    TimeZone: "Asia/Macau",
    Offset: "UTC +08:00",
  },
  {
    Code: "MK",
    Name: "Macedonia, the Former Yugoslav Republic of",
    TimeZone: "Europe/Skopje",
    Offset: "UTC +02:00",
  },
  {
    Code: "MG",
    Name: "Madagascar",
    TimeZone: "Indian/Antananarivo",
    Offset: "UTC +03:00",
  },
  {
    Code: "MW",
    Name: "Malawi",
    TimeZone: "Africa/Blantyre",
    Offset: "UTC +02:00",
  },
  {
    Code: "MY",
    Name: "Malaysia",
    TimeZone: "Asia/Kuala_Lumpur",
    Offset: "UTC +08:00",
  },
  {
    Code: "MY",
    Name: "Malaysia",
    TimeZone: "Asia/Kuching",
    Offset: "UTC +08:00",
  },
  {
    Code: "MV",
    Name: "Maldives",
    TimeZone: "Indian/Maldives",
    Offset: "UTC +05:00",
  },
  {
    Code: "ML",
    Name: "Mali",
    TimeZone: "Africa/Bamako",
    Offset: "UTC",
  },
  {
    Code: "MT",
    Name: "Malta",
    TimeZone: "Europe/Malta",
    Offset: "UTC +02:00",
  },
  {
    Code: "MH",
    Name: "Marshall Islands",
    TimeZone: "Pacific/Kwajalein",
    Offset: "UTC +12:00",
  },
  {
    Code: "MH",
    Name: "Marshall Islands",
    TimeZone: "Pacific/Majuro",
    Offset: "UTC +12:00",
  },
  {
    Code: "MQ",
    Name: "Martinique",
    TimeZone: "America/Martinique",
    Offset: "UTC -04:00",
  },
  {
    Code: "MR",
    Name: "Mauritania",
    TimeZone: "Africa/Nouakchott",
    Offset: "UTC",
  },
  {
    Code: "MU",
    Name: "Mauritius",
    TimeZone: "Indian/Mauritius",
    Offset: "UTC +04:00",
  },
  {
    Code: "YT",
    Name: "Mayotte",
    TimeZone: "Indian/Mayotte",
    Offset: "UTC +03:00",
  },
  {
    Code: "MX",
    Name: "Mexico",
    TimeZone: "America/Bahia_Banderas",
    Offset: "UTC -06:00",
  },
  {
    Code: "MX",
    Name: "Mexico",
    TimeZone: "America/Cancun",
    Offset: "UTC -05:00",
  },
  {
    Code: "MX",
    Name: "Mexico",
    TimeZone: "America/Chihuahua",
    Offset: "UTC -06:00",
  },
  {
    Code: "MX",
    Name: "Mexico",
    TimeZone: "America/Ciudad_Juarez",
    Offset: "UTC -06:00",
  },
  {
    Code: "MX",
    Name: "Mexico",
    TimeZone: "America/Hermosillo",
    Offset: "UTC -07:00",
  },
  {
    Code: "MX",
    Name: "Mexico",
    TimeZone: "America/Matamoros",
    Offset: "UTC -05:00",
  },
  {
    Code: "MX",
    Name: "Mexico",
    TimeZone: "America/Mazatlan",
    Offset: "UTC -07:00",
  },
  {
    Code: "MX",
    Name: "Mexico",
    TimeZone: "America/Merida",
    Offset: "UTC -06:00",
  },
  {
    Code: "MX",
    Name: "Mexico",
    TimeZone: "America/Mexico_City",
    Offset: "UTC -06:00",
  },
  {
    Code: "MX",
    Name: "Mexico",
    TimeZone: "America/Monterrey",
    Offset: "UTC -06:00",
  },
  {
    Code: "MX",
    Name: "Mexico",
    TimeZone: "America/Ojinaga",
    Offset: "UTC -05:00",
  },
  {
    Code: "MX",
    Name: "Mexico",
    TimeZone: "America/Tijuana",
    Offset: "UTC -07:00",
  },
  {
    Code: "FM",
    Name: "Micronesia, Federated States of",
    TimeZone: "Pacific/Chuuk",
    Offset: "UTC +10:00",
  },
  {
    Code: "FM",
    Name: "Micronesia, Federated States of",
    TimeZone: "Pacific/Kosrae",
    Offset: "UTC +11:00",
  },
  {
    Code: "FM",
    Name: "Micronesia, Federated States of",
    TimeZone: "Pacific/Pohnpei",
    Offset: "UTC +11:00",
  },
  {
    Code: "MD",
    Name: "Moldova, Republic of",
    TimeZone: "Europe/Chisinau",
    Offset: "UTC +03:00",
  },
  {
    Code: "MC",
    Name: "Monaco",
    TimeZone: "Europe/Monaco",
    Offset: "UTC +02:00",
  },
  {
    Code: "MN",
    Name: "Mongolia",
    TimeZone: "Asia/Choibalsan",
    Offset: "UTC +08:00",
  },
  {
    Code: "MN",
    Name: "Mongolia",
    TimeZone: "Asia/Hovd",
    Offset: "UTC +07:00",
  },
  {
    Code: "MN",
    Name: "Mongolia",
    TimeZone: "Asia/Ulaanbaatar",
    Offset: "UTC +08:00",
  },
  {
    Code: "ME",
    Name: "Montenegro",
    TimeZone: "Europe/Podgorica",
    Offset: "UTC +02:00",
  },
  {
    Code: "MS",
    Name: "Montserrat",
    TimeZone: "America/Montserrat",
    Offset: "UTC -04:00",
  },
  {
    Code: "MA",
    Name: "Morocco",
    TimeZone: "Africa/Casablanca",
    Offset: "UTC +01:00",
  },
  {
    Code: "MA",
    Name: "Morocco",
    TimeZone: "Africa/El_Aaiun",
    Offset: "UTC +01:00",
  },
  {
    Code: "MZ",
    Name: "Mozambique",
    TimeZone: "Africa/Maputo",
    Offset: "UTC +02:00",
  },
  {
    Code: "MM",
    Name: "Myanmar",
    TimeZone: "Asia/Yangon",
    Offset: "UTC +06:30",
  },
  {
    Code: "NA",
    Name: "Namibia",
    TimeZone: "Africa/Windhoek",
    Offset: "UTC +02:00",
  },
  {
    Code: "NR",
    Name: "Nauru",
    TimeZone: "Pacific/Nauru",
    Offset: "UTC +12:00",
  },
  {
    Code: "NP",
    Name: "Nepal",
    TimeZone: "Asia/Kathmandu",
    Offset: "UTC +05:45",
  },
  {
    Code: "NL",
    Name: "Netherlands",
    TimeZone: "Europe/Amsterdam",
    Offset: "UTC +02:00",
  },
  {
    Code: "NC",
    Name: "New Caledonia",
    TimeZone: "Pacific/Noumea",
    Offset: "UTC +11:00",
  },
  {
    Code: "NZ",
    Name: "New Zealand",
    TimeZone: "Pacific/Auckland",
    Offset: "UTC +12:00",
  },
  {
    Code: "NZ",
    Name: "New Zealand",
    TimeZone: "Pacific/Chatham",
    Offset: "UTC +12:45",
  },
  {
    Code: "NI",
    Name: "Nicaragua",
    TimeZone: "America/Managua",
    Offset: "UTC -06:00",
  },
  {
    Code: "NE",
    Name: "Niger",
    TimeZone: "Africa/Niamey",
    Offset: "UTC +01:00",
  },
  {
    Code: "NG",
    Name: "Nigeria",
    TimeZone: "Africa/Lagos",
    Offset: "UTC +01:00",
  },
  {
    Code: "NU",
    Name: "Niue",
    TimeZone: "Pacific/Niue",
    Offset: "UTC -11:00",
  },
  {
    Code: "NF",
    Name: "Norfolk Island",
    TimeZone: "Pacific/Norfolk",
    Offset: "UTC +11:00",
  },
  {
    Code: "MP",
    Name: "Northern Mariana Islands",
    TimeZone: "Pacific/Saipan",
    Offset: "UTC +10:00",
  },
  {
    Code: "NO",
    Name: "Norway",
    TimeZone: "Europe/Oslo",
    Offset: "UTC +02:00",
  },
  {
    Code: "OM",
    Name: "Oman",
    TimeZone: "Asia/Muscat",
    Offset: "UTC +04:00",
  },
  {
    Code: "PK",
    Name: "Pakistan",
    TimeZone: "Asia/Karachi",
    Offset: "UTC +05:00",
  },
  {
    Code: "PW",
    Name: "Palau",
    TimeZone: "Pacific/Palau",
    Offset: "UTC +09:00",
  },
  {
    Code: "PS",
    Name: "Palestine, State of",
    TimeZone: "Asia/Gaza",
    Offset: "UTC +03:00",
  },
  {
    Code: "PS",
    Name: "Palestine, State of",
    TimeZone: "Asia/Hebron",
    Offset: "UTC +03:00",
  },
  {
    Code: "PA",
    Name: "Panama",
    TimeZone: "America/Panama",
    Offset: "UTC -05:00",
  },
  {
    Code: "PG",
    Name: "Papua New Guinea",
    TimeZone: "Pacific/Bougainville",
    Offset: "UTC +11:00",
  },
  {
    Code: "PG",
    Name: "Papua New Guinea",
    TimeZone: "Pacific/Port_Moresby",
    Offset: "UTC +10:00",
  },
  {
    Code: "PY",
    Name: "Paraguay",
    TimeZone: "America/Asuncion",
    Offset: "UTC -04:00",
  },
  {
    Code: "PE",
    Name: "Peru",
    TimeZone: "America/Lima",
    Offset: "UTC -05:00",
  },
  {
    Code: "PH",
    Name: "Philippines",
    TimeZone: "Asia/Manila",
    Offset: "UTC +08:00",
  },
  {
    Code: "PN",
    Name: "Pitcairn",
    TimeZone: "Pacific/Pitcairn",
    Offset: "UTC -08:00",
  },
  {
    Code: "PL",
    Name: "Poland",
    TimeZone: "Europe/Warsaw",
    Offset: "UTC +02:00",
  },
  {
    Code: "PT",
    Name: "Portugal",
    TimeZone: "Atlantic/Azores",
    Offset: "UTC",
  },
  {
    Code: "PT",
    Name: "Portugal",
    TimeZone: "Atlantic/Madeira",
    Offset: "UTC +01:00",
  },
  {
    Code: "PT",
    Name: "Portugal",
    TimeZone: "Europe/Lisbon",
    Offset: "UTC +01:00",
  },
  {
    Code: "PR",
    Name: "Puerto Rico",
    TimeZone: "America/Puerto_Rico",
    Offset: "UTC -04:00",
  },
  {
    Code: "QA",
    Name: "Qatar",
    TimeZone: "Asia/Qatar",
    Offset: "UTC +03:00",
  },
  {
    Code: "RO",
    Name: "Romania",
    TimeZone: "Europe/Bucharest",
    Offset: "UTC +03:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Asia/Anadyr",
    Offset: "UTC +12:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Asia/Barnaul",
    Offset: "UTC +07:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Asia/Chita",
    Offset: "UTC +09:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Asia/Irkutsk",
    Offset: "UTC +08:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Asia/Kamchatka",
    Offset: "UTC +12:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Asia/Khandyga",
    Offset: "UTC +09:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Asia/Krasnoyarsk",
    Offset: "UTC +07:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Asia/Magadan",
    Offset: "UTC +11:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Asia/Novokuznetsk",
    Offset: "UTC +07:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Asia/Novosibirsk",
    Offset: "UTC +07:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Asia/Omsk",
    Offset: "UTC +06:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Asia/Sakhalin",
    Offset: "UTC +11:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Asia/Srednekolymsk",
    Offset: "UTC +11:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Asia/Tomsk",
    Offset: "UTC +07:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Asia/Ust-Nera",
    Offset: "UTC +10:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Asia/Vladivostok",
    Offset: "UTC +10:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Asia/Yakutsk",
    Offset: "UTC +09:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Asia/Yekaterinburg",
    Offset: "UTC +05:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Europe/Astrakhan",
    Offset: "UTC +04:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Europe/Kaliningrad",
    Offset: "UTC +02:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Europe/Kirov",
    Offset: "UTC +03:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Europe/Moscow",
    Offset: "UTC +03:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Europe/Samara",
    Offset: "UTC +04:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Europe/Saratov",
    Offset: "UTC +04:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Europe/Ulyanovsk",
    Offset: "UTC +04:00",
  },
  {
    Code: "RU",
    Name: "Russian Federation",
    TimeZone: "Europe/Volgograd",
    Offset: "UTC +03:00",
  },
  {
    Code: "RW",
    Name: "Rwanda",
    TimeZone: "Africa/Kigali",
    Offset: "UTC +02:00",
  },
  {
    Code: "RE",
    Name: "Réunion",
    TimeZone: "Indian/Reunion",
    Offset: "UTC +04:00",
  },
  {
    Code: "BL",
    Name: "Saint Barthélemy",
    TimeZone: "America/St_Barthelemy",
    Offset: "UTC -04:00",
  },
  {
    Code: "SH",
    Name: "Saint Helena, Ascension and Tristan da Cunha",
    TimeZone: "Atlantic/St_Helena",
    Offset: "UTC",
  },
  {
    Code: "KN",
    Name: "Saint Kitts and Nevis",
    TimeZone: "America/St_Kitts",
    Offset: "UTC -04:00",
  },
  {
    Code: "LC",
    Name: "Saint Lucia",
    TimeZone: "America/St_Lucia",
    Offset: "UTC -04:00",
  },
  {
    Code: "MF",
    Name: "Saint Martin (French part)",
    TimeZone: "America/Marigot",
    Offset: "UTC -04:00",
  },
  {
    Code: "PM",
    Name: "Saint Pierre and Miquelon",
    TimeZone: "America/Miquelon",
    Offset: "UTC -02:00",
  },
  {
    Code: "VC",
    Name: "Saint Vincent and the Grenadines",
    TimeZone: "America/St_Vincent",
    Offset: "UTC -04:00",
  },
  {
    Code: "WS",
    Name: "Samoa",
    TimeZone: "Pacific/Apia",
    Offset: "UTC +13:00",
  },
  {
    Code: "SM",
    Name: "San Marino",
    TimeZone: "Europe/San_Marino",
    Offset: "UTC +02:00",
  },
  {
    Code: "ST",
    Name: "Sao Tome and Principe",
    TimeZone: "Africa/Sao_Tome",
    Offset: "UTC",
  },
  {
    Code: "SA",
    Name: "Saudi Arabia",
    TimeZone: "Asia/Riyadh",
    Offset: "UTC +03:00",
  },
  {
    Code: "SN",
    Name: "Senegal",
    TimeZone: "Africa/Dakar",
    Offset: "UTC",
  },
  {
    Code: "RS",
    Name: "Serbia",
    TimeZone: "Europe/Belgrade",
    Offset: "UTC +02:00",
  },
  {
    Code: "SC",
    Name: "Seychelles",
    TimeZone: "Indian/Mahe",
    Offset: "UTC +04:00",
  },
  {
    Code: "SL",
    Name: "Sierra Leone",
    TimeZone: "Africa/Freetown",
    Offset: "UTC",
  },
  {
    Code: "SG",
    Name: "Singapore",
    TimeZone: "Asia/Singapore",
    Offset: "UTC +08:00",
  },
  {
    Code: "SX",
    Name: "Sint Maarten (Dutch part)",
    TimeZone: "America/Lower_Princes",
    Offset: "UTC -04:00",
  },
  {
    Code: "SK",
    Name: "Slovakia",
    TimeZone: "Europe/Bratislava",
    Offset: "UTC +02:00",
  },
  {
    Code: "SI",
    Name: "Slovenia",
    TimeZone: "Europe/Ljubljana",
    Offset: "UTC +02:00",
  },
  {
    Code: "SB",
    Name: "Solomon Islands",
    TimeZone: "Pacific/Guadalcanal",
    Offset: "UTC +11:00",
  },
  {
    Code: "SO",
    Name: "Somalia",
    TimeZone: "Africa/Mogadishu",
    Offset: "UTC +03:00",
  },
  {
    Code: "ZA",
    Name: "South Africa",
    TimeZone: "Africa/Johannesburg",
    Offset: "UTC +02:00",
  },
  {
    Code: "GS",
    Name: "South Georgia and the South Sandwich Islands",
    TimeZone: "Atlantic/South_Georgia",
    Offset: "UTC -02:00",
  },
  {
    Code: "SS",
    Name: "South Sudan",
    TimeZone: "Africa/Juba",
    Offset: "UTC +02:00",
  },
  {
    Code: "ES",
    Name: "Spain",
    TimeZone: "Africa/Ceuta",
    Offset: "UTC +02:00",
  },
  {
    Code: "ES",
    Name: "Spain",
    TimeZone: "Atlantic/Canary",
    Offset: "UTC +01:00",
  },
  {
    Code: "ES",
    Name: "Spain",
    TimeZone: "Europe/Madrid",
    Offset: "UTC +02:00",
  },
  {
    Code: "LK",
    Name: "Sri Lanka",
    TimeZone: "Asia/Colombo",
    Offset: "UTC +05:30",
  },
  {
    Code: "SD",
    Name: "Sudan",
    TimeZone: "Africa/Khartoum",
    Offset: "UTC +02:00",
  },
  {
    Code: "SR",
    Name: "Suriname",
    TimeZone: "America/Paramaribo",
    Offset: "UTC -03:00",
  },
  {
    Code: "SJ",
    Name: "Svalbard and Jan Mayen",
    TimeZone: "Arctic/Longyearbyen",
    Offset: "UTC +02:00",
  },
  {
    Code: "SZ",
    Name: "Swaziland",
    TimeZone: "Africa/Mbabane",
    Offset: "UTC +02:00",
  },
  {
    Code: "SE",
    Name: "Sweden",
    TimeZone: "Europe/Stockholm",
    Offset: "UTC +02:00",
  },
  {
    Code: "CH",
    Name: "Switzerland",
    TimeZone: "Europe/Zurich",
    Offset: "UTC +02:00",
  },
  {
    Code: "SY",
    Name: "Syrian Arab Republic",
    TimeZone: "Asia/Damascus",
    Offset: "UTC +03:00",
  },
  {
    Code: "TW",
    Name: "Taiwan, Province of China",
    TimeZone: "Asia/Taipei",
    Offset: "UTC +08:00",
  },
  {
    Code: "TJ",
    Name: "Tajikistan",
    TimeZone: "Asia/Dushanbe",
    Offset: "UTC +05:00",
  },
  {
    Code: "TZ",
    Name: "Tanzania, United Republic of",
    TimeZone: "Africa/Dar_es_Salaam",
    Offset: "UTC +03:00",
  },
  {
    Code: "TH",
    Name: "Thailand",
    TimeZone: "Asia/Bangkok",
    Offset: "UTC +07:00",
  },
  {
    Code: "TL",
    Name: "Timor-Leste",
    TimeZone: "Asia/Dili",
    Offset: "UTC +09:00",
  },
  {
    Code: "TG",
    Name: "Togo",
    TimeZone: "Africa/Lome",
    Offset: "UTC",
  },
  {
    Code: "TK",
    Name: "Tokelau",
    TimeZone: "Pacific/Fakaofo",
    Offset: "UTC +13:00",
  },
  {
    Code: "TO",
    Name: "Tonga",
    TimeZone: "Pacific/Tongatapu",
    Offset: "UTC +13:00",
  },
  {
    Code: "TT",
    Name: "Trinidad and Tobago",
    TimeZone: "America/Port_of_Spain",
    Offset: "UTC -04:00",
  },
  {
    Code: "TN",
    Name: "Tunisia",
    TimeZone: "Africa/Tunis",
    Offset: "UTC +01:00",
  },
  {
    Code: "TR",
    Name: "Turkey",
    TimeZone: "Europe/Istanbul",
    Offset: "UTC +03:00",
  },
  {
    Code: "TM",
    Name: "Turkmenistan",
    TimeZone: "Asia/Ashgabat",
    Offset: "UTC +05:00",
  },
  {
    Code: "TC",
    Name: "Turks and Caicos Islands",
    TimeZone: "America/Grand_Turk",
    Offset: "UTC -04:00",
  },
  {
    Code: "TV",
    Name: "Tuvalu",
    TimeZone: "Pacific/Funafuti",
    Offset: "UTC +12:00",
  },
  {
    Code: "UG",
    Name: "Uganda",
    TimeZone: "Africa/Kampala",
    Offset: "UTC +03:00",
  },
  {
    Code: "UA",
    Name: "Ukraine",
    TimeZone: "Europe/Kyiv",
    Offset: "UTC +03:00",
  },
  {
    Code: "UA",
    Name: "Ukraine",
    TimeZone: "Europe/Simferopol",
    Offset: "UTC +03:00",
  },
  {
    Code: "AE",
    Name: "United Arab Emirates",
    TimeZone: "Asia/Dubai",
    Offset: "UTC +04:00",
  },
  {
    Code: "GB",
    Name: "United Kingdom",
    TimeZone: "Europe/London",
    Offset: "UTC +01:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/Adak",
    Offset: "UTC -09:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/Anchorage",
    Offset: "UTC -08:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/Boise",
    Offset: "UTC -06:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/Chicago",
    Offset: "UTC -05:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/Denver",
    Offset: "UTC -06:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/Detroit",
    Offset: "UTC -04:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/Indiana/Indianapolis",
    Offset: "UTC -04:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/Indiana/Knox",
    Offset: "UTC -05:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/Indiana/Marengo",
    Offset: "UTC -04:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/Indiana/Petersburg",
    Offset: "UTC -04:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/Indiana/Tell_City",
    Offset: "UTC -05:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/Indiana/Vevay",
    Offset: "UTC -04:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/Indiana/Vincennes",
    Offset: "UTC -04:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/Indiana/Winamac",
    Offset: "UTC -04:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/Juneau",
    Offset: "UTC -08:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/Kentucky/Louisville",
    Offset: "UTC -04:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/Kentucky/Monticello",
    Offset: "UTC -04:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/Los_Angeles",
    Offset: "UTC -07:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/Menominee",
    Offset: "UTC -05:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/Metlakatla",
    Offset: "UTC -08:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/New_York",
    Offset: "UTC -04:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/Nome",
    Offset: "UTC -08:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/North_Dakota/Beulah",
    Offset: "UTC -05:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/North_Dakota/Center",
    Offset: "UTC -05:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/North_Dakota/New_Salem",
    Offset: "UTC -05:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/Phoenix",
    Offset: "UTC -07:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/Sitka",
    Offset: "UTC -08:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "America/Yakutat",
    Offset: "UTC -08:00",
  },
  {
    Code: "US",
    Name: "United States",
    TimeZone: "Pacific/Honolulu",
    Offset: "UTC -10:00",
  },
  {
    Code: "UM",
    Name: "United States Minor Outlying Islands",
    TimeZone: "Pacific/Midway",
    Offset: "UTC -11:00",
  },
  {
    Code: "UM",
    Name: "United States Minor Outlying Islands",
    TimeZone: "Pacific/Wake",
    Offset: "UTC +12:00",
  },
  {
    Code: "UY",
    Name: "Uruguay",
    TimeZone: "America/Montevideo",
    Offset: "UTC -03:00",
  },
  {
    Code: "UZ",
    Name: "Uzbekistan",
    TimeZone: "Asia/Samarkand",
    Offset: "UTC +05:00",
  },
  {
    Code: "UZ",
    Name: "Uzbekistan",
    TimeZone: "Asia/Tashkent",
    Offset: "UTC +05:00",
  },
  {
    Code: "VU",
    Name: "Vanuatu",
    TimeZone: "Pacific/Efate",
    Offset: "UTC +11:00",
  },
  {
    Code: "VE",
    Name: "Venezuela, Bolivarian Republic of",
    TimeZone: "America/Caracas",
    Offset: "UTC -04:00",
  },
  {
    Code: "VN",
    Name: "Viet Nam",
    TimeZone: "Asia/Ho_Chi_Minh",
    Offset: "UTC +07:00",
  },
  {
    Code: "VG",
    Name: "Virgin Islands, British",
    TimeZone: "America/Tortola",
    Offset: "UTC -04:00",
  },
  {
    Code: "VI",
    Name: "Virgin Islands, U.S.",
    TimeZone: "America/St_Thomas",
    Offset: "UTC -04:00",
  },
  {
    Code: "WF",
    Name: "Wallis and Futuna",
    TimeZone: "Pacific/Wallis",
    Offset: "UTC +12:00",
  },
  {
    Code: "YE",
    Name: "Yemen",
    TimeZone: "Asia/Aden",
    Offset: "UTC +03:00",
  },
  {
    Code: "ZM",
    Name: "Zambia",
    TimeZone: "Africa/Lusaka",
    Offset: "UTC +02:00",
  },
  {
    Code: "ZW",
    Name: "Zimbabwe",
    TimeZone: "Africa/Harare",
    Offset: "UTC +02:00",
  },
  {
    Code: "AX",
    Name: "Åland Islands",
    TimeZone: "Europe/Mariehamn",
    Offset: "UTC +03:00",
  },
];
